import React, { useState } from "react";
import { HiEye } from "react-icons/hi";

import {
  MdFlight,
  MdOutlineFlightLand,
  MdOutlineFlightTakeoff,
} from "react-icons/md";
import AmadeusLogo from "../../../../../assets/images/airlineLogo/amadeusRounded.png";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import ConnectedFlight from "./ConnectedFlight";
import moment from "moment";
import UnfoldMoreIcon from "@mui/icons-material/UnfoldMore";
import {
  asyncAmadeusBrandFare,
  setBrandFareError,
  setExpandFlightDetails,
  setpricingErrorNull,
  setShowMoreOptions,
} from "../../../../../redux/features/home/FlightSearchSlice";
import { useDispatch, useSelector } from "react-redux";
import AmadeusBrandedFare from "./AmadeusBrandedFare";
import BrandFareSkeleton from "./BrandFareSkeleton";
import AmadeusNoBrandFare from "./AmadeusNoBrandFare";
import {
  CurrencyCode,
  CurrencyExchange,
} from "../../../../../Utils/currencyExchange";
import { TwoDecimalPoint } from "../../../../../Utils/FixedTwoDecimal";
import { pricingairline } from "../../../../../Utils/airlinePricing";
import { getSectorsName } from "../../../../../Utils/FlightSector";
import {
  codeShareAmadeus,
  codeShareSabre,
  ExclusiveSetors,
} from "../../../../../Utils/exclusiveSector";

const AmadeusCard = ({
  index,
  isExpanded,
  toggleDetail,
  amadeusItem,
  setShowOtherOption,
  showOtherOption,
  selectedFlight,
}) => {
  // console.log("ammm",amadeusItem.travelerPricings);
  const dispatch = useDispatch();
  const { amadeusBrandFare, amadeusloading, brandFareError } = useSelector(
    (state) => state.FlightSearch
  );

  const { addAirLinesData } = useSelector(
    (state) => state?.price_airlines_suppRoleSlice
  );

  const { airlinePricing } = useSelector(
    (state) => state?.price_airlines_suppRoleSlice
  );

  const [selectedTab, setSelectedTab] = useState("Fare Options");
  const searchValues = JSON.parse(localStorage.getItem("flightSearchData"));
  const locDeparture = searchValues?.locationDep;
  const locArrival = searchValues?.locationArrival;

  const getCity = (loc) => {
    let city = loc?.split("-")[2]?.toUpperCase();
    return city;
  };

  const timeDuration = (time1, time2) => {
    if (!time1 || !time2) {
      return null;
    }
    const depMoment = moment(time1);
    const arrMoment = moment(time2);
    const duration = moment.duration(arrMoment.diff(depMoment));
    const hours = String(Math.floor(duration.asHours())).padStart(2, "0");
    const minutes = String(duration.minutes()).padStart(2, "0");

    return `${hours}:${minutes}`;
  };

  function scrollToElement(elementId) {
    const element = document.getElementById(elementId);
    if (element) {
      const yOffset = element.getBoundingClientRect().top + window.scrollY;
      window.scrollTo({
        top: yOffset,
        behavior: "smooth",
      });
    }
  }

  // airline pricing
  const pricing = airlinePricing?.find(
    (item) =>
      item.bg_id === searchValues?.agent?.agent_grp_id &&
      item?.add_airline?.code_alpha ===
        amadeusItem?.validatingAirlineCodes?.at(0)
  );
  //airline
  const airline = addAirLinesData?.find(
    (it) =>
      it.code_alpha === amadeusItem?.validatingAirlineCodes?.at(0)
  );

  const fligthSegment = amadeusItem?.itineraries[0]?.segments;

  // adult fare
  const adultFareTotal = (fare) => {
    return ~~pricingairline(
      airline,
      pricing,
      Number(CurrencyExchange(fare?.price?.base)),
      Number(CurrencyExchange(fare?.price?.grandTotal - fare?.price?.base)),
      getSectorsName(locDeparture, locArrival),
      ExclusiveSetors(
        searchValues,
        pricing,
        fare?.travelerPricings[0]?.fareDetailsBySegment[0]?.class
      ),
      codeShareAmadeus(fligthSegment)
    )?.total_fare;
  };

  // calculate the total fare price
  const totalFarePrice = (fare) => {
    let adultFare = adultFareTotal(fare);
    let total = adultFare;
    return TwoDecimalPoint(total);
  };

    // discount
    const DiscountPrice = (fare) => {
      let paxFare=fare.travelerPricings;
      paxFare = Array.isArray(paxFare) ? paxFare : [paxFare]
  
      const getBaseFareAndTaxes = (faree) => ({baseFare: Number(faree?.price?.base), taxes: Number(faree?.price?.total) - Number(faree?.price?.base), sector:faree?.fareDetailsBySegment?.[0]?.class})
      const getNoFare = () => ({baseFare: 0, taxes: 0})
      
      // console.log("fare",fare);
      let adultFare = getBaseFareAndTaxes(paxFare?.at(0));
      let childFare = searchValues?.childCount > 0 ? getBaseFareAndTaxes(paxFare?.at(1)) : getNoFare();
      let infantFare = searchValues?.infantNo > 0 ? getBaseFareAndTaxes(paxFare?.at(paxFare?.length - 1)) : getNoFare();


      return TwoDecimalPoint(pricingairline(
        airline,
        pricing,
        Number(CurrencyExchange(adultFare?.baseFare)),
        Number(CurrencyExchange(adultFare?.taxes)),
        getSectorsName(locDeparture, locArrival),
        ExclusiveSetors(searchValues, pricing,adultFare?.sector)
      )?.sp * searchValues?.adultsCount) +
      (pricingairline(
        airline,
        pricing,
        Number(CurrencyExchange(childFare?.baseFare)),
        Number(CurrencyExchange(childFare?.taxes)),
        getSectorsName(locDeparture, locArrival),
        ExclusiveSetors(searchValues, pricing,childFare?.sector)
      )?.sp * searchValues?.childCount) +
      (pricingairline(
        airline,
        pricing,
        Number(CurrencyExchange(infantFare?.baseFare)),
        Number(CurrencyExchange(infantFare?.taxes)),
        getSectorsName(locDeparture, locArrival),
        ExclusiveSetors(searchValues, pricing,infantFare?.sector)
      )?.sp * searchValues?.infantNo) 
    };
  
    // calculate the totalDiscount
    const totalDiscount = (fare) => {
      let total = DiscountPrice(fare);
      // let total = adultFare;
      if(total>0){
        return `Off ${CurrencyCode()} ${TwoDecimalPoint(total)}`
      } return ""
      
    };

  const singPaxFareTotal = (fare, pax, travelerType) => {
    const travelerPricing = fare.travelerPricings.find(
      (tp) => tp.travelerType === travelerType
    );
    if (!travelerPricing) return 0;

    const baseFare = Number(CurrencyExchange(travelerPricing.price.base));
    const tax = Number(
      CurrencyExchange(travelerPricing.price.total - travelerPricing.price.base)
    );
    const totalFare = ~~pricingairline(
      airline,
      pricing,
      baseFare,
      tax,
      getSectorsName(locDeparture, locArrival),
      ExclusiveSetors(
        searchValues,
        pricing,
        travelerPricing.fareDetailsBySegment[0]?.class
      ),
      codeShareAmadeus(fligthSegment)
    )?.total_fare;

    return totalFare * pax;
  };

  const singPaxTotalFare = (fare, travelerType) => {
    const travelerPricing = fare?.travelerPricings.find(
      (tp) => tp?.travelerType === travelerType
    );
    if (!travelerPricing) return 0;

    const baseFare = Number(CurrencyExchange(travelerPricing.price.base));
    const tax = Number(
      CurrencyExchange(
        travelerPricing?.price.total - travelerPricing?.price?.base
      )
    );
    const totalFare = ~~pricingairline(
      airline,
      pricing,
      baseFare,
      tax,
      getSectorsName(locDeparture, locArrival),
      ExclusiveSetors(
        searchValues,
        pricing,
        travelerPricing?.fareDetailsBySegment[0]?.class
      ),
      codeShareAmadeus(fligthSegment)
    )?.total_fare;

    return totalFare;
  };

  const handleBrandFare = (amadeusItem) => {
    const { agent_name } = amadeusItem;
    dispatch(
      asyncAmadeusBrandFare({
        agent_name: agent_name,
        flightOffers: amadeusItem,
      })
    );
    dispatch(setBrandFareError(null));
  };

  return (
    <div key={`${index}`} id={`${index}`}>
      <div className="search_engin_result_box flight_card_shadow bg-white">
        <div
          className="shadow cursorpointer"
          onClick={() => {
            dispatch(setpricingErrorNull(null))
            toggleDetail(index);
            if (!isExpanded && !selectedFlight) {
              handleBrandFare(amadeusItem);
            }
            setTimeout(() => {
              !isExpanded && scrollToElement(`${isExpanded}`);
            }, 200);
          }}
        >
          <div className="row align-items-center">
            <div className="col-2">
              <div className="px-2">
                <div className="text-center">
                  <img
                    src={`/NEW_LOGO/${
                      amadeusItem?.itineraries?.at(0)?.segments?.at(0)
                        ?.carrierCode
                    }.png`}
                    alt="logo"
                    width={90}
                    height={90}
                  />
                  <div className="mt-3">
                    {amadeusItem?.itineraries
                      ?.at(0)
                      ?.segments?.map((it, idx, arr) => (
                        <span className="pe-1" key={idx}>{`${it?.carrierCode} ${
                          it?.number
                        } ${idx < arr.length - 1 ? " -" : ""}`}</span>
                      ))}
                  </div>
                </div>
              </div>
            </div>

            <div className="col-7">
              <div className="d-flex justify-content-evenly align-items-center">
                <div className="d-flex align-items-center">
                  <div className="me-3">
                    <MdOutlineFlightTakeoff
                      size={30}
                      className="color_primary"
                    />
                  </div>
                  <div>
                    <h6>
                      {moment(
                        amadeusItem?.itineraries
                          ?.at(0)
                          ?.segments?.at(0)
                          ?.departure?.at?.split("T")[0]
                      ).format("ddd DD-MMM-YY")}
                    </h6>
                    <h6 className="my-3">
                      {moment(
                        amadeusItem?.itineraries
                          ?.at(0)
                          ?.segments?.at(0)
                          ?.departure?.at?.split("T")[1],
                        "HH:mm:ss"
                      ).format("HH:mm")}
                    </h6>
                    <h6>
                      {
                        amadeusItem?.itineraries?.at(0)?.segments?.at(0)
                          ?.departure?.iataCode
                      }{" "}
                      - {getCity(locDeparture)}
                    </h6>
                  </div>
                </div>

                <div>
                  <h6 className="text-center">
                    {timeDuration(
                      amadeusItem?.itineraries?.at(0)?.segments?.at(0)
                        ?.departure?.at,
                      amadeusItem?.itineraries
                        ?.at(0)
                        ?.segments?.at(
                          amadeusItem?.itineraries?.at(0)?.segments.length - 1
                        )?.arrival?.at
                    )}
                  </h6>
                  <div className="border_rounded mb-2" />
                  <h6 className="mx-3">
                    {amadeusItem?.itineraries?.at(0)?.segments?.length === 1
                      ? "Non Stop"
                      : amadeusItem?.itineraries?.at(0)?.segments?.length === 2
                      ? "One Stop"
                      : amadeusItem?.itineraries?.at(0)?.segments?.length === 3
                      ? "Two Stop"
                      : "Three Stop"}
                  </h6>

                  {amadeusItem?.itineraries?.map(
                    (itinery) =>
                      itinery?.segments?.length > 1 && (
                        <h6 className="text-center">
                          {itinery?.segments
                            ?.slice(1)
                            ?.map((seg) => seg?.departure?.iataCode)?.join(", ")}
                        </h6>
                      )
                  )}
                </div>
                <div className="d-flex align-items-center">
                  <div className="me-3">
                    <MdOutlineFlightLand size={30} className="color_primary" />
                  </div>
                  <div>
                    <h6>
                      {moment(
                        amadeusItem?.itineraries
                          ?.at(0)
                          ?.segments?.at(
                            amadeusItem?.itineraries?.at(0)?.segments.length - 1
                          )
                          ?.arrival?.at?.split("T")[0]
                      ).format("ddd DD-MMM-YY")}
                    </h6>
                    <h6 className="my-3">
                      {moment(
                        amadeusItem?.itineraries
                          ?.at(0)
                          ?.segments?.at(
                            amadeusItem?.itineraries?.at(0)?.segments.length - 1
                          )
                          ?.arrival?.at?.split("T")[1],
                        "HH:mm:ss"
                      ).format("HH:mm")}
                    </h6>
                    <h6>
                      {
                        amadeusItem?.itineraries
                          ?.at(0)
                          ?.segments?.at(
                            amadeusItem?.itineraries?.at(0)?.segments.length - 1
                          )?.arrival?.iataCode
                      }{" "}
                      - {getCity(locArrival)}
                    </h6>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-3">
              <div className="shadow pt-3 d-flex flex-column align-items-center justify-content-center result_box_body pb-3">
                <>
                <h6 className="text-danger">
                  {totalDiscount(amadeusItem)}
                </h6>
                  <h4>
                    <span className="me-1">
                      {`${CurrencyCode()}
                     `}
                      {totalFarePrice(amadeusItem)}
                    </span>
                    <img src={AmadeusLogo} alt="" width={35} height={35} />
                  </h4>
                  <button
                    onClick={(e) => {
                      toggleDetail(index);
                      setTimeout(() => {
                        !isExpanded && scrollToElement(`${index}`);
                      }, 200);
                    }}
                    className="button_style w-75 mb-2 cursorpointer"
                    type="button"
                  >
                    <div>
                      <MenuOpenIcon className="me-1" />
                      Flight Detail
                    </div>
                  </button>
                </>

                {!selectedFlight && (
                  <div>
                    {amadeusItem?.otherOptions?.length > 0 && (
                      <button
                        className="bg-white color_primary border_primary p-2 px-4 fs-5 rounded-2 cursorpointer"
                        onClick={(e) => {
                          e.stopPropagation();
                          dispatch(setExpandFlightDetails(null));
                          setShowOtherOption(!showOtherOption);
                        }}
                      >
                        <div>
                          <UnfoldMoreIcon className="me-1" />
                          {showOtherOption
                            ? "Hide Options"
                            : `${amadeusItem?.otherOptions?.length} More Options`}
                        </div>
                      </button>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        {brandFareError?.response?.data?.message === "Availability Expired" ? (
          <>{isExpanded && <></>}</>
        ) : (
          <>
            {isExpanded && (
              <>
                {" "}
                {amadeusloading ? (
                  <BrandFareSkeleton />
                ) : (
                  <div className="p-3">
                    <div className="d-flex mb-4 mt-2 flight_details_tabs">
                      {["Fare Options", "Flight Details"].map((item, index) => {
                        const active = selectedTab === item;
                        return (
                          <button
                            key={index}
                            onClick={() => setSelectedTab(item)}
                            className={active ? "active" : ""}
                          >
                            {item}
                          </button>
                        );
                      })}
                    </div>

                    <div>
                      {selectedTab === "Flight Details" && (
                        <div className="row rounded-2 search_engin_result_box bg-white p-4">
                          {amadeusItem?.itineraries[0]?.segments?.map(
                            (item, inx, array) => {
                              return (
                                <ConnectedFlight
                                  timeDuration={timeDuration}
                                  amadeusItem={item}
                                  inx={inx}
                                  flightSegment={array}
                                />
                              );
                            }
                          )}
                        </div>
                      )}
                    </div>

                    <div>
                      {selectedTab === "Fare Options" && (
                        <div className="row rounded-2 search_engin_result_box bg-white p-4">
                          {brandFareError?.response?.data?.message ===
                            "UNABLE TO RETRIEVE UPSELL OFFER" ||
                          brandFareError?.response?.data?.message ===
                            "SYSTEM ERROR HAS OCCURRED" ? (
                            <>
                              <AmadeusNoBrandFare
                                amadeusItem={amadeusItem}
                                totalFarePrice={totalFarePrice}
                                singPaxFareTotal={singPaxFareTotal}
                                singPaxTotalFare={singPaxTotalFare}
                                timeDuration={timeDuration}
                                pricing={pricing}
                                airline={airline}
                                selectedFlight={selectedFlight}
                              />
                            </>
                          ) : (
                            <>
                              <AmadeusBrandedFare
                                amadeusBrandFare={amadeusBrandFare?.data}
                                amadeusItem={amadeusItem}
                                totalFarePrice={totalFarePrice}
                                singPaxFareTotal={singPaxFareTotal}
                                singPaxTotalFare={singPaxTotalFare}
                                timeDuration={timeDuration}
                                pricing={pricing}
                                airline={airline}
                                selectedFlight={selectedFlight}
                              />
                            </>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default AmadeusCard;
