import React from "react";
import { useDispatch } from "react-redux";
import { cancelBooking, getAsyncBookingDetails } from "../../redux/features/booking/bookingDetailsSlice";
import { Formik, Form, Field } from "formik";
import moment from "moment";
import { Navigate, useNavigate } from "react-router-dom";
const CancelBooking = ({ booking }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = JSON.parse(localStorage.getItem("loginUser"));

  const id = booking?.booking?.id;
  const gds = booking?.booking?.FlightBookingModels?.at(0)?.cred_supp_name;

  const initialValues={
    reason: ""
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={ async(values, { setSubmitting }) => {
        const text = `${user.user_id} ${user.f_name} ${user.l_name} ${moment().format("DD-MMM-YY HH:MM")} - ${values.reason}`;
          dispatch(cancelBooking({ id, gds, text }))
            .unwrap()
            .then((response) => {
              dispatch(getAsyncBookingDetails({ id }));
              setSubmitting(false);
              navigate("/booking")
            })
            .catch((err) => {
             console.log("error", err);
            });
      }}
    >
      {({ isSubmitting }) => (
        <Form>
          <div>
            <Field
              as="textarea"
              name="reason"
              className="py-1 px-1"
              rows="5"
              cols="27"
              placeholder="Enter your text here"
              style={{
                border: "2px solid #cccccc",
                outline: "none",
              }}
              maxLength="100"
            />
          </div>
          <div className="py-2">
            <button
              className="px-3 py-2 btn btn-danger"
              type="submit"
              disabled={isSubmitting}
            >
              Cancel
            </button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default CancelBooking;
