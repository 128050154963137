import { Button } from "@mui/material";
import React from "react";

import ReactDatePicker from "react-datepicker";
import { useSelector } from "react-redux";

function OpeningBalance() {
  const user = JSON.parse(localStorage.getItem("loginUser"));
  const { userPermission } = useSelector((state) => state?.roleAndPermissions);

  // Check if the login user has permission to opening balance
  const opBalancePermission =
    user?.user_id !== 1
      ? userPermission?.find(
          (it) =>
            it?.user_id === user?.user_id &&
            it?.page_level.permission_url === "/op-balance"
        )
      : true;

  return (
    <div className="Full_height">
      <div className="manual_invoices  mx-auto">
        <div className="manual_invoices_header">
          <div>Opening Balance</div>
        </div>
        <div className="row py-5 px-2 px-md-4 align-items-end">
          <div className="col-6 col-md-4">
            <ReactDatePicker
              placeholderText="From Date"
              // selected={startDate}
              // onChange={(date) => {
              //     setStartDate(date);
              // }}
              monthsShown={1}
              // selected={new Date()}
              // onCalendarClose={handleCalendarClose}
              minDate={new Date()}
              // onCalendarOpen={handleCalendarOpen}
              className="date_picker"
            />
          </div>
          <div className="col-6 col-md-4">
            <ReactDatePicker
              placeholderText="To Date"
              // selected={startDate}
              // onChange={(date) => {
              //     setStartDate(date);
              // }}
              monthsShown={1}
              // selected={new Date()}
              // onCalendarClose={handleCalendarClose}
              minDate={new Date()}
              // onCalendarOpen={handleCalendarOpen}
              className="date_picker"
            />
          </div>

          <div className="col-12 col-md-4 mt-4 mt-md-0">
            <Button variant="contained" disabled={!opBalancePermission}>
              Generate Report
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default OpeningBalance;
