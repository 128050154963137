import React, { useEffect, useState } from "react";
import "../Styles/manualInvoices.css";
import CustomDropdown from "../components/CustomDrp";
import ReactDatePicker from "react-datepicker";
import {
  Button,
  ClickAwayListener,
  FormControl,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { asyncGetNewSupplier } from "../redux/features/supplier/AddNewSupplier";
import { useDispatch, useSelector } from "react-redux";
import { MdClose } from "react-icons/md";
import { asyncSearchAgencyData } from "../redux/features/setupFeature/NewRegistration/NewRegistrationSlice";
import { asyncGetAllUserData } from "../redux/features/agency/usersSlice";

function RefundReport() {
  const dispatch = useDispatch();

  const [agentDRopDwn, setagentDRopDwn] = useState(false);
  const [agent, setAgent] = useState("");
  const { searchAgency } = useSelector((state) => state.newAgency);

  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");

  const [service, setService] = useState("");

  const arry = [
    { id: 0, type: "All" },
    { id: 1, type: "Flight" },
    { id: 2, type: "Visa" },
    { id: 3, type: "Hotel" },
    { id: 4, type: "Insurance" },
    { id: 5, type: "Transfer" },
    { id: 6, type: "Activity" },
    { id: 7, type: "Umrah" },
  ];

  const [drp, setdrp] = useState(false);
  const [supplierName, setSupplierName] = useState([]);
  const [supValue, setSupValue] = useState("");

  const { newSupplier } = useSelector((state) => state.supplier);
  const suppArray = newSupplier?.map((sup) => sup?.supp_name);

  const [manager, setManager] = useState("");
  const { allUsersData } = useSelector((state) => state?.usersSlice);
  const accManagerUser = allUsersData?.filter((it) => it.agent_id === 1);

  useEffect(() => {
    dispatch(asyncGetNewSupplier());
    dispatch(asyncGetAllUserData());
  }, []);

  return (
    <div className="Full_height">
      <div className="manual_invoices mx-auto mt-5">
        <div className="manual_invoices_header">
          <div className="w-100 text-center">
            Refund Report (Date Wise / Sale Type Wise / Agent Wise / Supplier
            Wise)
          </div>
        </div>
        <div className="pt-5 px-2 px-md-5">
          <div className="row align-items-center">
            <div className="col-6">
              <ReactDatePicker
                placeholderText="From Date"
                selected={fromDate}
                onChange={(date) => {
                  setFromDate(date);
                }}
                maxDate={new Date()}
                className="date_picker "
              />
            </div>
            <div className="col-6">
              <ReactDatePicker
                placeholderText="To Date"
                selected={toDate}
                onChange={(date) => {
                  setToDate(date);
                }}
                maxDate={new Date()}
                className="date_picker "
              />
            </div>
          </div>
          <div className="row pt-4 pt-md-5">
            <div className="col-6 pe-4">
              <FormControl variant="standard" className="w-100">
                <InputLabel id="demo-simple-select-standard-label">
                  Account Manager
                </InputLabel>
                <Select
                  labelId="demo-simple-select-standard-label"
                  id="demo-simple-select-standard"
                  name="manager"
                  value={manager}
                >
                  {accManagerUser?.map((mngr) => (
                    <MenuItem
                      value={`${mngr?.f_name} ${mngr?.l_name}`}
                      key={mngr.user_id}
                      onClick={() =>
                        setManager(`${mngr?.f_name} ${mngr?.l_name}`)
                      }
                    >
                      {`${mngr?.f_name} ${mngr?.l_name}`}
                    </MenuItem>
                  ))}
                </Select>
                {/* <ErrorMessage
                    name="manager"
                    component="div"
                    className="mt-2 text-danger"
                  /> */}
              </FormControl>
            </div>

            <div className="col-6 pe-4">
              <FormControl variant="standard" className="w-100" sx={{}}>
                <InputLabel id="demo-simple-select-standard-label">
                  Services
                </InputLabel>
                <Select
                  labelId="demo-simple-select-standard-label"
                  id="demo-simple-select-standard"
                  name={service}
                  value={service}
                  onChange={(e) => setService(e.target.value)}
                  label="Services"
                >
                  {arry.map((list) => (
                    <MenuItem
                      value={list?.type}
                      sx={{ bgcolor: "#fff" }}
                      key={list?.id}
                    >
                      {list?.type}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          </div>
          <div className="row pt-4 pt-md-5 pe-4">
            <div className="col-6 pe-4">
              <FormControl
                variant="standard"
                className="w-100"
                onClick={() => setagentDRopDwn(!agentDRopDwn)}
              >
                <InputLabel htmlFor="supplier">Select Agent</InputLabel>
                <Input
                  variant="standard"
                  label="Select Agent"
                  autoComplete="off"
                  className="w-100"
                  name="agent"
                  value={agent}
                  onChange={(e) => {
                    let value = e.target.value;
                    setAgent(value);
                    dispatch(asyncSearchAgencyData(value));
                  }}
                  endAdornment={
                    <InputAdornment position="end">
                      {agent !== "" && (
                        <IconButton
                          onClick={() => {
                            setAgent("");
                          }}
                        >
                          <MdClose />
                        </IconButton>
                      )}
                    </InputAdornment>
                  }
                />
              </FormControl>

              {agentDRopDwn ? (
                <ClickAwayListener onClickAway={() => setagentDRopDwn(false)}>
                  <div className="agent_dropdown">
                    {searchAgency?.length > 0 ? (
                      searchAgency?.map((item, index) => {
                        return (
                          <div
                            className="p-3"
                            onClick={(e) => {
                              setAgent(item.agent_name);
                              setagentDRopDwn(false);
                            }}
                            style={{ cursor: "pointer" }}
                            key={index}
                          >
                            {item.agent_name}
                          </div>
                        );
                      })
                    ) : (
                      <div className="p-3">No record available.</div>
                    )}
                  </div>
                </ClickAwayListener>
              ) : null}
              {/* <TextField
                id="standard-basic"
                label="Select Agent"
                variant="standard"
                className="w-100"
              /> */}
            </div>
            <div className="col-6">
              <FormControl
                variant="standard"
                className="w-100"
                onClick={() => {
                  setdrp(!drp);
                }}
              >
                <InputLabel htmlFor="supplier">Select Supplier</InputLabel>
                <Input
                  autoComplete="off"
                  id="supplier"
                  className="w-100"
                  value={supValue}
                  name={supValue}
                  onChange={(e) => {
                    let value = e.target.value;
                    setSupValue(value);

                    let filterSupplier = value
                      ? suppArray?.filter((item) =>
                          item?.toLowerCase().includes(value.toLowerCase())
                        )
                      : suppArray;
                    setSupplierName([...filterSupplier]);
                  }}
                  endAdornment={
                    <InputAdornment position="end">
                      {supValue !== "" && (
                        <IconButton
                          onClick={() => {
                            setSupValue("");
                          }}
                        >
                          <MdClose />
                        </IconButton>
                      )}
                    </InputAdornment>
                  }
                />
              </FormControl>
              {drp && (
                <ClickAwayListener onClickAway={() => setdrp(!drp)}>
                  <div className="supplier_dropdown">
                    {supplierName?.length > 0 ? (
                      supplierName?.map((item, index) => {
                        return (
                          <div
                            key={index}
                            className="supplier_style"
                            onClick={() => {
                              let value = `${item}`;
                              setSupValue(value);
                              setdrp(!drp);
                            }}
                          >
                            <div className="fs-6 supplier_captilize">
                              {item.toLowerCase()}
                            </div>
                          </div>
                        );
                      })
                    ) : (
                      <div className="supplier_style">No Record Found.</div>
                    )}
                  </div>
                </ClickAwayListener>
              )}
            </div>
          </div>
        </div>
        <div className=" pt-5">
          {/* radio buttons */}
          <div className="d-flex justify-content-center align-items-center">
            <div className="me-5">
              <label class="form-control">
                <input type="radio" name="radio" />
                <span className="h6">PDF</span>
              </label>
            </div>
            <div className="">
              <label class="form-control">
                <input type="radio" name="radio" />
                <span className="h6">EXCEL</span>
              </label>
            </div>
          </div>
        </div>

        {/* generat report button */}
        <div className="container py-4">
          <div className="d-flex justify-content-center align-items-center my-3 mt-4">
            <button className="button_style">Generate Report</button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RefundReport;
