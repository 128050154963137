import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { NODEURL } from "../ApiConfig";

const initialState = {
  booking: [],
  allBooking: [],
  isLoading: false,
  otpstatus: null,
  otpMSG: null,
  error: null,
  VoucherBooking: [],
  pnr: null,
  singleVoucherLog: [],
  getBookingLogs: [],
  cancelBookingStatus: null,
};

// Async Thunk for cancelBooking API
export const cancelBooking = createAsyncThunk(
  "cancelBooking/patch",
  async ({ id, gds,text },{ rejectWithValue }) => {
    try {
      const response = await NODEURL.patch(`/api/v1/b2b/booking/cancel/update/${id}`,{gds:gds,text:text});
      return await response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const getAsyncBookingDetails = createAsyncThunk(
  "getAsyncBookingDetails/get",
  async ({ id }, { rejectWithValue }) => {
    try {
      const response = await NODEURL.get(`/api/v1/b2b/booking-main/${id}`);
      return await response.data?.data;
    } catch (error) {
      rejectWithValue(error);
    }
  }
);
// /voucher-manual

export const getAsyncBookingDetailsForVocherDetails = createAsyncThunk(
  "getAsyncBookingDetailsForVocherDetails/get",
  async ({ id, tkt_voucher_no, voucher_id }, { rejectWithValue }) => {
    try {
      const response = await NODEURL.get(
        `/api/v1/b2b/voucher-manual/${id}?tkt_voucher_no=${tkt_voucher_no}&&voucher_id=${voucher_id}`
      );
      return await response.data?.data;
    } catch (error) {
      rejectWithValue(error);
    }
  }
);

// get single Voucher Log

export const getSingleVoucherLog = createAsyncThunk(
  "getSingleVoucherLog/get",
  async ({ voucher_id }, { rejectWithValue }) => {
    try {
      const response = await NODEURL.get(
        `/api/v1/b2b/getSingleVoucherLogs/${voucher_id}`
      );
      return await response.data?.data;
    } catch (error) {
      rejectWithValue(error);
    }
  }
);

export const updateAsynicAutoVoucher = createAsyncThunk(
  "updateAsynicAutoVoucher/patch",
  async ({ id, body }, { rejectWithValue }) => {
    try {
      const response = await NODEURL.patch(
        `/api/v1/b2b/voucher-manual/${id}`,
        body
      );
      return await response.data?.data;
    } catch (error) {
      rejectWithValue(error);
    }
  }
);
// /voucher-manual/:id
// manual - invoice;

export const updateAsynicManuailVoucher = createAsyncThunk(
  "updateAsynicManuailVoucher/patch",
  async ({ id, body }, { rejectWithValue }) => {
    try {
      const response = await NODEURL.patch(
        `/api/v1/b2b/manual-invoice/${id}`,
        body
      );
      return await response.data?.data;
    } catch (error) {
      rejectWithValue(error);
    }
  }
);

export const getAllAsyncBookings = createAsyncThunk(
  "getAllAsyncBookings/get",
  async (
    { id, rowsPerPage, currentPage, bookingFilter },
    { rejectWithValue }
  ) => {
    try {
      const response = await NODEURL.get(
        `/api/v1/b2b/booking-main?agent_acc_id=${id}&page=${
          currentPage + 1
        }&limit=${rowsPerPage}&bookingFilter=${JSON.stringify(bookingFilter)}`
      );

      return await response?.data?.data;
    } catch (error) {
      rejectWithValue(error);
    }
  }
);
////update booking Status

export const asyncupdateMianBookingStatus = createAsyncThunk(
  "asyncupdateMianBookingStatus/patch",
  async ({ id, body }, { rejectWithValue }) => {
    try {
      await NODEURL.patch(`/api/v1/b2b/booking-main-status/${id}`, body);
    } catch (error) {
      rejectWithValue(error);
    }
  }
);

export const asyncPostBookingId = createAsyncThunk(
  "asyncPostBookingId/post",
  async (otpbody, { rejectWithValue }) => {
    try {
      const response = await NODEURL.post("/api/v1/b2b/ticket-issue", otpbody);
      return await response.data;
    } catch (error) {
      rejectWithValue(error);
    }
  }
);

export const asyncCreateFlightGroupTicketIssue = createAsyncThunk(
  "asyncCreateFlightGroupTicketIssue/post",
  async (otpbody, { rejectWithValue }) => {
    try {
      const response = await NODEURL.post(
        "/api/v1/b2b/flight-group-ticket-issue",
        otpbody
      );
      return await response.data;
    } catch (error) {
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const asyncmanualInvoice = createAsyncThunk(
  "asyncmanualInvoice/post",
  async (body, { rejectWithValue }) => {
    try {
      const response = await NODEURL.post("/api/v1/b2b/manual-invoice", body);
      return await response.data;
    } catch (error) {
      rejectWithValue(error);
    }
  }
);
// manual - invoice;
export const asyncGenerateOtpcode = createAsyncThunk(
  "asyncGenerateOtp/post",
  async (booking_id, { rejectWithValue }) => {
    try {
      const res = await NODEURL.post(`/api/v1/b2b/sending-varification-code?booking_id=${booking_id}`);
      return await res.data;
    } catch (error) {
      rejectWithValue(error);
    }
  }
);

//Find PNR
export const getAsyncSearchPNR = createAsyncThunk(
  "getAsyncSearchPNR/get",
  async (param, { rejectWithValue }) => {
    try {
      const response = await NODEURL.get(
        `/api/v1/b2b/search-pnr?document_number=${param}`
      );
      return await response.data?.data;
    } catch (error) {
      rejectWithValue(error);
    }
  }
);

// updatePaxMain and Table andCreate BookingLogs;

export const updateAsynicupdatePaxMainTableCreateBookingLogs = createAsyncThunk(
  "updateAsynicupdatePaxMainTableCreateBookingLogs/patch",
  async ({ id, body }, { rejectWithValue }) => {
    try {
      const response = await NODEURL.patch(
        `/api/v1/b2b/pax-update/${id}`,
        body
      );
      return await response.data?.data;
    } catch (error) {
      rejectWithValue(error);
    }
  }
);

// Delete Pax Data
export const asyncDeletePaxsData = createAsyncThunk(
  "asyncDeletePaxsData/post",
  async ({ id, body }, { rejectWithValue }) => {
    try {
      const response = await NODEURL.post(`/api/v1/b2b/pax-delete/${id}`, body);
      return await response.data;
    } catch (error) {
      rejectWithValue(error);
    }
  }
);

// Get Fare Logs
export const GetFareLogs = createAsyncThunk(
  "GetFareLogs/get",
  async ({ id }, { rejectWithValue }) => {
    try {
      const response = await NODEURL.get(`/api/v1/b2b/fare-logs/${id}`);
      return await response.data?.data;
    } catch (error) {
      rejectWithValue(error);
    }
  }
);
// export const FareLogs =  createAsyncThunk()

export const bookingDetails = createSlice({
  name: "bookingDetails",
  initialState,
  reducers: {
    setOtpNull: (state) => {
      state.otpstatus = null;
    },
    setOtmMSGNull: (state) => {
      state.otpMSG = null;
    },
    setManualkInvoiceNull: (state) => {
      state.VoucherBooking = null;
    },
    setAllBookingNull :(state)=>{
      state.allBooking=null
    },
    resetCancelBookingStatus: (state) => {
      state.cancelBookingStatus = null; 
    }
  },
  extraReducers: (builder) => {

    builder.addCase(cancelBooking.pending, (state) => {
      state.isLoading = true;
      state.error = null;
      state.cancelBookingStatus = null;
    });
    builder.addCase(cancelBooking.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.cancelBookingStatus = payload;
    });
    builder.addCase(cancelBooking.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.error = payload;
      state.cancelBookingStatus = null;
    });

    builder.addCase(getAsyncBookingDetails.pending, (state, { payload }) => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(getAsyncBookingDetails.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.error = null;
      state.booking = payload;
    });
    builder.addCase(getAsyncBookingDetails.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    });

    builder.addCase(
      getAsyncBookingDetailsForVocherDetails.pending,
      (state, { payload }) => {
        state.isLoading = true;
        state.error = null;
      }
    );
    builder.addCase(
      getAsyncBookingDetailsForVocherDetails.fulfilled,
      (state, { payload }) => {
        state.isLoading = false;
        state.error = null;
        state.VoucherBooking = payload;
      }
    );
    builder.addCase(
      getAsyncBookingDetailsForVocherDetails.rejected,
      (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      }
    );

    builder
      .addCase(asyncCreateFlightGroupTicketIssue.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(asyncCreateFlightGroupTicketIssue.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
      })
      .addCase(asyncCreateFlightGroupTicketIssue.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });

    //  get sing voucwre logs

    builder.addCase(getSingleVoucherLog.pending, (state, { payload }) => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(getSingleVoucherLog.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.error = null;
      state.singleVoucherLog = payload;
    });
    builder.addCase(getSingleVoucherLog.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    });

    builder.addCase(asyncGenerateOtpcode.pending, (state, { payload }) => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(asyncGenerateOtpcode.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.error = null;
      state.otpstatus = payload;
    });
    builder.addCase(asyncGenerateOtpcode.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    });

    builder.addCase(asyncPostBookingId.pending, (state, { payload }) => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(asyncPostBookingId.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.error = null;
      state.otpMSG = payload;
    });
    builder.addCase(asyncPostBookingId.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    });

    builder.addCase(getAllAsyncBookings.pending, (state, { payload }) => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(getAllAsyncBookings.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.error = null;
      state.allBooking = payload;
    });
    builder.addCase(getAllAsyncBookings.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    });

    // searchPNR
    //  builder.addCase(getAsyncBookingDetails.pending, (state, { payload }) => {
    //    state.isLoading = true;
    //    state.error = null;
    //  });
    //  builder.addCase(getAsyncBookingDetails.fulfilled, (state, { payload }) => {
    //    state.isLoading = false;
    //    state.error = null;
    //    state.pnr = payload;
    //  });
    //  builder.addCase(getAsyncBookingDetails.rejected, (state, action) => {
    //    state.isLoading = false;
    //    state.error = action.payload;
    //  });

    // Get booking Logs
    builder.addCase(GetFareLogs.pending, (state, { payload }) => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(GetFareLogs.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.error = null;
      state.getBookingLogs = payload;
    });
    builder.addCase(GetFareLogs.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    });
  },
});
export const { setOtpNull, setOtmMSGNull, setManualkInvoiceNull, setAllBookingNull,resetCancelBookingStatus } =
  bookingDetails.actions;

export default bookingDetails.reducer;
