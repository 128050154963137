import React, { useEffect, useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import MUIDataTable from "mui-datatables";
import { ErrorMessage, Form, Formik } from "formik";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { asyncGetBankDetailData } from "../../../../redux/features/setupRestPages/Bank_curr_RoleSlice";
import {
  asyncGetAllChequeSerials,
  asyncGetHistoryCheckBook,
  asyncPostChequeSerials,
  asyncUpdateSerialStatus,
} from "../../../../redux/features/Finance/Account/BankBook/ChequeBookSlice";
import { Link, useNavigate } from "react-router-dom";
import moment from "moment";

const AddChequeBook = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  /********************Use selector *********** */
  const user = JSON.parse(localStorage.getItem("loginUser"));
  const [historyChData, sethistoryChData] = useState([]);
  const { bankDetails } = useSelector((state) => state?.bank_curr_roleSlice);

  const { allChequeSerials, error, historyChequeBook } =
    useSelector((state) => state?.chequeBook);
  const { userPermission } = useSelector((state) => state?.roleAndPermissions);

  const [accordion, setAccordion] = useState(false);
  const [bankid, setBankID] = useState();
  const [chqBookId,setChqBookId]= useState("")
  
  /******************UseEffect **************** */
  // Check if the login user has permission to add cheque book
  const chequeBookPermission = ![1, 6, 7].includes(user.role_id)
    ? userPermission?.find(
        (it) =>
          it?.user_id === user?.user_id &&
          it?.page_level.permission_url === "/check-book-edit"
      )
    : true;

  useEffect(() => {
    dispatch(asyncGetBankDetailData());
  }, [dispatch]);

  /**************** Table options  */
  const options = {
    filter: false,
    Selection: false,
    print: false,
    download: false,
    sort: false,
    viewColumns: false,
    displayRowCheckbox: false,
    selectableRows: "none",
    rowsPerPage: 100,
    sortOrder: {
      name: "chq_numbers",
      direction: "asc",
    },
  };

  /*********  Current Cheque Books*****table columns *********/
  const Historycolumn = [
    {
      label: "Bank Name",
      name: "bank_id",
      options: {
        customBodyRender: (value) => {
          let bank_name = bankDetails.find((b) => b.bank_id === value);
          return <div>{bank_name.name}</div>;
        },
      },
    },
    {
      name: "serial_start",
      options: {
        display: false,
      },
    },
    {
      name: "serial_end",
      options: { display: false },
    },
    {
      label: "Cheque Book Id",
      name: "chq_book_id",
    },
    {
      label: "Serial Number",
      name: "serial_number",
      options: {
        customBodyRender: (value, tableMeta) => {
          let serial_start = tableMeta.rowData[1];
          let serial_end = tableMeta.rowData[2];
          return (
            <div>
              {serial_start} - {serial_end}
            </div>
          );
        },
      },
    },

    {
      label: "Action",
      name: "action",
      options: {
        setCellProps: () => ({
          style: { minWidth: "20px", maxWidth: "50px" },
        }),
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            // <Link to="/cheque-book-history" target="_blank">
              <button
                className="setup-btn"
                onClick={() => {
              
                    navigate("/cheque-book-history", {
                      state: {
                     
                        bankid: bankid,
                        chqId:chqBookId
                      },
                    });
                    
                }}
              >
                {/* // localStorage.setItem( // "historyCheckbookdata", //
                JSON.stringify(historyChData) */}
                <i className="fa fa-thin fa-view" aria-hidden="true"></i>
                View
              </button>
            // </Link>
          );
        },
      },
    },
  ];
  const currentChequecolumn = [
    {
      label: "Date",
      name: "chq_serial_id",
      options: {
        setCellProps: () => ({
          style: { minWidth: "20px", maxWidth: "130px" },
        }),
        customBodyRender: (value, tableMeta) => {
          const serialRow = allChequeSerials?.find(
            (ser, i) => ser?.chq_serial_id === value
          );
          return (
            <div className="d-flex" style={{ width: 150 }}>
              <p>
                {/* {moment( */}
                {serialRow?.acc_voucher?.saved_date?.split("T").at(0)}
                {/* ).format("ddd DD-MMM-YY")}{" "} */}
              </p>
            </div>
          );
        },
      },
    },
    {
      name: "chq_serial_id",
      options: {
        display: false,
      },
    },
    {
      name: "bank_id",
      options: {
        display: false,
      },
    },
    {
      label: "Paid To",
      name: "chq_serial_id",
      options: {
        setCellProps: () => ({
          style: { minWidth: "20px", maxWidth: "130px" },
        }),
        customBodyRender: (value, tableMeta) => {
          const serialRow = allChequeSerials?.find(
            (ser, i) => ser?.chq_serial_id === value
          );
          return (
            <div className="d-flex" style={{ width: 150 }}>
              <p>
                {
                  serialRow?.acc_voucher?.acc_voucher_trans?.at(1)?.acc_account
                    ?.acc_title
                }
              </p>
            </div>
          );
        },
      },
    },
    {
      label: "Cheque No",
      name: "chq_numbers",
    },
    {
      label: "Payment Voucher",
      name: "chq_serial_id",
      options: {
        setCellProps: () => ({
          style: { minWidth: "20px", maxWidth: "130px" },
        }),
        customBodyRender: (value, tableMeta) => {
          const serialRow = allChequeSerials?.find(
            (ser, i) => ser?.chq_serial_id === value
          );
          return (
            <div className="d-flex" style={{ width: 150 }}>
              <p>{serialRow?.acc_voucher?.voucher_no}</p>
            </div>
          );
        },
      },
    },
    {
      label: "Status",
      name: "chq_status",
      options: {
        setCellProps: () => ({
          style: { minWidth: "20px", maxWidth: "130px" },
        }),
        customBodyRender: (value, tableMeta) => {
          let title = value === "Not Used" ? "Open +" : "Void";
          let id = tableMeta?.rowData[1];
          let bank_id = tableMeta?.rowData[2];
          return value === "Not Used" ? (
            <>
              <Accordion
                sx={{
                  boxShadow: "0",
                  bgcolor: "inherit",
                  width: "fit-content",
                  padding: "0",
                }}
              >
                <AccordionSummary
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  className="p-0"
                >
                  <Box
                    sx={{
                      width: "10rem",
                      padding: ".5rem 0",
                      textAlign: "center",
                      bgcolor: "#457a7c",
                      color: "#ffffff",
                      borderRadius: "4px",
                    }}
                    onClick={(e) => setAccordion(true)}
                  >
                    {title}
                  </Box>
                </AccordionSummary>
                {accordion && (
                  <AccordionDetails className="bg-white rounded-2 black_border">
                    <Typography>
                      Are you sure, you want to void this Cheque.
                    </Typography>
                    <div className="d-flex justify-content-end align-items-center mt-1">
                      <button
                        onClick={(e) => {
                          setAccordion(false);
                        }}
                        className="acc-btn bg-danger me-2"
                      >
                        Cancel
                      </button>
                      <button
                        onClick={(e) => {
                          dispatch(asyncUpdateSerialStatus({ id, bank_id }));
                          setAccordion(false);
                        }}
                        className="acc-btn bg-primary"
                      >
                        Confirm
                      </button>
                    </div>
                  </AccordionDetails>
                )}
              </Accordion>
            </>
          ) : (
            <Button
              disabled={!chequeBookPermission}
              sx={{
                width: "10rem",
                padding: ".5rem 0",
                textAlign: "center",
                bgcolor: "red",
                color: "#ffffff",
                borderRadius: "4px",
                margin: "10px 0",
              }}
              r
            >
              {value}
            </Button>
          );
        },
      },
    },
  ];

  const dropMenuProp = {
    PaperProps: {
      sx: {
        width: "inherit",
      },
    },
  };
  /******************Initials values ***********/
  const initialValues = {
    bank_id: "",
    serial_start: "",
    serial_end: "",
  };
  /**********Validation schema ************ */
  const validationSchema = yup.object().shape({
    bank_id: yup.number().required("Bank is Required"),
    serial_start: yup.number().required("Serial Start is Required"),
    serial_end: yup
      .number()
      .required("Serial End is Required")
      .min(
        yup.ref("serial_start"),
        "Serial End must be greater than Serial Start"
      )
      .test("notEqual", "Serial must not be equal", function (value) {
        return value !== this.parent.serial_start;
      }),
  });

  return (
    <div className="pb-5">
      {/*************** add cheque book  ************* */}
      <Formik
        validateOnChange={true}
        validateOnBlur={true}
        enableReinitialize={true}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values, { resetForm }) => {
          let data = values;
          dispatch(asyncPostChequeSerials(data));
          resetForm({});
        }}
      >
        {({ values, handleChange }) => {
          return (
            <Form>
              <div className="col-11 mx-auto rounded-2 mt-5 shadow-sm bg-white pb-5">
                <p className="h4 otawix-heading box_title">Add Cheque Book</p>
                <div className="row px-5 py-3 pt-5">
                  <div className="col-2 h-5rem">
                    <FormControl variant="standard" className="w-100">
                      <InputLabel id="demo-simple-select-standard-label">
                        Select Bank
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-standard-label"
                        id="demo-simple-select-standard"
                        name={`bank_id`}
                        value={values.bank_id}
                        onChange={handleChange}
                        MenuProps={dropMenuProp}
                      >
                        {bankDetails?.map((it) => {
                          return (
                            <MenuItem value={it.bank_id} key={it.bank_id}>
                              {it?.name}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                    <ErrorMessage
                      component="p"
                      className="text-danger mt-1"
                      name="bank_id"
                    />
                  </div>
                  <div className="col-2">
                    <TextField
                      id="standard-basic"
                      label="Serial Start"
                      variant="standard"
                      className="w-100"
                      type="number"
                      name="serial_start"
                      value={values.serial_start}
                      onChange={handleChange}
                    />
                    <ErrorMessage
                      component="p"
                      className="text-danger mt-1"
                      name="serial_start"
                    />
                  </div>
                  <div className="col-2">
                    <TextField
                      id="standard-basic"
                      label="Serial End"
                      variant="standard"
                      className="w-100"
                      type="number"
                      name="serial_end"
                      value={values.serial_end}
                      onChange={handleChange}
                    />
                    <ErrorMessage
                      component="p"
                      className="text-danger mt-1"
                      name="serial_end"
                    />
                  </div>
                  <div className="col-2">
                    <button
                      className="btn setup-btn"
                      type="submit"
                      disabled={!chequeBookPermission}
                    >
                      Add Serial
                    </button>
                  </div>
                  <div className="text-primary error_msg text-danger">
                    {error && error}
                  </div>
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>

      {/***************Cheque Book History *********** */}
      <div className="shadow-sm rounded-2 col-11 mx-auto mt-5 bg-white">
        <p className="h4 otawix-heading box_title">Cheque Books History</p>
        <div className="p-5">
          <Formik
            enableReinitialize={true}
            initialValues={{
              bankId: "",
              cheque_id: "",
            }}
            onSubmit={(values, { resetForm }) => {}}
          >
            {({ values, setFieldValue, resetForm }) => {
              return (
                <Form>
                  <div className="row pb-4">
                    <div className="col-2">
                      <FormControl variant="standard" className="w-100">
                        <InputLabel id="demo-simple-select-standard-label">
                          Select Bank
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-standard-label"
                          id="demo-simple-select-standard"
                          name={`bankId`}
                          value={values.bankId}
                          onChange={(e) => {
                            let bank_id = e.target.value;
                            setBankID(bank_id);
                            setFieldValue("bankId", bank_id);
                            dispatch(asyncGetHistoryCheckBook(bank_id));
                            sethistoryChData([]);
                          }}
                          MenuProps={dropMenuProp}
                        >
                          {bankDetails?.map((it) => {
                            return (
                              <MenuItem value={it.bank_id} key={it.bank_id}>
                                {it?.name}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </div>
                    <div className="col-2">
                      <FormControl variant="standard" className="w-100">
                        <InputLabel id="demo-simple-select-standard-label">
                          Select Cheque Book Id
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-standard-label"
                          id="demo-simple-select-standard"
                          name={`cheque_id`}
                          value={values.cheque_id}
                          onChange={(e) => {
                            let cheque_id = e.target.value;
                            let hiChData = historyChequeBook?.filter(
                              (ch) => ch.chq_book_id === cheque_id
                            );
                            setFieldValue("cheque_id", cheque_id);
                            setChqBookId(cheque_id);
                            sethistoryChData(hiChData);
                          }}
                          MenuProps={dropMenuProp}
                        >
                          {historyChequeBook?.map((it) => {
                            return (
                              <MenuItem
                                value={it.chq_book_id}
                                key={it.chq_book_id}
                              >
                                {/* {it?.chq_book_id}{} */}
                                {it?.serial_start.toString() +
                                  "-" +
                                  (it?.serial_end?.toString().slice(-3) || "")}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </div>
                  </div>
                </Form>
              );
            }}
          </Formik>

          <MUIDataTable
            title={""}
            columns={Historycolumn}
            data={historyChData}
            options={options}
          />
        </div>
      </div>

      {/***************Current Cheque Books *********** */}
      <div className="shadow-sm rounded-2 col-11 mx-auto mt-5 bg-white">
        <p className="h4 otawix-heading box_title">Current Cheque Books</p>
        <div className="p-5">
          <div className="col-2 h-5rem">
            <Formik
              enableReinitialize={true}
              initialValues={{
                bank_id: "",
              }}
              onSubmit={(values, { resetForm }) => {}}
            >
              {({ values, setFieldValue }) => {
                return (
                  <Form>
                    <FormControl variant="standard" className="w-100">
                      <InputLabel id="current-checque-bood">
                        Select Bank
                      </InputLabel>
                      <Select
                        labelId="current-checque-bood"
                        id="current-checque-bood"
                        name={`bank_id`}
                        value={values.bank_id}
                        onChange={(e) => {
                          let bank_id = e.target.value;
                          setFieldValue("bank_id", bank_id);
                          dispatch(asyncGetAllChequeSerials(bank_id));
                        }}
                        MenuProps={dropMenuProp}
                      >
                        {bankDetails?.map((it) => {
                          return (
                            <MenuItem
                              value={it.bank_id}
                              key={it.bank_id}
                              sx={{ bgcolor: "white", m: 1 }}
                            >
                              {it?.name}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Form>
                );
              }}
            </Formik>
          </div>
          <MUIDataTable
            title={""}
            columns={currentChequecolumn}
            data={allChequeSerials ? allChequeSerials : []}
            options={options}
          />
        </div>
      </div>
    </div>
  );
};

export default AddChequeBook;
