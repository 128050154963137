import React, { useState } from "react";
import "../Styles/manualInvoices.css";
import ReactDatePicker from "react-datepicker";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";

function TransactionalReport() {
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [transactionalType, setTransactionalType] = useState("")

  const arry = ["All", "Journal Voucher", "Payment Voucher", "Reciept Voucher", "Sale Invoice", "Credit Note"];
  return (
    <div className="">
      <div className="manual_invoices mx-auto mt-5">
        <div className="manual_invoices_header">
          <div className="w-100 text-center">
            Transactional Report (Date Wise / Voucher Type Wise)
          </div>
        </div>
        <div className=" pt-5 px-2 px-md-5">
          <div className="row align-items-center">
            <div className="col-6">
              <ReactDatePicker
                placeholderText="From Date"
                selected={fromDate}
                onChange={(date) => {
                  setFromDate(date);
                }}
                // onCalendarClose={handleCalendarClose}
                maxDate={new Date()}
                // onCalendarOpen={handleCalendarOpen}
                className="date_picker "
              />
            </div>
            <div className="col-6">
              <ReactDatePicker
                placeholderText="To Date"
                selected={toDate}
                onChange={(date) => {
                  setToDate(date);
                }}
                // onCalendarClose={handleCalendarClose}
                maxDate={new Date()}
                // onCalendarOpen={handleCalendarOpen}
                className="date_picker "
              />
            </div>
            <div className="col-6 pt-5">
              <FormControl variant="standard" className="w-100" sx={{}}>
                <InputLabel id="demo-simple-select-standard-label">
                  Transactional Type
                </InputLabel>
                <Select
                  labelId="demo-simple-select-standard-label"
                  id="demo-simple-select-standard"
                  name={transactionalType}
                  value={transactionalType}
                  onChange={(e) => setTransactionalType(e.target.value)}
                  label="Transactional Type"
                >
                  {arry.map((list) => (
                    <MenuItem
                      value={list}
                      sx={{ m: 1, bgcolor: "#fff" }}
                      key={list}
                    >
                      {list}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          </div>
        </div>
        <div className=" pt-5">
          {/* radio buttons */}
          <div className="d-flex justify-content-center align-items-center ">
            <div className="me-5">
              <label class="form-control">
                <input type="radio" name="radio" />
                <span className="h6">PDF</span>
              </label>
            </div>
            <div className="">
              <label class="form-control">
                <input type="radio" name="radio" />
                <span className="h6">EXCEL</span>
              </label>
            </div>
          </div>
        </div>

        {/* generat report button */}
        <div className="container py-4">
          <div className="d-flex justify-content-center align-items-center my-3 ">
            <button className="button_style">Generate Report</button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TransactionalReport;
