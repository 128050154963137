import React, { useState } from "react";
import "../Styles/manualInvoices.css";
import {
  ClickAwayListener,
  FormControl,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import "../Styles/radio.css";
import ReactDatePicker from "react-datepicker";
import { MdClose } from "react-icons/md";
import { asyncSearchAgencyData } from "../redux/features/setupFeature/NewRegistration/NewRegistrationSlice";
import { useDispatch, useSelector } from "react-redux";
import { BlobProvider } from "@react-pdf/renderer";
import { useEffect } from "react";
import { AsyncGetAllVoucher } from "../redux/features/Voucher/VoucherSlice";
import moment from "moment";
import { AsyncCreateAgentStatment, setChangeComponet } from "../redux/features/Statement/StatementSlice";
import BtnLoader from "../components/AppForm/BtnLoader";
import AgentsStatementPDF from "../components/Finance/Accounts/Statements/AgentsStatementPDF";

function AgentAccountStatement() {
  const dispatch = useDispatch();

  const [agentDRopDwn, setagentDRopDwn] = useState(false);
  const userData = JSON.parse(localStorage.getItem("loginUser"));
  const { agentStatement, changeComponent } = useSelector(
    (state) => state.Statement
  );
  const [agent, setAgent] = useState(
    userData?.agent_id === 1 ? "" : userData?.agents_comp?.agent_name
  );
  const [agentAccId, setAgentAccId] = useState(
    userData?.agent_id === 1 ? "" : userData?.agents_comp?.acc_id
  );

  const { searchAgency } = useSelector((state) => state.newAgency);

  const { allVoucher } = useSelector((state) => state?.voucherEntries);

  const [fromDate, setFromDate] = useState(new Date());
  const [toDate, setToDate] = useState(new Date());

  const [voucher, setVoucher] = useState({
    salin: 4,
    vch: [],
    curr: userData?.agent_id === 1 ? "PKR" : "other",
    cn: "",
    op: "",
  });
  const [agentData, setAgentData] = useState({});
  useEffect(() => {
    let value = agent;
    dispatch(AsyncGetAllVoucher());
    if (userData?.agent_id !== 1) {
      dispatch(asyncSearchAgencyData(value))
        .then((res) => {
          setAgentData(res?.payload?.at(0));
        })
        .catch((err) => console.log(err));
    }
  }, [dispatch]);

  // const [changeComponent, setChangeComponet] = useState("search");
  const isVoucherNotEmpty = () => {
    return (
      voucher.salin !== "" ||
      (voucher.vch && voucher.vch.length > 0) ||
      voucher.cn !== "" ||
      voucher.op !== ""
    );
  };
  const hasVoucherData = isVoucherNotEmpty();
  const [message, setMessage] = useState(true);
  return (
    <div>
      {changeComponent === "search" && (
        <div className="Full_height">
          <div className="manual_invoices mx-auto mt-5">
            <div className="manual_invoices_header">
              <div className="w-100 text-center">Agent Account Statement</div>
            </div>
            <div className="px-2 px-md-5">
              <div className=" pt-5">
                <div className="row align-items-center">
                  <div className="input_groupS col-4">
                    <ReactDatePicker
                      placeholderText="From Date"
                      selected={fromDate}
                      dateFormat="dd-MM-yyyy"
                      onChange={(date) => {
                        setFromDate(date);
                      }}
                      maxDate={new Date()}
                      className="date_picker "
                    />
                  </div>
                  <div className="input_groupS col-4">
                    <ReactDatePicker
                      placeholderText="To Date"
                      selected={toDate}
                      dateFormat="dd-MM-yyyy"
                      onChange={(date) => {
                        setToDate(date);
                      }}
                      maxDate={new Date()}
                      className="date_picker "
                    />
                  </div>
                  <div className="col-4" style={{ marginBottom: "20px" }}>
                    <FormControl
                      variant="standard"
                      className="w-100"
                      onClick={() => setagentDRopDwn(!agentDRopDwn)}
                    >
                      <InputLabel htmlFor="supplier">Select Agent</InputLabel>
                      <Input
                        variant="standard"
                        label="Select Agent"
                        autoComplete="off"
                        className="w-100"
                        name="agent"
                        value={agent}
                        onFocus={(e) => {
                          e.target.select();
                        }}
                        disabled={userData?.agent_id !== 1}
                        onChange={(e) => {
                          let value = e.target.value;
                          setAgent(value);
                          dispatch(asyncSearchAgencyData(value));
                        }}
                        endAdornment={
                          <InputAdornment position="end">
                            {agent !== "" && userData?.agent_id === 1 && (
                              <IconButton
                                onClick={() => {
                                  setAgent("");
                                }}
                              >
                                <MdClose />
                              </IconButton>
                            )}
                          </InputAdornment>
                        }
                      />
                    </FormControl>

                    {agentDRopDwn ? (
                      <ClickAwayListener
                        onClickAway={() => setagentDRopDwn(false)}
                      >
                        <div className="agent_dropdown">
                          {searchAgency?.length > 0 ? (
                            searchAgency?.map((item, index) => {
                              return (
                                <div
                                  className="p-3"
                                  onClick={(e) => {
                                    setAgentAccId(item?.acc_id);
                                    setAgent(item.agent_name);
                                    setagentDRopDwn(false);
                                    setAgentData(item);
                                    setVoucher({
                                      ...voucher,
                                      curr:
                                        item?.agent_group?.currency
                                          ?.curr_code === "PKR"
                                          ? "PKR"
                                          : "other",
                                    });
                                  }}
                                  style={{ cursor: "pointer" }}
                                  key={index}
                                >
                                  {item.agent_name}
                                </div>
                              );
                            })
                          ) : (
                            <div className="p-3">No record available.</div>
                          )}
                        </div>
                      </ClickAwayListener>
                    ) : null}
                  </div>
                </div>
              </div>

              <div className="py-5">
                <div className="row ">
                  <div class="checkbox-rect col-6 col-md-3">
                    <input
                      type="checkbox"
                      id="checkbox-rect1"
                      name="check"
                      checked={voucher?.salin}
                      onChange={() =>
                        setVoucher({
                          ...voucher,
                          salin: voucher?.salin === 4 ? "" : 4,
                        })
                      }
                    />
                    <label for="checkbox-rect1" className="h6">
                      Sale Invoice
                    </label>
                  </div>

                  <div class="checkbox-rect col-6 col-md-3">
                    <input
                      type="checkbox"
                      id="add_voucher"
                      name="check"
                      checked={voucher?.vch?.length > 0}
                      onChange={() =>
                        setVoucher({
                          ...voucher,
                          vch: voucher?.vch?.length ? [] : [1, 2, 3],
                        })
                      }
                    />
                    <label for="add_voucher" className="h6">
                      Vochers
                    </label>
                  </div>

                  <div class="checkbox-rect col-6 mt-4 col-md-3 mt-md-0">
                    <input
                      type="checkbox"
                      id="add_credit_note"
                      name="check"
                      checked={voucher?.cn}
                      onChange={() =>
                        setVoucher({
                          ...voucher,
                          cn: voucher?.cn === 5 ? "" : 5,
                        })
                      }
                    />
                    <label for="add_credit_note" className="h6">
                      Credit Note
                    </label>
                  </div>

                  <div class="checkbox-rect col-6 col-md-3 mt-4 mt-md-0">
                    <input
                      type="checkbox"
                      id="add_opening_balence"
                      name="check"
                      checked={voucher?.op === "opb"}
                      onChange={() =>
                        setVoucher({
                          ...voucher,
                          op: voucher?.op === "opb" ? "" : "opb",
                        })
                      }
                    />
                    <label for="add_opening_balence" className="h6">
                      Opening Balence
                    </label>
                  </div>

                  {userData?.agent_id === 1 ? (
                    <span
                      className="form-control me-5"
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        gap: 8,
                        paddingTop: "40px",
                        paddingLeft: "10px",
                      }}
                    >
                      <input
                        type="radio"
                        id="without_statement"
                        value="cheque"
                        name="currency"
                        checked={voucher?.curr === "PKR"}
                        onChange={() =>
                          setVoucher({
                            ...voucher,
                            curr: "PKR",
                          })
                        }
                      />
                      <label for="without_statement" className="h6">
                        PKR
                      </label>
                    </span>
                  ) : null}

                  <span
                    className="form-control me-5"
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      gap: 8,
                      paddingTop: "40px",
                      paddingLeft: "10px",
                    }}
                  >
                    <input
                      type="radio"
                      id="with_sar"
                      value="cheque"
                      name="currency"
                      checked={voucher?.curr === "other"}
                      onChange={() => {
                        setVoucher({
                          ...voucher,
                          curr:
                            agentData?.agent_group?.currency?.curr_code ===
                            "PKR"
                              ? "PKR"
                              : "other",
                        });
                      }}
                    />
                    <label for="with_sar" className="h6">
                      Currency
                    </label>
                  </span>
                </div>

                {/* radio buttons */}

                {message ? (
                  <div className="text-center py-4">
                    <button
                      className="button_style px-4"
                      type="submit"
                      disabled={!hasVoucherData || agent === ""}
                      onClick={(e) => {
                        const body = {
                          ...voucher,
                          fromDate: new Date(
                            moment(fromDate).format("YYYY-MM-DD")
                          ),
                          toDate: new Date(moment(toDate).format("YYYY-MM-DD")),
                          accID:agentAccId,
                        };
                        dispatch(AsyncCreateAgentStatment({ body }));
                        setMessage(false);
                        setTimeout(() => {
                          setMessage(true);
                          // setChangeComponet("data");
                           dispatch(setChangeComponet("data"));
                        }, 3000);
                      }}
                    >
                      Agent Statement
                    </button>
                  </div>
                ) : (
                  <div className="text-center py-4">
                    <button className="button_style px-4">
                      <BtnLoader />
                    </button>
                  </div>
                )}

                {/* <div className="d-flex justify-content-center align-items-center my-5 mb-0">
                  <button
                    className="w-auto rounded bg_primary text-white px-2 py-2 fs-6"
                   
                  >
                    Agent Statement
                  </button>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      )}

      {agentStatement?.succcess && changeComponent === "data" && (
        <AgentsStatementPDF
          agentData={agentData}
          fromDate={moment(fromDate).format("MMM DD, YYYY")}
          toDate={moment(toDate).format("MMM DD, YYYY")}
          statement="agent"
          data={
            voucher?.op === "opb"
              ? [
                  {
                    acc_voucher_trans: [
                      {
                        d_cur_credit: 0,
                        d_cur_debit: agentStatement?.total,
                        trans_description: "Opening Balance",
                      },
                    ],
                  },
                  ...agentStatement?.filterData,
                ]
              : [
                  {
                    acc_voucher_trans: [
                      {
                        d_cur_credit: 0,
                        d_cur_debit: 0,
                        trans_description: "Opening Balance",
                      },
                    ],
                  },
                  ...agentStatement?.filterData,
                ]
          }
          userData={userData}
          voucher={voucher}
          setChangeComponet={setChangeComponet}
          allwaysCredit={false}
        />
      )}
    </div>
  );
}

export default AgentAccountStatement;
