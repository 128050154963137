import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import FinanceDropDown from "./FinanceDropDown";
import AgencyDropDown from "./AgencyDropDown";
import SetupDropDown from "./SetupDropDown";

import Reservation from "../../assets/images/reservation.png";
import Booking from "../../assets/images/online-booking.png";
import Collection from "../../assets/images/shopping-basket.png";
import Requests from "../../assets/images/customer-service.png";
import Groups from "../../assets/images/travel.png";
import Setup from "../../assets/images/setup.png";
import Finance from "../../assets/images/chart.png";
import Agency from "../../assets/images/agency.png";

import ActiveReservation from "../../assets/images/white/reservation.png";
import ActiveBookings from "../../assets/images/white/booking.png";
import ActiveCollections from "../../assets/images/white/collection.png";
import ActiveRequests from "../../assets/images/white/request.png";
import ActiveGroups from "../../assets/images/white/group.png";
import ActiveSetup from "../../assets/images/white/setup.png";
import ActiveFinance from "../../assets/images/white/financial.png";
import ActiveAgency from "../../assets/images/white/agency.png";
import { useDispatch, useSelector } from "react-redux";
import { asyncGetFlightRequestsData, fetchMessagesCount } from "../../redux/features/requests/flightrequest";
import { asyncGetCollectionData } from "../../redux/features/Payments/PaymentsAllSlices";

import "./chatdropdown.css"

function NavigationBar() {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const user = JSON.parse(localStorage.getItem("loginUser"));
  const [isSetupVisible, setIsSetupVisible] = useState(false);
  const [isFinanceVisible, setIsFinanceVisible] = useState(false);
  const [isAgencyVisible, setIsAgencyVisible] = useState(false);

  const activeLink = localStorage.getItem("Active_link");
  const setActiveLink = (value) => localStorage.setItem("Active_link", value);
  

  const { flightgetData } = useSelector((state) => state?.flightrequest);
  const [autoRefresh, setAutoRefresh] = useState(false);
  const { collectionData } = useSelector((state) => state.allPayments);
  const count = useSelector((state) => state.flightrequest.Count);

  // const c_id=user.user_id
  const c_id=user.agent_id
  useEffect(() => {
    dispatch(fetchMessagesCount({c_id}));
  }, [location]);

  const agentId = user?.agents_comp?.acc_id;

  useEffect(() => {
    dispatch(asyncGetCollectionData(agentId));
  }, []);

  useEffect(() => {
    if (location?.pathname === "/requests") {
      setTimeout(() => {
        dispatch(asyncGetFlightRequestsData());
        setAutoRefresh(!autoRefresh);
      }, 8000);
    }
  }, [dispatch, location?.pathname]);

  const handleMenuVisibility = (data, fn) => {
    fn(data?.length > 0 ? true : false);
  };

  return (
    <>
      <div className="navigation_bar d-none d-md-flex">
        <div
          className={`menu_link_container ${
            activeLink === "home" && "menu_link_container-active"
          }`}
          onClick={(e) => {
            navigate("/home");
            setActiveLink("home");
          }}
        >
          <img src={Reservation} alt="home" className="menu_link_icon" />
          <img
            src={ActiveReservation}
            alt="home"
            className="menu_link_icon-active"
          />
          <span>Home</span>
        </div>
        <div
          className={`menu_link_container ${
            activeLink === "booking" && "menu_link_container-active"
          }`}
          onClick={(e) => {
            navigate("/booking");
            setActiveLink("booking");
          }}
        >
          <img src={Booking} alt="booking" className="menu_link_icon" />
          <img
            src={ActiveBookings}
            alt="reservation"
            className="menu_link_icon-active"
          />
          <span>Booking</span>
        </div>
        <div
          className={`menu_link_container ${
            activeLink === "collection" && "menu_link_container-active"
          }`}
          onClick={(e) => {
            navigate("/collection");
            setActiveLink("collection");
          }}
        >
          <img src={Collection} alt="collection" className="menu_link_icon" />
          <img
            src={ActiveCollections}
            alt="reservation"
            className="menu_link_icon-active"
          />
          <span>
            Collection <sup>{collectionData?.length}</sup>
          </span>
        </div>

        <div
          className={`menu_link_container ${
            activeLink === "requests" && "menu_link_container-active"
          }`}
          onClick={(e) => {
            navigate("/requests");
            setActiveLink("requests");
          }}
        >
          <img src={Requests} alt="requests" className="menu_link_icon" />
          <img
            src={ActiveRequests}
            alt="reservation"
            className="menu_link_icon-active"
          />
          <span>
            Messages <sup>{count}</sup>
          </span>
        </div>
     

        <div
          className={`menu_link_container ${
            activeLink === "groups" && "menu_link_container-active"
          }`}
          onClick={(e) => {
            navigate("/flight-group-list");
            setActiveLink("groups");
          }}
        >
          <img src={Groups} alt="groups" className="menu_link_icon" />
          <img
            src={ActiveGroups}
            alt="reservation"
            className="menu_link_icon-active"
          />
          <span>Groups</span>
        </div>
        <div
          className={`menu_link_container ${
            activeLink?.split(")")[1] === "setup" &&
            "menu_link_container-active"
          }`}
          onMouseEnter={(e) => {
            const element = document.getElementById("dropdown-menu-setup");
            element.classList.add("dropdown-menu-setup-show");
          }}
          onMouseLeave={(e) => {
            const element = document.getElementById("dropdown-menu-setup");
            element.classList.remove("dropdown-menu-setup-show");
          }}
          style={{
            display: isSetupVisible || user?.user_id === 1 ? "flex" : "none",
          }}
        >
          <img src={Setup} alt="reservation" className="menu_link_icon" />
          <img
            src={ActiveSetup}
            alt="reservation"
            className="menu_link_icon-active"
          />
          <span>Setup</span>
          <SetupDropDown
            callback={(filteredItems) => {
              handleMenuVisibility(filteredItems, setIsSetupVisible);
            }}
          />
        </div>
        <div
          className={`menu_link_container ${
            activeLink?.split(")")[1] === "finance" &&
            "menu_link_container-active"
          }`}
          onMouseEnter={(e) => {
            const element = document.getElementById("dropdown-menu-finance");
            element.classList.add("dropdown-menu-finance-show");
          }}
          onMouseLeave={(e) => {
            const element = document.getElementById("dropdown-menu-finance");
            element.classList.remove("dropdown-menu-finance-show");
          }}
          style={{
            display: isFinanceVisible || user?.user_id === 1 ? "flex" : "none",
          }}
        >
          {/* {isFinanceVisible ? ( */}
          <>
            <img src={Finance} alt="reservation" className="menu_link_icon" />
            <img
              src={ActiveFinance}
              alt="reservation"
              className="menu_link_icon-active"
            />
            <span>Finance</span>
            <FinanceDropDown
              callback={(filteredItems) =>
                handleMenuVisibility(filteredItems, setIsFinanceVisible)
              }
            />
          </>
          {/* ) : null} */}
        </div>
        <div
          className={`menu_link_container ${
            activeLink?.split(")")[1] === "agency" &&
            "menu_link_container-active"
          }`}
          onMouseEnter={(e) => {
            const element = document.getElementById("dropdown-menu-agency");
            element.classList.add("dropdown-menu-agency-show");
          }}
          onMouseLeave={(e) => {
            const element = document.getElementById("dropdown-menu-agency");
            element.classList.remove("dropdown-menu-agency-show");
          }}
          style={{
            display: isAgencyVisible || user?.user_id === 1 ? "flex" : "none",
          }}
        >
          <img src={Agency} alt="reservation" className="menu_link_icon" />
          <img
            src={ActiveAgency}
            alt="reservation"
            className="menu_link_icon-active"
          />
          <span>Agency</span>
          <AgencyDropDown
            callback={(filteredItems) =>
              handleMenuVisibility(filteredItems, setIsAgencyVisible)
            }
          />
        </div>
      </div>
    </>
  );
}

export default NavigationBar;
