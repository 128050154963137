import { useNavigate, useParams } from "react-router-dom";
import iata from "../assets/images/iata.png";
import logo from "../assets/images/zairaa.jpeg";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useRef } from "react";
import moment from "moment";
import { TwoDecimalPoint } from "../Utils/FixedTwoDecimal";
import ReactToPrint from "react-to-print";
const SupplierStatement = ({
  data,
  agentData,
  voucher,
  setChangeComponet,
  fromDate,
  toDate,
  statement,
  userData,
}) => {
    const { adminAgencyData } = useSelector((state) => state.agentsCategories);

  let cumulativeBalance = 0;
  const calculateCumulativeBalance = (transaction) => {
    const debit = TwoDecimalPoint(
      voucher?.curr === "PKR"
        ? transaction?.acc_voucher_trans?.at(0)?.d_cur_debit
        : transaction?.acc_voucher_trans?.at(0)?.d_cur_debit /
            agentData?.agent_group?.currency?.roe
    );
    const credit = TwoDecimalPoint(
      voucher?.curr === "PKR"
        ? transaction?.acc_voucher_trans?.at(0)?.d_cur_credit
        : transaction?.acc_voucher_trans?.at(0)?.d_cur_credit /
            agentData?.agent_group?.currency?.roe
    );
    cumulativeBalance += debit - credit;
    //  cumulativeBalance = cumulativeBalance + debit - credit;

  return cumulativeBalance;
  };
  const transformSign = (balance) => {
    return balance < 0 ? Math.abs(balance) : -Math.abs(balance);
  };
  const naivate = useNavigate();
  const obj = {
    INV: "manual-invoice",
    RV: "vouchers-entry",
  };
  const componentRef = useRef();
  const firstColor = adminAgencyData?.first_color;
  return (
    <div className="d-flex  flex-column gap-4 bg-white">
      <div className="p-4 fs-4 no-print border border-secondry ">
        <div
          className="d-flex justify-content-between align-items-center"
          style={{ marginRight: "100px", marginLeft: "100px" }}
        >
          <ReactToPrint
            trigger={() => (
              <button className="bg_primary px-4 py-1 text-white">Print</button>
            )}
            content={() => componentRef.current}
          />
          <button
            onClick={(e) => setChangeComponet("search")}
            className="bg_primary px-4 py-1 rounded text-white"
          >
            Back
          </button>
        </div>
      </div>
      {/* bottom setion */}
      <div className="d-flex flex-column gap-3 mt-4" ref={componentRef}>
        {/* logo */}
        <div className="d-flex justify-content-around align-items-center">
          <div style={{ width: "300px" }}>
            <img
              src={logo}
              alt="Logo"
              style={{ width: "70%", height: "auto" }}
            />
          </div>
          <div
            className="d-flex flex-column gap-2 align-items-center"
            style={{ width: "1200px" }}
          >
            <span className="fs-4 text-black">
              {" "}
              {userData?.agents_comp?.agent_name}
            </span>
            <span className="fs-5 text-black">
              {" "}
              {userData?.agents_comp?.address
                ?.split("]")
                ?.slice(0, 3)
                ?.join(" ")}{" "}
            </span>
            <span className="fs-5 text-black"> Email: {userData?.email}</span>
            <span className="fs-5 text-black">
              {" "}
              Contact:{userData?.mobile_no}
            </span>
          </div>
          <div style={{ width: "200px" }}>
            <img
              src={iata}
              alt="iata"
              style={{ width: "70%", height: "auto" }}
            />
          </div>
        </div>
        <div
          className="d-flex justify-content-center  mx-4 py-2 align-items-center d-flex flex-column"
          style={{ backgroundColor: firstColor }}
        >
          <span className="fs-3 text-white "> Account Statement</span>
          <span className="fs-6 text-white py-1 "> ({agentData})</span>
        </div>
        {/* statement */}
        {agentData?.credit_limit ? (
          <div
            className="d-flex justify-content-between fs-5"
            style={{ marginLeft: "250px", marginRight: "250px" }}
          >
            {statement === "agent" ? (
              <div>
                <p> From: {moment(fromDate)?.format("ddd DD-MMM-YY")}</p>
                <p> To: {moment(toDate)?.format("ddd DD-MMM-YY")} </p>
                <p>
                  {" "}
                  Currency:{" "}
                  {voucher?.curr === "PKR"
                    ? "PKR"
                    : agentData?.agent_group?.currency?.curr_code}
                </p>
              </div>
            ) : null}
            <div>
              <div className="d-flex gap-2">
                <p> Approve Limit {agentData?.credit_limit} </p>
                <p style={{ color: "#0000ff" }}>
                  {agentData?.agent_group?.currency?.curr_code}{" "}
                  {TwoDecimalPoint(
                    Number(agentData?.credit_limit) /
                      Number(agentData?.agent_group?.currency?.roe)
                  )}
                </p>
              </div>
              <div className="d-flex gap-2">
                <p>Used Limit {agentData?.credit_used} </p>
                <p style={{ color: "#0000ff" }}>
                  {agentData?.agent_group?.currency?.curr_code}{" "}
                  {TwoDecimalPoint(
                    Number(agentData?.credit_used) /
                      Number(agentData?.agent_group?.currency?.roe)
                  )}{" "}
                </p>
              </div>
              <div className="d-flex gap-2">
                <p>
                  {" "}
                  Available Limit{" "}
                  {agentData?.credit_limit - agentData?.credit_used}{" "}
                </p>
                <p style={{ color: "#0000ff" }}>
                  {agentData?.agent_group?.currency?.curr_code}{" "}
                  {TwoDecimalPoint(
                    (agentData?.credit_limit - agentData?.credit_used) /
                      agentData?.agent_group?.currency?.roe
                  )}
                </p>
              </div>
            </div>
          </div>
        ) : null}
        {/* table */}
        <div
          className="d-flex justify-content-around mx-1 "
          style={{
            marginBottom: "80px",
          }}
        >
          <table className="table table-bordered">
            <thead>
              <tr>
                <th>VN NO</th>
                <th>DATE</th>
                <th>Description</th>
                <th>DEBIT</th>
                <th>CREDIT</th>
                <th>BALANCE</th>
              </tr>
            </thead>
            <tbody>
              {data?.map((transaction, index) => (
                <tr key={index}>
                  {statement === "agent" &&
                  voucher?.op === "opb" &&
                  index === 0 ? (
                    <>
                      <td colSpan="5" className=" text-center">
                        {
                          transaction?.acc_voucher_trans?.at(0)
                            ?.trans_description
                        }
                      </td>
                      <td colSpan="1">
                        {/* {transaction?.trans?.d_cur_debit}{" "} */}
                        {TwoDecimalPoint(
                          transformSign(calculateCumulativeBalance(transaction))
                        )}
                      </td>
                    </>
                  ) : (
                    <>
                      <td
                        className="text-primary"
                        style={{ cursor: "pointer" }}
                        onClick={(e) => {
                          const url = `/${
                            obj[transaction?.voucher_no?.split("-")?.at(0)]
                          }/${
                            transaction?.voucher_no?.split("-")?.at(0) === "INV"
                              ? `${~~transaction?.acc_voucher_trans
                                  ?.at(0)
                                  ?.trans_description?.split("/")
                                  ?.at(
                                    0
                                  )}/${~~transaction?.acc_voucher_trans?.at(0)
                                  ?.tkt_voucher_no}/${transaction?.voucher_id}`
                              : transaction?.voucher_id
                          }`;
                          window.open(url, "_blank", "noopener,noreferrer");
                        }}
                      >
                        {transaction.voucher_no}
                      </td>
                      <td>{transaction.saved_date}</td>
                      <td>
                        {
                          transaction?.acc_voucher_trans?.at(0)
                            ?.trans_description
                        }
                      </td>
                      <td>
                        {TwoDecimalPoint(
                          voucher?.curr === "PKR"
                            ? transaction?.acc_voucher_trans?.at(0)?.d_cur_debit
                            : transaction?.acc_voucher_trans?.at(0)
                                ?.d_cur_debit /
                                agentData?.agent_group?.currency?.roe
                        )}
                      </td>
                      <td>
                        {TwoDecimalPoint(
                          voucher?.curr === "PKR"
                            ? transaction?.acc_voucher_trans?.at(0)
                                ?.d_cur_credit
                            : transaction?.acc_voucher_trans?.at(0)?.d_cur_credit /
                                agentData?.agent_group?.currency?.roe
                        )}
                      </td>
                      <td>
                        {TwoDecimalPoint(
                          transformSign(calculateCumulativeBalance(transaction))
                        )}
                      </td>
                    </>
                  )}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default SupplierStatement;
