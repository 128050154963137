import React, { useEffect, useLayoutEffect, useState } from "react";
import AirSial from "./AirSial";
import Typography from "@mui/material/Typography";
import Serene from "./Serene";
import Pia from "./pia";
import FlyJinnahReturn from "./FlyJinnahReturn";
import { useDispatch, useSelector } from "react-redux";
import {
  asyncFlightsSearchResults,
  clearFlightResult,
  setExpandFlightDetails,
  set_Filters_null,
} from "../../../../redux/features/home/FlightSearchSlice";
import FlightSkeleton from "../OneWaySearchResult/FlightSkeleton";
import FlightsTab from "../../FlightsTab";
import moment from "moment";
import {
  isAirblueSectors,
  isDomesticSectors,
  isSereneSectors,
  isSialSectors,
} from "../../../../Utils/FlightSector";
import {
  asyncGetAddAirlinesData,
  asyncGetAirlinePricing,
} from "../../../../redux/features/setupRestPages/Price_AirLine_SuppRole";
import { asyncGetCurrencyData } from "../../../../redux/features/setupRestPages/Bank_curr_RoleSlice";
import { asyncGetAgentGroup } from "../../../../redux/features/setupRestPages/agentsSlice";
import { Accordion, AccordionDetails } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import SelectedFlight from "./SelectedFlight";
import ChangeAvailabiltyDate from "./ChangeAvailabiltyDate";
import Airblue from "./Airblue";
import SabreCard from "./SabreCard";
import Sidebar from "../OneWaySearchResult/Sidebar";
import FIlter from "../OneWaySearchResult/FIlter";
import AmadeusReturn from "../OneWaySearchResult/amadeus/AmadeusReturn";
import AmadeusSegmentCard from "../OneWaySearchResult/amadeus/AmadeusSegmentCard";
import FlyJinnahSegmentCard from "./FlyJinnah/FlyJinnahSegmentCard";
import { asyncGetNewSupplier } from "../../../../redux/features/supplier/AddNewSupplier";
import PiaHitit from "./PiaHitit";
import Timer from "../../checkout/Timer";

function ReturnSearchResult() {
  const dispatch = useDispatch();
  const [accordion, setAccordion] = useState(null);

  //use selector redux
  const {
    searchValues,
    loading,
    serene,
    sial,
    pia,
    airBlue,
    sabre,
    newDepDate,
    newArrivalDate,
    selectedFlight,
    expandFlightDetail,
    stop_filters,
    gds_filter,
    airline_filter,
    amadeus,
    flyJinnah,
  } = useSelector((state) => state.FlightSearch);
  const { currencyData } = useSelector((state) => state?.bank_curr_roleSlice);
  const { agentGroup } = useSelector((state) => state.agentsCategories);
  // get values from local storage
  const localsearchValues = JSON.parse(
    localStorage.getItem("flightSearchData")
  );
  //agent group
  const agent_grp = agentGroup?.find(
    (item) => item.agent_grp_id === localsearchValues?.agent?.agent_grp_id
  );

  // currency of the selected agent group
  const currency = currencyData?.find(
    (it) => it.currency_id === agent_grp?.currency_id
  );

  //set local storage
  localStorage.setItem(
    "currency",
    JSON.stringify(currency !== undefined ? currency : "")
  );
  //total travelor
  const totalTravelor =
    localsearchValues?.adultsCount +
    localsearchValues?.childCount +
    localsearchValues?.infantNo;

  let locDepCode = localsearchValues?.locationDep?.split("-")?.at(0);
  let locArrCode = localsearchValues?.locationArrival?.split("-")?.at(0);

  const gdsArray = localsearchValues?.gds?.filter((it) => {
    if (it === "Air sial" && isSialSectors(locDepCode, locArrCode)) {
      return "Air Sial"; // Filter out "Air Sial"
    } else if (it === "Serene" && isSereneSectors(locDepCode, locArrCode)) {
      return "Serene"; // Filter out "Serene"
    } else if (it === "Air Blue" && isAirblueSectors(locDepCode, locArrCode)) {
      return "Air blue";
    } else if (it === "HITIT") {
      return "HITIT";
    } else if (it === "Sabre" && !isDomesticSectors(locDepCode, locArrCode)) {
      return "Sabre";
    } else if (it === "Amadeus" && !isDomesticSectors(locDepCode, locArrCode)) {
      return "Amadeus";
    } else if (it === "Fly Jinnah") {
      return "Fly Jinnah";
    }
    // Keep all other items
    return false;
  });

  const setGds = ["Air sial", "Serene", "Air Blue", "HITIT", "Fly Jinnah", "Amadeus"].some(
    (airline) => gdsArray?.includes(airline)
  );

  const [duration, setDuration] = useState(setGds ? 5 : 10);
  const [cancel, setCancel] = useState(false);
  const [timer, setTimer] = useState("");

  useEffect(() => {
    if (timer?.minutes <= 0 && timer?.seconds <= 0) {
      setCancel(!cancel);
    }
  }, [timer]);

  // set origin and des name
  const getOriginDesName = (loc) => {
    let code = loc.split("-")?.at(0);
    let airport = loc.split("-")[1];
    return `${code}-${airport}`;
  };

  const toggleDetail = (index) => {
    if (expandFlightDetail === index) {
      // If the clicked item is already expanded, collapse it
      dispatch(setExpandFlightDetails(null));
    } else {
      // Expand the clicked item
      dispatch(setExpandFlightDetails(index));
    }
  };
  const getCityName = (loc) => {
    let city = loc?.split("-")[2];
    return city;
  };

  //////////////// find outbound flight for airblue
  const getAirblueFlight = new Map();
  // Iterate through the PricedItinerary items
  airBlue?.PricedItinerary?.forEach((item) => {
    const flightNumber =
      item?.AirItinerary?.OriginDestinationOptions?.OriginDestinationOption
        ?.FlightSegment?.$?.FlightNumber;
    const depCode =
      item?.AirItinerary?.OriginDestinationOptions?.OriginDestinationOption
        ?.FlightSegment?.DepartureAirport?.$?.LocationCode;
    if (selectedFlight) {
      // Check if both FlightNumber and depCode match the conditions
      if (flightNumber && depCode === locArrCode) {
        // If the FlightNumber is already in the map, push the item to the existing array
        if (getAirblueFlight.has(flightNumber)) {
          getAirblueFlight.get(flightNumber).push(item);
        } else {
          // If the FlightNumber is not in the map, create a new array with the item
          getAirblueFlight.set(flightNumber, [item]);
        }
      }
    } else {
      // Check if both FlightNumber and depCode match the conditions
      if (flightNumber && depCode === locDepCode) {
        // If the FlightNumber is already in the map, push the item to the existing array
        if (getAirblueFlight.has(flightNumber)) {
          getAirblueFlight.get(flightNumber).push(item);
        } else {
          // If the FlightNumber is not in the map, create a new array with the item
          getAirblueFlight.set(flightNumber, [item]);
        }
      }
    }
  });

  // Extract and return just the values as an array
  const newAirblue = [...getAirblueFlight.values()];

  //uselayout effect
  useLayoutEffect(() => {
    dispatch(asyncGetAgentGroup());
    dispatch(asyncGetAirlinePricing());
    dispatch(asyncGetCurrencyData());
    dispatch(asyncGetAddAirlinesData());
    dispatch(asyncGetNewSupplier());
  }, [dispatch, searchValues]);
  //use effect
  // flight search api call
  useEffect(() => {
    //clear all flight result states
    dispatch(clearFlightResult());
    dispatch(setExpandFlightDetails(null));
    dispatch(set_Filters_null());
    setAccordion(false);

    gdsArray?.forEach((element) => {
      let data = {
        departingOn: newDepDate ? newDepDate : localsearchValues?.departingOn,
        locationDep: getOriginDesName(localsearchValues?.locationDep),
        locationArrival: getOriginDesName(localsearchValues?.locationArrival),
        ReturningOn: newArrivalDate
          ? newArrivalDate
          : localsearchValues?.ReturningOn,
        multi_des_from: localsearchValues?.multi_des_from,
        multi_des_to: localsearchValues?.multi_des_to,
        multi_des_from_date: localsearchValues?.multi_des_from_date,
        multi_des_ticket_class: localsearchValues?.multi_des_ticket_class,
        adultsCount: localsearchValues?.adultsCount,
        adult_ages: localsearchValues?.adult_ages,
        childCount: localsearchValues?.childCount,
        children_ages: localsearchValues?.children_ages,
        infantNo: localsearchValues?.infantNo,
        infant_ages: localsearchValues?.infant_ages,
        trip_type: localsearchValues?.trip_type,
        gds: element.replace(/\s/g, ""),
        ticket_class: localsearchValues?.ticket_class,
        umrah_fare: localsearchValues?.umrah_fare,
      };
      let tm = Date.now();
      localStorage.setItem("timer", JSON.stringify(tm));
      dispatch(asyncFlightsSearchResults(data));
    });
  }, [dispatch, searchValues, newDepDate, newArrivalDate, cancel]);

  return (
    <div className="col-11 mx-auto pt-5">
      <div className="pb-4">
        <div className="d-flex">
          {/* search engin *****************************/}
          <div className="col-12 bg-white shadow-sm">
            <Accordion
              expanded={accordion}
              onChange={(event, isExpanded) =>
                setAccordion(isExpanded ? true : false)
              }
            >
              <div
                className="accordion_heading"
                onClick={() => setAccordion(!accordion)}
              >
                <Typography>Edit Search</Typography>
                <ExpandMoreIcon />
              </div>
              <AccordionDetails className="px-5">
                <FlightsTab searchData={localsearchValues} />
              </AccordionDetails>
            </Accordion>
          </div>
        </div>

        <div className="d-flex flex-column flex-md-row mt-2 mt-md-5 gap-4">
          {/* filters *******************************/}
          <div
            className="search_engin_filter bg-white"
            style={{ position: "sticky", top: 30, left: 0 }}
          >
            <Sidebar />
          </div>

          {/* search results ***********************/}
          <div className="search_data">
            <div>
              <FIlter />
            </div>
            {selectedFlight ? (
              <div>
                <div className="pt-3">
                  <h5>
                    Your Selected Flight from{" "}
                    {getCityName(localsearchValues?.locationDep)} to{" "}
                    {getCityName(localsearchValues?.locationArrival)}
                  </h5>
                  <h5>
                    {selectedFlight?.depDate}, {totalTravelor} Travelor(s)
                  </h5>
                </div>
                <SelectedFlight />
              </div>
            ) : null}
            <div className="py-3 d-flex justify-content-between align-items-center">
              <div>
                <h5>
                  {selectedFlight
                    ? `Select your Flight from ${localsearchValues?.locationArrival?.split("-")[2]
                    } to ${localsearchValues?.locationDep?.split("-")[2]}`
                    : `Select your Flight from ${localsearchValues?.locationDep?.split("-")[2]
                    } to ${localsearchValues?.locationArrival?.split("-")[2]
                    }`}
                </h5>
                <h5>
                  {moment(
                    selectedFlight && newArrivalDate
                      ? newArrivalDate
                      : !selectedFlight && newDepDate
                        ? newDepDate
                        : !selectedFlight && !newDepDate
                          ? localsearchValues?.departingOn
                          : localsearchValues?.ReturningOn,
                    "DD-MM-YYYY"
                  ).format("ddd, Do MMM")}
                  , {totalTravelor} Travelor(s)
                </h5>
              </div>
              {/* ///////////// change availabilty date///////// */}

              <div className="d-flex flex-column gap-2">
                <ChangeAvailabiltyDate />
                <p>
                  <Timer
                    duration={duration}
                    availabilityTime={JSON.parse(localStorage.getItem("timer"))}
                    setTimer={setTimer}
                    timer={timer}
                  />
                </p>
              </div>
            </div>

            <div className="d-flex flex-column">
              <div>
                {loading ? (
                  <FlightSkeleton />
                ) : ["NON STOP", "ALL"].includes(stop_filters) &&
                  ["Serene", "ALL"].includes(gds_filter) &&
                  ["ER", "ALL"].includes(airline_filter) ? (
                  amadeus?.outbound?.map((item, index) => (
                    <AmadeusSegmentCard
                      amadeusItem={item}
                      key={index}
                      index={"amadeus" + index}
                      expandFlightDetail={expandFlightDetail}
                      toggleDetail={toggleDetail}
                    />
                  ))
                ) : null}
              </div>

              {(!selectedFlight || selectedFlight?.code === "ER") && (
                <div
                  className={`${selectedFlight?.logo !== "Serene" ? "order-1" : ""
                    }`}
                >
                  {serene === null && gdsArray?.includes("Serene") ? (
                    <FlightSkeleton />
                  ) : ["NON STOP", "ALL"].includes(stop_filters) &&
                    ["Serene", "ALL"].includes(gds_filter) &&
                    ["ER", "ALL"].includes(airline_filter) ? (
                    serene?.routes[
                      serene?.routes[1] && selectedFlight ? 1 : 0
                    ]?.flights?.map((item, index) => (
                      <Serene
                        serene={item}
                        key={index}
                        index={"serene" + index}
                        isExpanded={expandFlightDetail === "serene" + index}
                        toggleDetail={toggleDetail}
                      />
                    ))
                  ) : null}
                </div>
              )}
              {(!selectedFlight || selectedFlight?.code === "PF") && (
                <div
                  className={`${selectedFlight?.logo !== "Sial" ? "order-1" : ""
                    }`}
                >
                  {sial === null && gdsArray?.includes("Air sial") ? (
                    <FlightSkeleton />
                  ) : ["NON STOP", "ALL"].includes(stop_filters) &&
                    ["Air Sial", "ALL"].includes(gds_filter) &&
                    ["PF", "ALL"].includes(airline_filter) ? (
                    (selectedFlight && sial?.inbound
                      ? sial?.inbound
                      : sial?.outbound
                    )?.map((item, index) => (
                      <AirSial
                        data={item}
                        key={index}
                        index={"sial" + index}
                        isExpanded={expandFlightDetail === "sial" + index}
                        toggleDetail={toggleDetail}
                      />
                    ))
                  ) : null}
                </div>
              )}
              {(!selectedFlight || selectedFlight?.code === "PA") && (
                <div
                  className={`${selectedFlight?.code !== "PA" ? "order-1" : ""
                    }`}
                >
                  {airBlue === null && gdsArray?.includes("Air Blue") ? (
                    <FlightSkeleton />
                  ) : ["NON STOP", "ALL"].includes(stop_filters) &&
                    ["Airblue", "ALL"].includes(gds_filter) &&
                    ["PA", "ALL"].includes(airline_filter) ? (
                    newAirblue?.map((item, index) => (
                      <Airblue
                        data={item}
                        key={index}
                        index={"airblue" + index}
                        isExpanded={expandFlightDetail === "airblue" + index}
                        toggleDetail={toggleDetail}
                      />
                    ))
                  ) : null}
                </div>
              )}

              {(!selectedFlight || selectedFlight?.code === "PK") && (
                <div
                  className={`${selectedFlight?.logo !== "HITIT" ? "order-1" : ""
                    }`}
                >
                  {pia === null && gdsArray?.includes("HITIT") ? (
                    <FlightSkeleton />
                  ) : ["HITIT", "ALL"].includes(gds_filter) &&
                    ["PK", "ALL"].includes(airline_filter) ? (
                    pia?.map((piaData, index) => (
                      <PiaHitit
                        piadata={piaData}
                        key={index}
                        index={index}
                        isExpanded={expandFlightDetail === index}
                        toggleDetail={toggleDetail}
                        flight="return"
                      />
                    ))
                  ) : null}
                  {/* {pia === null && gdsArray?.includes("HITIT") ? (
                    <FlightSkeleton />
                  ) : ["HITIT", "ALL"].includes(gds_filter) &&
                    ["PK", "ALL"].includes(airline_filter) ? (
                    pia?.length ? (
                      selectedFlight ? (
                        pia
                          ?.filter((item) => {
                            let curFLNum =
                              item?.fareComponentGroupList?.boundList?.at(0)
                                ?.availFlightSegmentList?.flightSegment
                                ?.flightNumber;
                            let selectedFLnum =
                              selectedFlight?.flight?.fareComponentGroupList?.boundList?.at(
                                0
                              )?.availFlightSegmentList?.flightSegment
                                ?.flightNumber;
                            if (curFLNum === selectedFLnum) {
                              return item;
                            }
                            return false;
                          })
                          ?.reverse()
                          ?.map((piaData, index, arra) => {
                            return (
                              <Pia
                                piadata={piaData}
                                key={index}
                                index={index}
                                isExpanded={expandFlightDetail === index}
                                toggleDetail={toggleDetail}
                              />
                            );
                          })
                      ) : (
                        [
                          ...new Map(
                            pia?.map((item) => [
                              Array.isArray(
                                item?.fareComponentGroupList?.boundList?.at(0)
                                  ?.availFlightSegmentList
                              )
                                ? item?.fareComponentGroupList?.boundList
                                    ?.at(0)
                                    ?.availFlightSegmentList?.at(0)
                                    ?.flightSegment?.flightNumber
                                : item?.fareComponentGroupList?.boundList?.at(0)
                                    ?.availFlightSegmentList?.flightSegment
                                    ?.flightNumber,
                              item,
                            ])
                          ).values(),
                        ]?.map((piaData, index) => (
                          <Pia
                            piadata={piaData}
                            key={index}
                            index={index}
                            isExpanded={expandFlightDetail === index}
                            toggleDetail={toggleDetail}
                          />
                        ))
                      )
                    ) : null
                  ) : null} */}
                </div>
              )}

              {sabre === null && gdsArray?.includes("Sabre") ? (
                <FlightSkeleton />
              ) : (
                sabre?.map((item, index) => (
                  <div className="my-2">
                    <SabreCard
                      data={item}
                      key={index}
                      index={index}
                      isExpanded={expandFlightDetail === index}
                      toggleDetail={toggleDetail}
                    />
                  </div>
                ))
              )}



              <div>
                {flyJinnah && Array.isArray(flyJinnah) && gdsArray?.includes("Fly Jinnah") ? (
                  flyJinnah?.map((item, index) => (
                    <FlyJinnahSegmentCard
                      flyjinnahItem={item}
                      key={index}
                      isExpanded={expandFlightDetail === "FlyJinnah" + index}
                      index={"FlyJinnah" + index}
                      expandFlightDetail={expandFlightDetail}
                      toggleDetail={toggleDetail}
                    />
                  ))
                ) : null}
              </div>


            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ReturnSearchResult;



{/* <div>
                {loading ? (
                  <FlightSkeleton />
                ) : ["NON STOP", "ALL"].includes(stop_filters) &&
                  ["Fly Jinnah", "ALL"].includes(gds_filter) &&
                  ["9P", "ALL"].includes(airline_filter) ? (
                  flyJinnah?.map((item, index) => (
                    <FlyJinnahSegmentCard
                      flyjinnahItem={item}
                      key={index}
                      isExpanded={expandFlightDetail === "FlyJinnah" + index}
                      index={"FlyJinnah" + index}
                      expandFlightDetail={expandFlightDetail}
                      toggleDetail={toggleDetail}
                    />
                  ))
                ) : null}
              </div> */}