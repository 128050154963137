import React, { useState } from "react";
// import CustomDropdown from "../components/CustomDrp";
import "../Styles/manualInvoices.css";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { BlobProvider } from "@react-pdf/renderer";
import AgentListPdf from "../components/Pdf/AgentListPdf";

function AgentList() {
  const [first, setfirst] = useState("");
  const arry = ["Travel Agent", "Corporate", "Travel Consultant"];
  return (
    <div className=" finance-container Full_height">
      <div className="manual_invoices mx-auto mt-5">
        <div className="manual_invoices_header px-md-5">
          <div className="w-100 text-center">
            Agent Outstanding Reports
          </div>
        </div>
        <div className="px-5 py-4">
          <div className="pt-5 ">
            {/* <CustomDropdown arry={['Active', 'Inactive', 'Pending']} placehlder='--select status--' setfunction={setfirst} /> */}
            <FormControl variant="standard" className="w-100" sx={{}}>
              <InputLabel id="demo-simple-select-standard-label">
                Select Agent
              </InputLabel>
              <Select
                labelId="demo-simple-select-standard-label"
                id="demo-simple-select-standard"
                value={first}
                onChange={(e) => setfirst(e.target.value)}
                label="Select Agent"
              >
                {arry.map((list) => (
                  <MenuItem value={list} sx={{ m: 1, bgcolor: '#fff'}} key={list}>
                    {list}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>

          <div className="d-flex justify-content-center align-items-center my-4 mt-5">
            {/* <button className="btn btn-primary">Generate Report</button> */}
            <div className="my-4">
            <BlobProvider document={<AgentListPdf first={first}/>}>
              {({ url, loading }) => {
                if (loading) {
                  return <div className="button_style">Loading...</div>;
                }
                return (
                  <div className="">
                    <a href={url} target="_blank" rel="noopener noreferrer" className="button_style no-underline ">
                      Generate Report
                    </a>
                  </div>
                );
              }}
            </BlobProvider>
          </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AgentList;
