import React, { useEffect, useRef, useState } from 'react';
import './chat.css';
import { URL2 } from '../../redux/features/ApiConfig';
import axios from 'axios';
import moment from 'moment';
import { fetchMessagesCount } from '../../redux/features/requests/flightrequest';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

const Chat = ({ data, agencyData }) => {
    const dispatch =useDispatch()
    const location = useLocation();
    const [message, setMessage] = useState('');
    const [messages, setMessages] = useState([]);
    const chatEndRef = useRef(null);
    const stor = JSON.parse(localStorage.getItem("loginUser"));
    const count = useSelector((state) => state?.flightrequest?.Count);
    const booking = data?.booking;
    // const agent = stor?.agent_id
    const agent = stor?.agent_id !==1 ? 1 : booking?.acc_agents?.agents_comp?.[0]?.agent_id
    const c_id=stor?.user_id
    const handleSendMessage = async () => {
      if (!message.trim()) return;
  
      const payload = {
        book_id: booking?.id,
        user_id: stor?.user_id,
        agent_id: agent,
        message_text: message,
        ad_ag_name: stor?.agents_comp?.agent_name,
        status: 'unread',
      };
  
      try {
        await axios.post(`${URL2}/api/v1/b2b/chats`, payload);
        setMessage(''); // Clear input
        fetchMessagesForAgent();
      } catch (error) {
        console.error('Error sending message:', error);
      }
    };

    const fetchMessagesForAgent = async () => {
        try {
          const response = await axios.get(`${URL2}/api/v1/b2b/chats/agent/${booking?.id}`);
          const fetchedMessages= response.data.data
          setMessages(fetchedMessages);

          // Get all unread messages
        const unreadMessages = fetchedMessages.filter(msg => msg.status === 'unread' && msg.agent_id !== agent);
        
        // Mark unread messages as read
        if (unreadMessages.length > 0) {
            const messageIds = unreadMessages.map(msg => msg.req_id);
            await axios.patch(`${URL2}/api/v1/b2b/chats/read/`, { ids: messageIds });
        }
        
        //  const deleteMessages = fetchedMessages.filter(msg => msg.status === 'read' );
        // If there are more than 25 read messages, delete the first 10
        //   if (deleteMessages.length > 25) {
        //     const messageIdsToDelete = deleteMessages.slice(0, 10).map(msg => msg.req_id);
        //     console.log("messageIdsToDelete",messageIdsToDelete)
        //     await axios.delete(`${URL2}/api/v1/b2b/chats/delete/`, { data: { ids: messageIdsToDelete } });
        //   }
        } catch (error) {
          console.error('Error fetching messages:', error);
        }
      };
    
    useEffect(() => {
        fetchMessagesForAgent();
      },[location,count]);

    useEffect(() => {
        dispatch(fetchMessagesCount({c_id:agent}));
      }, [location,count]);
      useEffect(() => {
        // Scroll to the bottom of the chat whenever new messages are added
        if (chatEndRef.current) {
            chatEndRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, [messages]);

  return (
    <div className="chat-container ">
      <div className="chat-messages">
        {messages.map((msg, index) => (
          <div
            key={index}
            className={`chat-bubble ${msg.user_id === stor.user_id ? 'chat-bubble-right' : 'chat-bubble-left'}`}
          >
            {msg.message_text}
            
            <div className="chat-timestamp mt-1">
              <span >
                {new Date(msg.msg_date_time).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
                </span>
            
            </div>
         
          </div>
        ))}
        <div ref={chatEndRef} /> 
      </div>
      
      <div className="chat-input">
        <input
          type="text"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          placeholder="Type a message..."
        />
        <button onClick={handleSendMessage}>Send</button>
     
      </div>
    </div>
  );
};

export default Chat;
