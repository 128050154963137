import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { BlobProvider } from "@react-pdf/renderer";
import React, { useState } from "react";
import SupplierListPdf from "../../Pdf/SupplierListPdf";
// import PrintData from "./PrintData";
import FlightPdf from "../../Pdf/FlightPdf";

const SupplierListComp = () => {
  const [first, setfirst] = useState("Active");
  const arry = ["Active", "Inactive"];
  return (
    <div>
      <div className="manual_invoices mx-auto mt-5">
        <div className="manual_invoices_header px-md-5">
          <div className="w-100 text-center">
            Supplier Reports (Date / Status Wise)
          </div>
        </div>
        <div className="px-5 py-4">
          <div className="pt-5 ">
            <FormControl variant="standard" className="w-100" sx={{}}>
              <InputLabel id="demo-simple-select-standard-label">
                select status
              </InputLabel>
              <Select
                labelId="demo-simple-select-standard-label"
                id="demo-simple-select-standard"
                value={first}
                onChange={(e) => setfirst(e.target.value)}
                label="select status"
              >
                {arry.map((list) => (
                  <MenuItem
                    value={list}
                    sx={{ m: 1, bgcolor: "#fff" }}
                    key={list}
                  >
                    {list}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>

          <div className="d-flex justify-content-center align-items-center my-4 mt-5">
            <div className="my-4">
              <BlobProvider document={<SupplierListPdf first={first} />}>
                {({ url, loading }) => {
                  if (loading) {
                    return <div className="button_style">Loading...</div>;
                  }
                  return (
                    <div className="">
                      <a
                        href={url}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="button_style no-underline "
                      >
                        Generate Report
                      </a>
                    </div>
                  );
                }}
              </BlobProvider>
            </div>

            {/* ************PDF********** */}
            {/* <div className="my-4">
                    <BlobProvider document={<PrintData />}>
                      {({ url, loading }) => {
                        if (loading) {
                          return <div>Loading...</div>;
                        }
                        return (
                          <div>
                            <a
                              href={url}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              Generate PDF
                            </a>
                          </div>
                        );
                      }}
                    </BlobProvider>
                  </div> */}

            {/* ************PDF********** */}
            {/* <div className="my-4">
                    <BlobProvider document={<FlightPdf />}>
                      {({ url, loading }) => {
                        if (loading) {
                          return <div>Loading...</div>;
                        }
                        return (
                          <div>
                            <a
                              href={url}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              Generate Flight PDF
                            </a>
                          </div>
                        );
                      }}
                    </BlobProvider>
                  </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SupplierListComp;
