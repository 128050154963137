import React from "react";
import AirSial from "../../../../assets/images/airlineLogo/PF.png";
import moment from "moment";

import {
  MdOutlineRestaurant,
  MdSwapHoriz,
  MdCheck,
  MdRemoveRedEye,
  MdOutlineFlightTakeoff,
  MdOutlineFlightLand,
} from "react-icons/md";
import { BsBagCheck, BsPerson, BsHandbag } from "react-icons/bs";
import { CgMenuGridR } from "react-icons/cg";
import {
  CurrencyCode,
  CurrencyExchange,
  CurrencyExchangeToPKR,
} from "../../../../Utils/currencyExchange";
import { pricingairline } from "../../../../Utils/airlinePricing";
import { getSectorsName } from "../../../../Utils/FlightSector";
import { useDispatch, useSelector } from "react-redux";
import { LightTooltip } from "../OneWaySearchResult/LightTooltip";
import {
  asyncPostSialPnr,
  setExpandFlightDetails,
  setReturnFlight,
  setselectedFlight,
} from "../../../../redux/features/home/FlightSearchSlice";
import { useNavigate } from "react-router-dom";
import { HiEye } from "react-icons/hi";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import { ExclusiveSetors } from "../../../../Utils/exclusiveSector";
import { TwoDecimalPoint } from "../../../../Utils/FixedTwoDecimal";

/////////////// Sial /////////////
function AirsialData({ data, index, isExpanded, toggleDetail, selectedFare }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { sial, selectedFlight, sialpnrData, pnrLoading } = useSelector((state) => state.FlightSearch);
  const { airlinePricing } = useSelector(
    (state) => state?.price_airlines_suppRoleSlice
  );
  const { addAirLinesData } = useSelector(
    (state) => state?.price_airlines_suppRoleSlice
  );
  //local storage data
  const searchValues = JSON.parse(localStorage.getItem("flightSearchData"));
  // airline pricing
  const pricing = airlinePricing?.find(
    (item) =>
      item.bg_id === searchValues?.agent?.agent_grp_id &&
      item?.add_airline?.code_alpha === "PF"
  );
  //airline
  const airline = addAirLinesData?.find((it) => it.code_alpha === "PF");

  const locDeparture = searchValues?.locationDep;
  const locArrival = searchValues?.locationArrival;
  // calculate the lowest fare price
  const lowestFarePrice = (fare) => {
    let newFare =
      (pricingairline(
        airline,
        pricing,
        Number(CurrencyExchange(fare?.ADULT?.BASIC_FARE)),
        Number(CurrencyExchange(fare?.ADULT?.TOTAL - fare?.ADULT?.BASIC_FARE)),
        getSectorsName(locDeparture, locArrival),
        ExclusiveSetors(searchValues, pricing)
      )?.total_fare * searchValues?.adultsCount || 0) +
      (pricingairline(
        airline,
        pricing,
        Number(CurrencyExchange(fare?.CHILD?.BASIC_FARE)),
        Number(CurrencyExchange(fare?.CHILD?.TOTAL - fare?.CHILD?.BASIC_FARE)),
        getSectorsName(locDeparture, locArrival),
        ExclusiveSetors(searchValues, pricing)
      )?.total_fare * searchValues?.childCount || 0) +
      (pricingairline(
        airline,
        pricing,
        Number(CurrencyExchange(fare?.INFANT?.BASIC_FARE)),
        Number(
          CurrencyExchange(fare?.INFANT?.TOTAL - fare?.INFANT?.BASIC_FARE)
        ),
        getSectorsName(locDeparture, locArrival),
        ExclusiveSetors(searchValues, pricing)
      )?.total_fare * searchValues?.infantNo || 0);
    return newFare;
  };

  // Function to get discount for a single fare
// const singleFareDiscount = (fare, paxCount) => {
//   return (
//     (pricingairline(
//       airline,
//       pricing,
//       Number(CurrencyExchange(fare?.BASIC_FARE)),
//       Number(CurrencyExchange(fare?.TOTAL - fare?.BASIC_FARE)),
//       getSectorsName(locDeparture, locArrival),
//       ExclusiveSetors(searchValues, pricing)
//     )?.sp * paxCount) || 0
//   );
// };

// // Function to combine discounts for outbound and inbound fares
// const combineDiscount = (fare, returnFare) => {
//   // console.log("fare",fare);
//   let discount = 
//     singleFareDiscount(fare?.ADULT, searchValues?.adultsCount) +
//     singleFareDiscount(fare?.CHILD, searchValues?.childCount) +
//     singleFareDiscount(fare?.INFANT, searchValues?.infantNo) +
//     singleFareDiscount(returnFare?.ADULT, searchValues?.adultsCount) +
//     singleFareDiscount(returnFare?.CHILD, searchValues?.childCount) +
//     singleFareDiscount(returnFare?.INF, searchValues?.infantNo);
  
//   return `Offer ${CurrencyCode()} ${TwoDecimalPoint(discount)}`;
// };
const DiscountPrice = (fare) => {
  let newFare =
    (pricingairline(
      airline,
      pricing,
      Number(CurrencyExchange(fare?.ADULT?.BASIC_FARE)),
      Number(CurrencyExchange(fare?.ADULT?.TOTAL - fare?.ADULT?.BASIC_FARE)),
      getSectorsName(locDeparture, locArrival),
      ExclusiveSetors(searchValues, pricing)
    )?.sp * searchValues?.adultsCount || 0) +
    (pricingairline(
      airline,
      pricing,
      Number(CurrencyExchange(fare?.CHILD?.BASIC_FARE)),
      Number(CurrencyExchange(fare?.CHILD?.TOTAL - fare?.CHILD?.BASIC_FARE)),
      getSectorsName(locDeparture, locArrival),
      ExclusiveSetors(searchValues, pricing)
    )?.sp * searchValues?.childCount || 0) +
    (pricingairline(
      airline,
      pricing,
      Number(CurrencyExchange(fare?.INFANT?.BASIC_FARE)),
      Number(
        CurrencyExchange(fare?.INFANT?.TOTAL - fare?.INFANT?.BASIC_FARE)
      ),
      getSectorsName(locDeparture, locArrival),
      ExclusiveSetors(searchValues, pricing)
    )?.sp * searchValues?.infantNo || 0);
  return TwoDecimalPoint(newFare);
};

  //////////////// get single pax fare
  const singlePaxFare = (fare, returnFare, pax) => {
    let newFare =
      pricingairline(
        airline,
        pricing,
        Number(CurrencyExchange(fare?.BASIC_FARE)),
        Number(CurrencyExchange(fare?.TOTAL - fare?.BASIC_FARE)),
        getSectorsName(locDeparture, locArrival),
        ExclusiveSetors(searchValues, pricing)
      )?.total_fare * pax || 0;
    let newReturn =
      pricingairline(
        airline,
        pricing,
        Number(CurrencyExchange(returnFare?.BASIC_FARE)),
        Number(CurrencyExchange(returnFare?.TOTAL - returnFare?.BASIC_FARE)),
        getSectorsName(locDeparture, locArrival),
        ExclusiveSetors(searchValues, pricing)
      )?.total_fare * pax || 0;
    return TwoDecimalPoint(newFare + newReturn);
  };

  //////////// convert time to required formate
  function formatDuration(inputDuration) {
    // Use a custom regular expression to extract hours and minutes
    const regex = /(\d+)h (\d+)m/;
    const match = inputDuration.match(regex);

    if (match) {
      const hours = parseInt(match[1]);
      const minutes = parseInt(match[2]);

      // Calculate the duration using Moment.js
      const duration = moment.duration({ hours, minutes });

      // Format the duration as 'hh:mm'
      const formattedDuration = `${duration
        .hours()
        .toString()
        .padStart(2, "0")}:${duration.minutes().toString().padStart(2, "0")}`;

      return formattedDuration;
    } else {
      return "Invalid input format"; // Handle invalid input
    }
  }

  // get city name
  const getCity = (loc) => {
    let city = loc?.split("-")[2]?.toUpperCase();
    return city;
  };

  ///////////////////
  function scrollToElement(elementId) {
    const element = document.getElementById(elementId);
    if (element) {
      const yOffset = element.getBoundingClientRect().top + window.scrollY;
      window.scrollTo({
        top: yOffset,
        behavior: "smooth",
      });
    }
  }


  // find combine fare for outbound and inbound
  const combineFare = (current) => {
    let total =
    current +
    lowestFarePrice(
      selectedFlight
      ? selectedFlight?.fare?.FARE_PAX_WISE
      : sial?.inbound[0]?.BAGGAGE_FARE[0]?.FARE_PAX_WISE
      );
    return TwoDecimalPoint(total);
  };

  //combine discount
   const combineDiscountFare = (current) => {
    let total =
    current +
    DiscountPrice(
      selectedFlight
      ? selectedFlight?.fare?.FARE_PAX_WISE
      : sial?.inbound[0]?.BAGGAGE_FARE[0]?.FARE_PAX_WISE
      );
      if(total>0){
        return `Off ${CurrencyCode()} ${TwoDecimalPoint(total)}`;
      } return ""
    
  };

  const combineBaseFare = (fare, paxType) => {
    let currentFare = fare?.[paxType];
    let groupedFare = selectedFlight
      ? selectedFlight?.fare?.FARE_PAX_WISE?.[paxType]
      : sial?.inbound[0]?.BAGGAGE_FARE[0]?.FARE_PAX_WISE?.[paxType];

    return {
      basic: TwoDecimalPoint(currentFare?.BASIC_FARE + groupedFare?.BASIC_FARE),
      taxes: TwoDecimalPoint(
        currentFare?.TOTAL -
          currentFare?.BASIC_FARE +
          (groupedFare?.TOTAL - groupedFare?.BASIC_FARE)
      ),
    };
  };

  const supplierPrice = (fare) => {
    const adult = combineBaseFare(fare,"ADULT");
    const child = combineBaseFare(fare,"CHILD");
    const infant = combineBaseFare(fare,"INFANT");
    return (
      adult.basic + adult.taxes +
      ((child.basic + child.taxes) * searchValues.childCount) +
      ((infant.basic + infant.taxes) * searchValues.infantNo) 
    )
  };

  const getTaxes = (fare, paxType) => {
    let groupedFare = selectedFlight
      ? selectedFlight?.fare?.FARE_PAX_WISE?.[paxType]
      : sial?.inbound[0]?.BAGGAGE_FARE[0]?.FARE_PAX_WISE?.[paxType];
    return [
      {
        code: "XT",
        amount: fare?.TAX + groupedFare?.TAX,
      },
      {
        code: "XP",
        amount:
          ~~fare?.SURCHARGE + ~~groupedFare?.SURCHARGE,
      },
      {
        code: "XP",
        amount:
          ~~fare?.FEES + ~~groupedFare?.FEES,
      },
    ]
  }

  combineFare(lowestFarePrice(data?.BAGGAGE_FARE[0]?.FARE_PAX_WISE), "currentFare");
  // const returnFare = sial?.inbound[0]?.BAGGAGE_FARE[0]?.FARE_PAX_WISE;
 
  return (
    <>
      <div
        className="search_engin_result_box flight_card_shadow bg-white"
        id={`sial${index}`}
      >
        <div
          className="shadow cursorpointer"
          onClick={() => {
            toggleDetail(index);
            setTimeout(() => {
              !isExpanded && scrollToElement(`sial${index}`);
            }, 200);
          }}
        >
          <div className="row align-items-center">
            <div className="col-2">
              <div className="px-2">
                <div className="text-center">
                  <img
                    src={AirSial}
                    alt="airsial"
                    style={{ width: "100px", height: "80px" }}
                  />
                </div>
                <p className="text-center">{data?.FLIGHT_NO}</p>
              </div>
            </div>

            <div className="col-7">
              <div className="d-flex justify-content-evenly align-items-center">
                <div className="d-flex align-items-center">
                  <div className="me-3">
                    <MdOutlineFlightTakeoff
                      size={30}
                      className="color_primary"
                    />
                  </div>
                  <div>
                    <h6>
                      {moment(data?.DEPARTURE_DATE, "DD,MM,YYYY").format(
                        "ddd DD-MMM-YY"
                      )}
                    </h6>
                    <h6 className="my-3">{data?.DEPARTURE_TIME}</h6>
                    <h6>
                      {data?.ORGN} -{" "}
                      {selectedFlight
                        ? getCity(locArrival)
                        : getCity(locDeparture)}
                    </h6>
                  </div>
                </div>
                <div>
                  <h6 className="text-center">
                    {formatDuration(data?.DURATION)}
                  </h6>
                  <div className="border_rounded mb-2" />
                  <h6 className="mx-3">Non-Stop</h6>
                </div>
                <div className="d-flex align-items-center">
                  <div className="me-3">
                    <MdOutlineFlightLand size={30} className="color_primary" />
                  </div>
                  <div>
                    <h6>
                      {moment(data?.DEPARTURE_DATE, "DD,MM,YYYY").format(
                        "ddd DD-MMM-YY"
                      )}
                    </h6>
                    <h6 className="my-3">{data?.ARRIVAL_TIME}</h6>
                    <h6>
                      {data?.DEST} -{" "}
                      {selectedFlight
                        ? getCity(locDeparture)
                        : getCity(locArrival)}
                    </h6>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-3">
              <div className="shadow pt-3 d-flex flex-column align-items-center justify-content-center result_box_body">
              <h6 className="text-danger">
                {/* {combineDiscount(data?.BAGGAGE_FARE[0]?.FARE_PAX_WISE,returnFare)}
                 */}
                 {combineDiscountFare(DiscountPrice(data?.BAGGAGE_FARE[0]?.FARE_PAX_WISE))}
              </h6>

                {selectedFare ? (
                  <HiEye className="color_primary" size={40} />
                ) : (
                  <>
                    <div className="d-flex align-items-end gap-3">
                      <h4>
                        {CurrencyCode()}{" "}
                        {combineFare(
                          lowestFarePrice(data?.BAGGAGE_FARE[0]?.FARE_PAX_WISE)
                        )}
                      </h4>
                      <img src={AirSial} alt="" width={40} height={40} />
                    </div>
                    <button className="button_style w-75 my-3" type="button">
                      <div>
                        <MenuOpenIcon className="me-1" />
                        Flight Detail
                      </div>
                    </button>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>

        {isExpanded ? (
          <div className="p-3">
            <div className="search_engin_result_box py-3 bg-white h-auto">
              <div
                className={`d-flex ${
                  selectedFare
                    ? "justify-content-start"
                    : "justify-content-between"
                } align-items-start px-5`}
              >
                {/* headings */}
                <div className={`${selectedFare ? "me-5 pe-5" : ""}`}>
                  <div className="mb-4 pb-3"></div>
                  <div className="flight-detail-headings">
                    <BsHandbag size={25} className="color_primary" />
                    <span>Hand Carry Bags</span>
                  </div>
                  <div className="flight-detail-headings">
                    <BsBagCheck size={25} className="color_primary" />
                    <span>Checked Bags</span>
                  </div>
                  <div className="flight-detail-headings">
                    <MdOutlineRestaurant size={25} className="color_primary" />
                    <span>Meals</span>
                  </div>
                  <div className="flight-detail-headings">
                    <BsPerson size={25} className="color_primary" />
                    <span>Seat Selection</span>
                  </div>
                  <div className="flight-detail-headings">
                    <MdSwapHoriz size={25} className="color_primary" />
                    <span>Refund & Exchanges</span>
                  </div>
                </div>

                {data?.BAGGAGE_FARE?.filter((item) =>
                  selectedFare ? item.sub_class_desc === selectedFare : item
                )?.map((fare, index) => {
                  let returnFare = selectedFlight
                    ? selectedFlight?.fare
                    : sial?.inbound[0]?.BAGGAGE_FARE[0];

                  return (
                    <div key={index}>
                      <div className="fs-4 theme-text-color mb-3 fw-bold">
                        {fare?.sub_class_desc}
                      </div>
                      <div className="flight-detail-headings">
                        <MdCheck size={25} color="green" />
                        <span>Upto 7 kg</span>
                      </div>
                      <div className="flight-detail-headings">
                        <>
                          <MdCheck size={25} color="green" />
                          <span>
                            {fare?.weight} kg ({fare?.piece} Pcs)
                          </span>
                        </>
                      </div>
                      <div className="flight-detail-headings">
                        <MdCheck size={25} color="green" />
                        <span>Allowed</span>
                      </div>
                      <div className="flight-detail-headings">
                        <CgMenuGridR size={25} color="#38025c" />
                        <span>System Allocated</span>
                      </div>
                      {/* refund and exchange dialog  */}
                      <div className="flight-detail-headings">
                        <LightTooltip
                          title={
                            <div className="p-3">
                              <h4>Refund & Exchange</h4>
                              <h6>
                                Penalty will be charged per passenger segment.
                              </h6>
                              <p className="text-danger font-italic">
                                for further clarification contact with Airline
                                Or Call our support team.
                              </p>

                              <div className="d-flex justify-content-between align-item-start w-fit mt-4">
                                <div className="me-5">
                                  <h4>Exchange</h4>
                                  <h6>
                                    Before 48 Hours:{" "}
                                    {`${CurrencyCode()} ${CurrencyExchange(
                                      1500
                                    )}`}
                                  </h6>
                                  <h6>
                                    Within 48 Hours:{" "}
                                    {`${CurrencyCode()} ${CurrencyExchange(
                                      2500
                                    )}`}
                                  </h6>
                                  <h6>
                                    Within 24 Hours:{" "}
                                    {`${CurrencyCode()} ${CurrencyExchange(
                                      3500
                                    )}`}
                                  </h6>
                                  <h6>
                                    Within 03 Hours:{" "}
                                    {`${CurrencyCode()} ${CurrencyExchange(
                                      4500
                                    )}`}
                                  </h6>
                                </div>
                                <div>
                                  <h4>Refund</h4>
                                  <h6>
                                    Before 48 Hours:{" "}
                                    {`${CurrencyCode()} ${CurrencyExchange(
                                      1500
                                    )}`}
                                  </h6>
                                  <h6>
                                    Within 48 Hours:{" "}
                                    {`${CurrencyCode()} ${CurrencyExchange(
                                      2500
                                    )}`}
                                  </h6>
                                  <h6>
                                    Within 24 Hours:{" "}
                                    {`${CurrencyCode()} ${CurrencyExchange(
                                      3500
                                    )}`}
                                  </h6>
                                  <h6>
                                    Within 03 Hours:{" "}
                                    {`${CurrencyCode()} ${CurrencyExchange(
                                      4500
                                    )}`}
                                  </h6>
                                </div>
                              </div>
                            </div>
                          }
                          placement="top-start"
                        >
                          <div style={{ cursor: "pointer" }}>
                            <MdRemoveRedEye size={25} color="#262829" />
                            <span>View</span>
                          </div>
                        </LightTooltip>
                      </div>
                      {/* ///////////// fare button and tooltip */}
                      {!selectedFare ? (
                        <div className="pt-3 px-2">
                          <LightTooltip
                            title={
                              <div className="p-3">
                                <div className="d-flex justify-content-between align-items-end">
                                  <div className="d-flex me-4">
                                    <h5 style={{ minWidth: "3rem" }}>ADT</h5>
                                    <h5>* {searchValues?.adultsCount}</h5>
                                  </div>
                                  <h6 style={{ minWidth: "7rem" }}>
                                    {CurrencyCode()}{" "}
                                    {singlePaxFare(
                                      fare?.FARE_PAX_WISE?.ADULT,
                                      returnFare?.FARE_PAX_WISE?.ADULT,
                                      searchValues?.adultsCount
                                    )}
                                  </h6>
                                </div>
                                <div className="d-flex justify-content-between align-items-end my-2">
                                  <div className="d-flex">
                                    <h5 style={{ minWidth: "3rem" }}>CHD</h5>
                                    <h5>* {searchValues?.childCount}</h5>
                                  </div>
                                  <h6 style={{ minWidth: "7rem" }}>
                                    {CurrencyCode()}{" "}
                                    {singlePaxFare(
                                      fare?.FARE_PAX_WISE?.CHILD,
                                      returnFare?.FARE_PAX_WISE?.CHILD,
                                      searchValues?.childCount
                                    )}
                                  </h6>
                                </div>
                                <div className="d-flex justify-content-between align-items-end">
                                  <div className="d-flex">
                                    <h5 style={{ minWidth: "3rem" }}>INF</h5>
                                    <h5>* {searchValues?.infantNo}</h5>
                                  </div>
                                  <h6 style={{ minWidth: "7rem" }}>
                                    {CurrencyCode()}{" "}
                                    {singlePaxFare(
                                      fare?.FARE_PAX_WISE?.INFANT,
                                      returnFare?.FARE_PAX_WISE?.INFANT,
                                      searchValues?.infantNo
                                    )}
                                  </h6>
                                </div>
                              </div>
                            }
                            placement="top"
                          >
                            <button
                              type="button"
                              className="button_style px-4"
                              onClick={() => {
                                if (selectedFlight) {
                                  let pnrBody = {
                                    isReturnFlight: true,
                                    origin_journey_code:
                                      selectedFlight?.flight?.JOURNEY_CODE,
                                    origin_fare_type:
                                      selectedFlight?.fare?.sub_class_id,
                                    origin_class:
                                      selectedFlight?.flight?.CLASS_CODE,
                                    origin_flight_code:
                                      selectedFlight?.flight?.FLIGHT_NO,

                                    dest_journey_code: data?.JOURNEY_CODE,
                                    dest_fare_type: fare?.sub_class_id,
                                    dest_class: data?.CLASS_CODE,
                                    dest_flight_code: data?.FLIGHT_NO,

                                    origin: data?.DEST,
                                    destination: data?.ORGN,
                                    infant: searchValues?.infantNo,
                                    child: searchValues?.childCount,
                                    adults: searchValues?.adultsCount,
                                    agent_name: data?.agent_name,
                                  };
                                  dispatch(
                                    asyncPostSialPnr({
                                      body: pnrBody,
                                      navigate: navigate,
                                    })
                                  );
                                  dispatch(
                                    setReturnFlight({
                                      flight: data,
                                      fare: fare,
                                      price: combineFare(lowestFarePrice(fare?.FARE_PAX_WISE)),
                                      ttlagent_curr_fare: CurrencyExchange(combineFare(Number(lowestFarePrice(fare?.FARE_PAX_WISE)) || 0)),
                                      ttl_supplier_fare: supplierPrice(fare?.FARE_PAX_WISE),
                                      ttlsup_curr_fare: TwoDecimalPoint(CurrencyExchange(supplierPrice(fare?.FARE_PAX_WISE))),
                                      adultBaseFare: combineBaseFare(fare?.FARE_PAX_WISE,"ADULT").basic,
                                      adultTaxes: combineBaseFare(fare?.FARE_PAX_WISE, "ADULT").taxes,
                                      childBaseFare: combineBaseFare(fare?.FARE_PAX_WISE,"CHILD").basic * searchValues?.childCount,
                                      childTaxes: combineBaseFare(fare?.FARE_PAX_WISE,"CHILD").taxes * searchValues?.childCount,
                                      infantBaseFare: combineBaseFare(fare?.FARE_PAX_WISE,"INFANT").basic * searchValues?.infantNo,
                                      infantTaxes: combineBaseFare(fare?.FARE_PAX_WISE,"INFANT").taxes * searchValues?.infantNo,
                                      adlt_ttl_d_curr: CurrencyExchangeToPKR(~~singlePaxFare(fare?.FARE_PAX_WISE?.ADULT, returnFare?.FARE_PAX_WISE?.ADULT, 1)),
                                      chld_ttl_d_curr: CurrencyExchangeToPKR(~~singlePaxFare(fare?.FARE_PAX_WISE?.CHILD, returnFare?.FARE_PAX_WISE?.CHILD, 1)),
                                      inft_ttl_d_curr: CurrencyExchangeToPKR(~~singlePaxFare(fare?.FARE_PAX_WISE?.INFANT, returnFare?.FARE_PAX_WISE?.INFANT, 1)),
                                      ADLT_TAXES: getTaxes(fare?.FARE_PAX_WISE?.ADULT, "ADULT"),
                                      CHLD_TAXES: searchValues?.childCount === 0 ? [] : getTaxes(fare?.FARE_PAX_WISE?.CHILD, "CHILD"),
                                      INFT_TAXES: searchValues?.infantNo === 0 ? [] : getTaxes(fare?.FARE_PAX_WISE?.INFANT, "INFANT"),
                                      baggageName: fare?.sub_class_desc,
                                      flightNumber: `${data?.FLIGHT_NO}`,
                                      depDate: moment(
                                        data?.DEPARTURE_DATE,
                                        "DD,MM,YYYY"
                                      ).format("DD-MMM-YY"),
                                      depTime: data?.DEPARTURE_TIME,
                                      arrDate: moment(
                                        data?.ARRIVAL_UTC?.split(" ")[0],
                                        "DD,MM,YYYY"
                                      ).format("DD-MMM-YY"),
                                      arrTime: data?.ARRIVAL_TIME,
                                      flightDuration: formatDuration(
                                        data?.DURATION
                                      ),
                                      logo: "Sial",
                                      code: "PF",
                                      booking: {
                                          gds: "AIRSIAL", // should be gds code
                                          pnr: sialpnrData?.Data, // returned in pricing response
                                          agent_name: data?.agent_name,
                                          segments: [
                                            {
                                              departure_date: moment(
                                                data?.DEPARTURE_DATE,
                                                "DD,MM,YYYY"
                                              ).format("YYYY-MM-DD"),
                                              baggage: `${fare?.weight} KG (${fare?.piece} Pcs)`,
                                              departure_time: data?.DEPARTURE_TIME,
                                              arrival_date: moment(
                                                data?.ARRIVAL_UTC?.split(" ")[0],
                                                "DD,MM,YYYY"
                                              ).format("YYYY-MM-DD"),
                                              arrival_time: data?.ARRIVAL_TIME,
                                              origin_terminal: "",
                                              desti_terminal: "",
                                              flight_number:
                                                data?.FLIGHT_NO?.split("F")?.at(1),
                                              number_in_party: "",
                                              res_book_desig_code: "",
                                              status: "NN",
                                              des_location: searchValues?.locationDep,
                                              dest_code: data?.DEST,
                                              marketing_airline: "PF",
                                              orig_location: searchValues?.locationArrival,
                                              orig_code: data?.ORGN,
                                              marriage_grp: "",
                                              cabin_class:
                                                searchValues?.ticket_class,
                                              segment_type: getSectorsName(
                                                locDeparture,
                                                locArrival
                                              ), // dom | siti | soto | group
                                            },
                                          ],
                                          tp_ur_code: "",
                                          platting_carrier: "PF",
                                          tp_ssr_verssion: 0,
                                          tp_trace_id: "",
                                      },
                                    })
                                  );
                                  navigate("/check-out");
                                } else {
                                  dispatch(
                                    setselectedFlight({
                                      flight: data,
                                      fare: fare,
                                      price: combineFare(lowestFarePrice(fare?.FARE_PAX_WISE)),
                                      ttlagent_curr_fare: combineFare(Number(lowestFarePrice(fare?.FARE_PAX_WISE)) || 0),
                                      ttl_supplier_fare: supplierPrice(fare?.FARE_PAX_WISE),
                                      ttlsup_curr_fare: TwoDecimalPoint(CurrencyExchange(supplierPrice(fare?.FARE_PAX_WISE))),
                                      adultBaseFare: combineBaseFare(fare?.FARE_PAX_WISE,"ADULT").basic,
                                      adultTaxes: combineBaseFare(fare?.FARE_PAX_WISE, "ADULT").taxes,
                                      childBaseFare: combineBaseFare(fare?.FARE_PAX_WISE,"CHILD").basic * searchValues?.childCount,
                                      childTaxes: combineBaseFare(fare?.FARE_PAX_WISE,"CHILD").taxes * searchValues?.childCount,
                                      infantBaseFare: combineBaseFare(fare?.FARE_PAX_WISE,"INFANT").basic * searchValues?.infantNo,
                                      infantTaxes: combineBaseFare(fare?.FARE_PAX_WISE,"INFANT").taxes * searchValues?.infantNo,
                                      adlt_ttl_d_curr: CurrencyExchangeToPKR(~~singlePaxFare(fare?.FARE_PAX_WISE?.ADULT, returnFare?.FARE_PAX_WISE?.ADULT, 1)),
                                      chld_ttl_d_curr: CurrencyExchangeToPKR(~~singlePaxFare(fare?.FARE_PAX_WISE?.CHILD, returnFare?.FARE_PAX_WISE?.CHILD, 1)),
                                      inft_ttl_d_curr: CurrencyExchangeToPKR(~~singlePaxFare(fare?.FARE_PAX_WISE?.INFANT, returnFare?.FARE_PAX_WISE?.INFANT, 1)),
                                      ADLT_TAXES: getTaxes(fare?.FARE_PAX_WISE?.ADULT, "ADULT"),
                                      CHLD_TAXES: searchValues?.childCount === 0 ? [] : getTaxes(fare?.FARE_PAX_WISE?.CHILD, "CHILD"),
                                      INFT_TAXES: searchValues?.infantNo === 0 ? [] : getTaxes(fare?.FARE_PAX_WISE?.INFANT, "INFANT"),
                                      baggageName: fare?.sub_class_desc,
                                      flightNumber: `${data?.FLIGHT_NO}`,
                                      depDate: moment(
                                        data?.DEPARTURE_DATE,
                                        "DD,MM,YYYY"
                                      ).format("DD-MMM-YY"),
                                      depTime: data?.DEPARTURE_TIME,
                                      arrDate: moment(
                                        data?.ARRIVAL_UTC?.split(" ")[0],
                                        "DD,MM,YYYY"
                                      ).format("DD-MMM-YY"),
                                      arrTime: data?.ARRIVAL_TIME,
                                      flightDuration: formatDuration(
                                        data?.DURATION
                                      ),
                                      logo: "Sial",
                                      code: "PF",
                                      booking: {
                                          gds: "AIRSIAL", // should be gds code
                                          agent_name: data?.agent_name,
                                          segments: [
                                            {
                                              departure_date: moment(
                                                data?.DEPARTURE_DATE,
                                                "DD,MM,YYYY"
                                              ).format("YYYY-MM-DD"),
                                              baggage: `${fare?.weight} KG (${fare?.piece} Pcs)`,
                                              departure_time: data?.DEPARTURE_TIME,
                                              arrival_date: moment(
                                                data?.ARRIVAL_UTC?.split(" ")[0],
                                                "DD,MM,YYYY"
                                              ).format("YYYY-MM-DD"),
                                              arrival_time: data?.ARRIVAL_TIME,
                                              origin_terminal: "",
                                              desti_terminal: "",
                                              flight_number:
                                                data?.FLIGHT_NO?.split("F")?.at(1),
                                              number_in_party: "",
                                              res_book_desig_code: "",
                                              status: "NN",
                                              des_location:
                                                searchValues?.locationArrival,
                                              dest_code: data?.DEST,
                                              marketing_airline: "PF",
                                              orig_location:
                                                searchValues?.locationDep,
                                              orig_code: data?.ORGN,
                                              marriage_grp: "",
                                              cabin_class:
                                                searchValues?.ticket_class,
                                              segment_type: getSectorsName(
                                                locDeparture,
                                                locArrival
                                              ), // dom | siti | soto | group
                                            },
                                          ],
                                          tp_ur_code: "",
                                          platting_carrier: "PF",
                                          tp_ssr_verssion: 0,
                                          tp_trace_id: "",
                                      },
                                    })
                                  );
                                }
                                dispatch(setExpandFlightDetails(null));
                              }}
                            >
                              {CurrencyCode()}{" "}
                              {combineFare(
                                lowestFarePrice(fare?.FARE_PAX_WISE)
                              )}
                            </button>
                          </LightTooltip>
                        </div>
                      ) : null}
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        ) : null}
      </div>
    </>
  );
}

export default AirsialData;
