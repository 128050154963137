import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { URL2 } from '../../../redux/features/ApiConfig';


const UpdateModel = ({booking}) => {
  const [data, setData] = useState([]);
  
  const searchValues = JSON.parse(localStorage.getItem("flightSearchData"));
  const currency = JSON.parse(localStorage.getItem("currency"));

// const pnr=booking?.booking?.FlightBookingModels?.at(0).pnr
const gds=booking?.booking?.FlightBookingModels?.at(0).gds
const id=booking?.booking?.id
const agent=searchValues?.agent.agent_grp_id

// console.log("pnr",pnr,data);
  useEffect(() => {
    const fetchData = async () => {
        try {
            const response = await axios.post(`${URL2}/api/v1/b2b/pia/update/${id}`,{
              gds: gds,id: id, agent:1,searchValues:searchValues,currency:currency
            });
            setData(response?.data);
        } catch (error) {
            console.log(error);
        }
    };
    fetchData();
}, []);
console.log("ddd",data);
// otawix.solution@gmail.com
  return (
    <div className='d-flex h-100 align-items-center justify-content-center '>
      <div>
        <h1>{data?.message}</h1>
      </div>
    </div>
  )
}



export default UpdateModel