import React from "react";
import { BsPersonFill } from "react-icons/bs";
import { FieldArray } from "formik";
import ReactDatePicker from "react-datepicker";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import moment from "moment";
import CountryDropdown from "country-dropdown-with-flags-for-react";
import { dropMenuProp } from "../../../Utils/MenuProps";

const Passanger = ({ setFieldValue, values, ErrorMessage }) => {
  /// search values from local storage
  const localsearchValues = JSON.parse(
    localStorage.getItem("flightSearchData")
  );
  // Calculate the current date
  const currentDate = new Date(
    moment(localsearchValues?.departingOn, "DD,MM,YYYY").format("YYYY-MM-DD")
  );
  // Calculate the minimum date (12 years before the current date)
  // const minDate = new Date(currentDate);
  // minDate.setFullYear(currentDate.getFullYear() - 12);

  return (
    <div className="p-4 bg-white shadow-sm borderradius">
      <div className="d-flex border-bottom pb-2">
        <i>
          <BsPersonFill size={26} />
        </i>
        <h4 className="ms-2">Passanger data</h4>
      </div>
      <div className="mt-4">
        <FieldArray
          name="pax"
          render={(arrayHelpers) => (
            <div>
              {values?.pax?.map((pax, index) => (
                <div key={index} className="my-4 theme_border p-3 rounded-2">
                  <p className="fs-5 fw-bold color_primary">
                    {index + 1}. Passenger ({pax.pax_type})
                  </p>
                  <div className="row">
                    <div className="col-sm-2">
                      <FormControl variant="standard" fullWidth>
                        <InputLabel id={`pax[${index}].title`}>
                          Title
                        </InputLabel>
                        <Select
                          id={`pax[${index}].title`}
                          value={pax.title}
                          onChange={(e) => {
                            let value = e.target.value;
                            setFieldValue(`pax[${index}].title`, value);
                          }}
                          MenuProps={dropMenuProp}
                        >
                          <MenuItem value="MR">MR</MenuItem>
                          <MenuItem value="MS">MS</MenuItem>
                          <MenuItem value="MISS">MRS</MenuItem>
                          <MenuItem value="MISS">MISS</MenuItem>
                          <MenuItem value="MISS">INF</MenuItem>
                        </Select>
                      </FormControl>
                      <ErrorMessage
                        name={`pax[${index}].title`}
                        component="div"
                        className="text-danger mt-1"
                      />
                    </div>
                    <div className="col-sm-2">
                      <TextField
                        id={`pax[${index}].f_name`}
                        label="First Name"
                        variant="standard"
                        autoComplete="off"
                        value={pax.f_name}
                        onChange={(e) => {
                          let value = e.target.value;
                          setFieldValue(
                            `pax[${index}].f_name`,
                            value.toLocaleUpperCase()
                          );
                        }}
                      />
                      <ErrorMessage
                        name={`pax[${index}].f_name`}
                        component="div"
                        className="text-danger mt-1"
                      />
                    </div>
                    <div className="col-sm-2">
                      <TextField
                        id={`pax[${index}].l_name`}
                        label="Last Name"
                        autoComplete="off"
                        variant="standard"
                        value={pax.l_name}
                        onChange={(e) => {
                          let value = e.target.value;
                          setFieldValue(
                            `pax[${index}].l_name`,
                            value.toLocaleUpperCase()
                          );
                        }}
                      />
                      <ErrorMessage
                        name={`pax[${index}].l_name`}
                        component="div"
                        className="text-danger mt-1"
                      />
                    </div>
                    <div className="col-sm-3">
                      <CountryDropdown
                        id={`pax[${index}].nationality`}
                        className="country_dropdown absolute w-100"
                        preferredCountries={["pk"]}
                        value={pax.nationality}
                        handleChange={(e) => {
                          const country = e.target.value;
                          setFieldValue(`pax[${index}].nationality`, country);
                        }}
                      />
                    </div>
                    <div className="col-sm-3">
                      <ReactDatePicker
                        className="date_picker_passanger"
                        placeholderText="Birth Date"
                        autoComplete="off"
                        name={`pax[${index}].dob`}
                        selected={pax.dob}
                        onChange={(date) =>
                          setFieldValue(`pax[${index}].dob`, date)
                        }
                        minDate={
                          pax.pax_type === "ADLT"
                            ? null
                            : pax.pax_type === "CHLD"
                            ? moment().subtract(12, "years").toDate()
                            : pax.pax_type === "INFT"
                            ? moment().subtract(2, "years").toDate()
                            : null
                        }
                        maxDate={
                          pax.pax_type === "ADLT"
                            ? moment().subtract(12, "years").toDate()
                            : pax.pax_type === "CHLD"
                            ? moment().subtract(2, "years").toDate()
                            : pax.pax_type === "INFT"
                            ? moment().subtract(0, "years").toDate()
                            : null // Max date for non-adults is not restricted
                        }
                        showYearDropdown
                        dropdownMode="select"
                      />
                    </div>
                  </div>

                  <div className="mt-4 mb-4">
                    <h6>Document Type</h6>
                    <div className="row">
                      <div className="col-sm-3">
                        <FormControl variant="standard" fullWidth>
                          <InputLabel id={`pax[${index}].doc_type`}>
                            Type
                          </InputLabel>
                          <Select
                            id={`pax.[${index}]doc_type`}
                            value={pax.doc_type}
                            name={`pax[${index}].doc_type`}
                            onChange={(e) => {
                              let value = e.target.value;
                              setFieldValue(`pax[${index}].doc_type`, value);
                            }}
                            MenuProps={dropMenuProp}
                          >
                            <MenuItem value="PASSPORT">PASSPORT</MenuItem>
                            <MenuItem value="CNIC">CNIC</MenuItem>
                          </Select>
                        </FormControl>
                        <ErrorMessage
                          name={`pax[${index}].doc_type`}
                          component={"div"}
                          className="text-danger mt-1"
                        />
                      </div>
                      <div className="col-sm-3">
                        <TextField
                          fullWidth
                          type="text"
                          inputProps={{ maxLength: 15 }}
                          id={`pax[${index}].doc_no`}
                          autoComplete="off"
                          label={
                            pax.doc_type !== ""
                              ? `${pax.doc_type} NO`
                              : "Document No"
                          }
                          variant="standard"
                          name={`pax[${index}].doc_no`}
                          value={pax.doc_no}
                          placeholder={
                            pax.doc_type === "CNIC"
                              ? "e.g 00000-0000000-0"
                              : pax.doc_type === "PASSPORT"
                              ? "e.g DY1234567"
                              : ""
                          }
                          onChange={(e) => {
                            const value = e.target.value;
                            if (pax.doc_type === "CNIC") {
                              if (
                                value.length === 6 &&
                                value.charAt(5) !== "-"
                              ) {
                                setFieldValue(
                                  `pax[${index}].doc_no`,
                                  `${value.slice(0, 5)}-${value.slice(5)}`
                                );
                              } else if (
                                value.length === 14 &&
                                value.charAt(13) !== "-"
                              ) {
                                setFieldValue(
                                  `pax[${index}].doc_no`,
                                  `${value.slice(0, 13)}-${value.slice(13)}`
                                );
                              } else {
                                setFieldValue(`pax[${index}].doc_no`, value?.toUpperCase());
                              }
                            } else {
                              setFieldValue(`pax[${index}].doc_no`, value?.toUpperCase());
                            }
                          }}
                        />
                        <ErrorMessage
                          name={`pax[${index}].doc_no`}
                          component={"div"}
                          className="text-danger mt-1"
                        />
                      </div>
                      <div className="col-sm-3">
                        <CountryDropdown
                          id={`pax[${index}].issue_country`}
                          className="country_dropdown absolute w-100"
                          preferredCountries={["pk"]}
                          value={pax.issue_country}
                          handleChange={(e) => {
                            const iss_country = e.target.value;
                            setFieldValue(
                              `pax[${index}].issue_country`,
                              iss_country
                            );
                          }}
                        />
                      </div>
                      <div className="col-sm-3 ">
                      
                        <ReactDatePicker
                          className="date_picker_passanger position-relative "
                          placeholderText="Expiry Date"
                          autoComplete="off"
                          name={`pax[${index}].expiry_date`}
                          selected={pax.expiry_date}
                          onChange={(date) =>
                            setFieldValue(`pax[${index}].expiry_date`, date)
                          }
                          minDate={new Date()}
                          showYearDropdown
                          dropdownMode="select"
                        />
                        <p className="expiry-text fs-7 mb-2 text-danger">EXP: VALID 6 MONTHS</p>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}
        />
      </div>
    </div>
  );
};

export default Passanger;
