import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  asyncGetFlightGroupData,
  asyncgetSingleFlightGroup,
  asyncdeleteFlightGroup,
  updateFlightGroupStatus,
  asyncGetSoldFlightGroupData,
} from "../../../redux/features/promotion/FlightGroupsSlice";
import { useLocation, useNavigate } from "react-router-dom";
import { asyncGetAccountData } from "../../../redux/features/Finance/Account/ChartOfAccountSlice";
import { TextField } from "@mui/material";

const FlightGroupDetails = ({ fligtData }) => {
  const [toggle, setToogle] = useState(false);
  const [activeSold, setActiveSold] = useState("active");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [pnrFilter, setPnrFilter] = useState("");
  const { upDateflightGroup, flightGroup, SoldFlightGroup } = useSelector(
    (state) => state.flightGroup
  );
  const { accountData } = useSelector((state) => state.chartAccount);
  useEffect(() => {
    dispatch(asyncGetFlightGroupData());
    dispatch(asyncGetSoldFlightGroupData());
    dispatch(asyncGetAccountData());
  }, [upDateflightGroup]);
  const fltFilter = location?.state?.fltPromotion
    ? location?.state?.fltPromotion
    : fligtData;
  ////////////////////////Filter//////////////////////////
  const flights = activeSold === "active" ? flightGroup : SoldFlightGroup;

  const filteredFlights = flights?.filter((flight) => {
    const isTypeMatch =
      !fligtData || flight?.FlightGroupTypeModel?.type_name === fltFilter;
    const isPnrMatch = !pnrFilter || flight?.pnr?.includes(pnrFilter);
    const isSupplierMatch =
      !pnrFilter || flight?.acc_account?.acc_title === pnrFilter;
    const isCurrencyMatch =
      !pnrFilter || flight?.flight_group_pax_fares[0]?.currency === pnrFilter;
    const result =
      isTypeMatch && (isPnrMatch || isSupplierMatch || isCurrencyMatch);
    return result;
  });

  return (
    <>
      <div className="mt-5 d-flex justify-content-around align-items-center">
        <h4>Flight Group Promotions ({fltFilter})</h4>
        <div className="nav nav-tabs gap-4">
          <div className="col-4">
            <TextField
              variant="standard"
              label="PNR/Supplier/Currency"
              placeholder="Enter Pnr"
              autoComplete="off"
              className="w-100 "
              name="Pnr"
              value={pnrFilter.toUpperCase()}
              onChange={(e) => {
                let value = e.target.value.toUpperCase();

                setPnrFilter(value);
              }}
            />
          </div>

          <button
            className={
              activeSold === "active" ? `button_acitve` : `button_style`
            }
            onClick={(e) => setActiveSold("active")}
          >
            Active
          </button>
          <button
            className={activeSold === "sold" ? `button_acitve` : `button_style`}
            onClick={(e) => setActiveSold("sold")}
          >
            Sold
          </button>
          <button
            className="button_style"
            onClick={() => navigate("/flight-groups")}
          >
            Back
          </button>
        </div>
      </div>
      <div className="rounded-2 shadow-sm mt-3 ">
        <div className="tab-content d-flex flex-column gap-8">
          <div id="active" className=" ">
            <div className="col-12 d-flex flex-column justify-content-center rounded-2 shadow-sm ">
              <div className=" bg_secondary table-bordered ">
                <div className="fs-5 ">
                  <tr className=" text-white align-middle ">
                    <th style={{ width: "10%", textAlign: "center" }}>
                      Group Name - PNR| Type
                    </th>
                    <th style={{ width: "8%", textAlign: "center" }}>
                      Total | Remaining | Issued
                    </th>
                    <th style={{ width: "8%", textAlign: "center" }}>
                      Supplier
                    </th>
                    <th style={{ width: "4%", textAlign: "center" }}>
                      Pax Type
                    </th>
                    <th style={{ width: "11%", textAlign: "center" }}>
                      Rec | Pay
                    </th>
                    <th style={{ width: "6%", textAlign: "center" }}>
                      Flight | Number
                    </th>
                    <th style={{ width: "10%", textAlign: "center" }}>
                      Orig| Dest
                    </th>
                    <th style={{ width: "8%", textAlign: "center" }}>
                      DEP Date
                    </th>
                    <th style={{ width: "6%", textAlign: "center" }}>
                      DEP Time
                    </th>
                    <th style={{ width: "8%", textAlign: "center" }}>
                      ARR Date
                    </th>
                    <th style={{ width: "6%", textAlign: "center" }}>
                      ARR Time
                    </th>
                    <th style={{ width: "6%", textAlign: "center" }}>Bag</th>
                    <th style={{ width: "8%", textAlign: "center" }}>Action</th>
                  </tr>
                </div>
              </div>
            </div>
          </div>

          {filteredFlights
            ?.sort((a, b) => {
              const dateA = new Date(
                a?.flight_group_flights[0]?.flt_dep_date
                  ?.split("-")
                  .reverse()
                  .join("-")
              );
              const dateB = new Date(
                b?.flight_group_flights[0]?.flt_dep_date
                  ?.split("-")
                  .reverse()
                  .join("-")
              );

              return dateA - dateB;
            })
            ?.map((item, index) => (
              <div className="mt-4 d-flex bg-white">
                <div
                  className="w-30 border d-flex fs-5"
                  style={{ width: "26%" }}
                >
                  <div
                    style={{ width: "37%", fontSize: "22px" }}
                    className="border p-2 text-wrap fs-4"
                  >
                    <small> {item.group_name?.split(" ")?.at(0)}</small>
                    <br />
                    <small> {item.group_name?.split(" ")?.at(1)}</small>
                    <br />
                    <small>
                      {item?.group_name?.split(" ")?.at(2)?.split("-")?.at(0)}
                    </small>
                    <br />
                    <small>
                      {item?.group_name
                        ?.split(" ")
                        ?.at(2)
                        ?.split("-")
                        ?.at(1)
                        ?.split("/")
                        ?.at(0)}
                    </small>
                    <br />
                    <small>
                      {item?.group_name
                        ?.split(" ")
                        ?.at(2)
                        ?.split("-")
                        ?.at(1)
                        ?.split("/")
                        ?.at(1)}
                    </small>
                    {/* {item?.group_name} */}
                  </div>
                  <div
                    style={{
                      width: "33%",
                      justifyContent: "center",
                      alignItems: "center",
                      display: "flex",
                      flexDirection: "column",
                    }}
                    className="border"
                  >
                    <span>
                      {" "}
                      {item?.ttl_seat} | {item?.ttl_seat - item?.booked_seat} |{" "}
                      {item?.issue_seat ? item?.issue_seat : 0}
                    </span>
                  </div>
                  <div
                    style={{
                      width: "30%",
                      justifyContent: "center",
                      alignItems: "center",
                      display: "flex",
                      flexDirection: "column",
                    }}
                    className="p-2"
                  >
                    {
                      item?.acc_account?.acc_title
                      // accountData?.find(
                      //   acc => acc?.acc_id === item?.supplier_id
                      // )?.acc_title
                    }
                  </div>
                </div>
                {/* center */}
                <div
                  className="d-flex align-items-center border  "
                  style={{ width: "66%" }}
                >
                  {/* pax */}
                  <div className="d-flex flex-column" style={{ width: "24%" }}>
                    {item?.flight_group_pax_fares?.map(
                      (pax) => (
                        (
                          <div className="border fs-5 d-flex">
                            <div
                              className="border-end  p-2"
                              style={{ width: "30%" }}
                            >
                              {pax?.pax_type}
                            </div>
                            <div
                              style={{ width: "70%" }}
                              className="  p-2 fs-4 "
                            >
                              {pax?.currency !== "PKR" ? (
                                <small className="fs-5">
                                  {pax?.currency +
                                    " " +
                                    pax?.selected_currency_received}
                                  /{pax?.selected_currency_pay}
                                </small>
                              ) : null}
                              <br />
                              <small>
                                {"PKR" + " " + pax?.default_currency_received}/{" "}
                                {pax?.default_currency_pay}
                              </small>
                            </div>
                          </div>
                        )
                      )
                    )}
                  </div>
                  {/* flight deta */}

                  <div style={{ width: "76%" }} className="d-flex flex-column">
                    {item?.flight_group_flights?.map((flight) => (
                      <div className="d-flex  justify-content-center fs-5">
                        <div className=" border p-2 " style={{ width: "12%" }}>
                          {flight?.flight} | {flight?.flt_no}
                        </div>
                        <div style={{ width: "20%" }} className="border p-2">
                          {flight?.origin?.split("-")[0]} |{" "}
                          {flight?.destination?.split("-")[0]}
                        </div>
                        <div style={{ width: "16%" }} className=" border  p-2">
                          {flight?.flt_dep_date}
                        </div>
                        <div style={{ width: "12%" }} className=" border p-2">
                          {flight?.dep_time}
                        </div>
                        <div style={{ width: "16%" }} className=" border p-2 ">
                          {flight?.flt_arr_date}
                        </div>
                        <div style={{ width: "12%" }} className=" border p-2">
                          {flight?.arr_time}
                        </div>
                        <div style={{ width: "12%" }} className=" border p-2 ">
                          {flight?.baggage}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>

                <div
                  className="w-10 border"
                  style={{
                    width: "8%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: 8,
                  }}
                >
                  <button
                    className="btn-success rounded edit-delete-icon-button me-1"
                    onClick={() => {

                      navigate("/flight-groups", {
                        state: {
                          fltPromotion: fligtData,
                          id: item?.flt_group_id,
                        },
                      });
                    }}
                  >
                    {" "}
                    <i className="fa fa-thin fa-pencil"></i>
                  </button>
                  <button
                    className="btn-danger rounded edit-delete-icon-button me-1"
                    onClick={() => {
                      const id = item?.flt_group_id;

                      dispatch(asyncdeleteFlightGroup(id));
                    }}
                  >
                    {" "}
                    <i className="fa fa-thin fa-trash"></i>
                  </button>
                  <div>
                    <div className="checkbox-rect">
                      <input
                        type="checkbox"
                        id={"with_sar_" + index}
                        checked={item?.status === 1 ? true : false}
                        onChange={(e) => {
                          const id = item?.flt_group_id;
                          let data = {
                            status: e.target.checked ? 1 : 0,
                          };

                          dispatch(updateFlightGroupStatus({ id, data }));

                          // if (singleFlightData) {
                          //   console.log("e.target.checked", e.target.checked ? 1: 0)
                          //   console.log("singleFlightData", singleFlightData)
                          //   let id = singleFlightData?.flt_group_id
                          //   let data = {
                          //     flt_gtype_id:
                          //       singleFlightData?.flt_gtype_id || "",
                          //     ttl_seat:
                          //       Number(singleFlightData?.ttl_seat) || "",
                          //     selectSupplier:
                          //       singleFlightData?.supplier_id || "",
                          //     pnr: singleFlightData?.pnr || "",
                          //     status:e.target.checked ? 1 :0,
                          //     //addpassanger states
                          //     flight_group_pax_fare:
                          //       singleFlightData?.flight_group_pax_fares?.map(
                          //         value => {
                          //           return {
                          //             group_pax_fare_id:
                          //               value?.group_pax_fare_id,
                          //             pax_type: value?.pax_type,
                          //             pay_price: value?.pay_price,
                          //             rec_price: value?.rec_price,
                          //           }
                          //         }
                          //       ),

                          //     flight_group_flight:
                          //       singleFlightData?.flight_group_flights?.map(
                          //         value => {
                          //           return {
                          //             group_flight_id: value?.group_flight_id,
                          //             flight: value.flight,
                          //             flt_no: value.flt_no,
                          //             origin: value.origin,
                          //             destination: value.destination,
                          //             flt_dep_date: value.flt_dep_date,
                          //             dep_time: value.dep_time,

                          //             flt_arr_date: value.flt_arr_date,
                          //             arr_time: value.arr_time,
                          //             baggage: value.baggage,
                          //           }
                          //         }
                          //       ),
                          //   }

                          //   setTimeout(() => {
                          //     dispatch(asyncUpdateFlightGroup({ id, data }))
                          //   }, 1000);

                          // }
                        }}
                      />
                      <label
                        htmlFor={"with_sar_" + index}
                        style={{ marginLeft: 60 }}
                        className="color_primary pt-1 fs-5 text-white  "
                      >
                        Status
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>
    </>
  );
};

export default FlightGroupDetails;
