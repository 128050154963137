import React, { useEffect, useState } from "react";
import {
  FormControl,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import CountryDropdown from "country-dropdown-with-flags-for-react";
import { ErrorMessage, Form, Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import {
  asyncGetNewAgencyReg,
  asyncPostNewAgencyReg,
  asyncRegNewAgencyMultiple,
  asyncUpdateNewAgency,
  asyncUpdatePendingAgency,
} from "../../../redux/features/setupFeature/NewRegistration/NewRegistrationSlice";
import BtnLoader from "../../AppForm/BtnLoader";
import * as yup from "yup";
import {
  asyncGetAgentData,
  asyncGetAgentGroup,
} from "../../../redux/features/setupRestPages/agentsSlice";
import { asyncGetAllUserData } from "../../../redux/features/agency/usersSlice";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { asyncGetNewSupplier } from "../../../redux/features/supplier/AddNewSupplier";
import { dropMenuProp, officeType } from "./helper";
import UploadExcel from "./UploadExcel";

const AgencyRegistration = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [validEmail, setValidEmail] = useState(false);
  const [agentType, setAgentType] = useState();
  const [showPassword, setShowPassword] = useState(false);

  const { newAgencyRegData, loading, message } = useSelector(
    (state) => state.newAgency
  );
  const { newSupplier } = useSelector((state) => state.supplier);
  const { agentGroup, editAgentsData } = useSelector(
    (state) => state.agentsCategories
  );

  const { userPermission } = useSelector((state) => state?.roleAndPermissions);
  const user = JSON.parse(localStorage.getItem("loginUser"));

  console.log("user", user)

  const { allUsersData } = useSelector((state) => state.usersSlice);

  const agent_ids_array = newAgencyRegData?.map((arr) => {
    return arr.agent_id;
  });
  const maxAgentId = Math.max(...agent_ids_array);

  const authByUsers = allUsersData?.filter((it) =>
    editAgentsData
      ? editAgentsData?.manager === it.user_id || it.user_id === user?.user_id
      : it.agent_id === 1
  );

  // (it.role_id === 1 || it.role_id === 2 || it.role_id === 5 || it.role_id === 9) &&

  const accManagerUser = allUsersData?.filter((it) => it.agent_id === 1);

  const [modalShow, setModalShow] = useState(false);
  const [errorMessages, setErrorMessages] = useState([]);
  const [agentNameError, setAgentNameError] = useState("");

  /*************use effect *************** */
  useEffect(() => {
    dispatch(asyncGetNewAgencyReg());
    dispatch(asyncGetAgentGroup());
    dispatch(asyncGetAgentData());
    dispatch(asyncGetAllUserData());
    dispatch(asyncGetNewSupplier());
  }, [dispatch]);

  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

  const isSupplierAlreadyExist = (values) => {
    return newSupplier?.some(
      (supp) =>
        supp?.supp_name?.toUpperCase() === values?.agent_name?.toUpperCase() &&
        values.add_as_supplier
    );
  };

  // if the login user has permissions to edit the agent
  const editPermission = ![1, 6, 7].includes(user.role_id)
    ? userPermission?.find(
      (it) =>
        it?.user_id === user?.user_id &&
        it?.page_level.permission_url === "/update-new-agency"
    )
    : true;

  /***********************validation schema ****************** */
  const validationSchem = yup.object().shape({
    bg_id: yup.number().required("Agent Group Required"),
    auth_by: yup.number().required("Auth By Required"),
    agent_type: yup.string().required("Agent Type Required"),
    manager: yup.number().required("Account Managet Required"),
    agent_name: yup.string().required("Agent Name Required"),
    // add_as_supplier: yup
    //   .boolean()
    //   .test("uniquesuppliername", "Supplier already exist.", function () {
    //     const values = this.parent;
    //     return !isSupplierAlreadyExist(values);
    //   }),
    f_name: yup.string().required("First Name Required"),
    l_name: yup.string().required("Last Name Required"),
    user_email: yup
      .string()
      .email("Must be Valid Email")
      .required("Email Required"),
    city: yup.string().required("City Required"),
    address: yup.string().required("Address Required"),
    agent_email: yup
      .string()
      .email("Must be Valid Email")
      .required("Agent Email Required"),
    phone: yup
      .string()
      .matches(phoneRegExp, "Phone number is not valid")
      .required("Phone Number Required"),
    mobile_no: yup
      .string()
      .matches(phoneRegExp, "Mobile number is not valid")
      .required("Mobile Number Required"),
    password: !editAgentsData
      ? yup
        .string()
        .min(8, "Password must be atleast 8 characters long")
        .required("Password Required")
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])/,
          "Must Contain One Uppercase, One Lowercase, One Number and One Special Case Character"
        )
      : yup
        .string()
        .min(8, "Password must be atleast 8 characters long")
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])/,
          "Must Contain One Uppercase, One Lowercase, One Number and One Special Case Character"
        ),
    password_confirmation: !editAgentsData
      ? yup
        .string()
        .required("Repeat Password Required")
        .oneOf([yup.ref("password"), null], "Passwords must match")
      : yup.string().oneOf([yup.ref("password"), null], "Passwords must match"),
  });
  /************************Initial values ************** */
  const initialValues = {
    bg_id: editAgentsData?.agent_grp_id ? editAgentsData?.agent_grp_id : 1,
    auth_by: editAgentsData?.auth_by
      ? editAgentsData?.auth_by
      : authByUsers.length || agentType === "Corporate"
        ? user?.user_id
        : 1,
    agent_type: editAgentsData?.agent_type
      ? editAgentsData?.agent_type
      : agentType === "Corporate"
        ? officeType[1]
        : agentType === "Travel Agent"
          ? officeType[0]
          : agentType === "Travel Consultant"
            ? officeType[2]
            : officeType[0],
    manager: editAgentsData?.manager
      ? editAgentsData?.manager
      : accManagerUser.length
        ? user?.user_id
        : 1,
    logo: editAgentsData?.logo_view || 0,
    statement:
      editAgentsData?.statement || agentType === "Corporate"
        ? "Only Total"
        : "Full Statement",
    hour_72: editAgentsData?.hours_72 || agentType === "Corporate" ? 1 : 0,
    ticket_authority:
      editAgentsData?.tkt_auth || agentType === "Corporate" ? 1 : 0,
    hotel_authority: editAgentsData?.hotel_authority || 0,
    agent_name: editAgentsData?.agent_name || "",
    agent_book_permission: !editAgentsData?.agent_book_permission ? 0 : 1,
    add_as_supplier: false,
    as_supplier: false,
    agent_id: editAgentsData
      ? "TA - " + editAgentsData?.agent_id
      : "TA - " + (maxAgentId + 1),
    f_name: editAgentsData?.agents_users[0]?.f_name || "",
    l_name: editAgentsData?.agents_users[0]?.l_name || "",
    user_email: editAgentsData?.agents_users[0]?.email || "",
    reg_number: editAgentsData?.reg_no || "",
    tax_number: editAgentsData?.tax_no || "",
    country: editAgentsData
      ? editAgentsData?.address.split("]")[2]
      : "Pakistan",
    city: editAgentsData?.address.split("]")[1] || "",
    address: editAgentsData?.address.split("]")[0] || "",
    credit_limit: editAgentsData
      ? editAgentsData?.credit_limit
      : agentType === "Corporate"
        ? 500000
        : 1,
    url: editAgentsData?.url || "",
    agent_email: editAgentsData?.email || "",
    phone: editAgentsData?.phone || "",
    mobile_no: editAgentsData?.mobile_no || "",
    password:
      editAgentsData?.password || agentType === "Corporate" ? "Demo@1234" : "",
    password_confirmation:
      editAgentsData?.password || agentType === "Corporate" ? "Demo@1234" : "",
    agent_grp_show: editAgentsData?.agent_grp_show === null ? 0 : 1,
    attachement: editAgentsData?.attachment || "",
    supplierName: "",
  };

  const initialValues2 = { excelFile: null };
  // const validationSchema2 = yup.object().shape({
  //   excelFile:yup.mixed()
  //     .required('A file is required')
  //     .test(
  //       'fileFormat',
  //       'Only .xlsx and .xls files are allowed',
  //       value => value && ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/vnd.ms-excel'].includes(value.type)
  //     )
  // });
  /****************************************** */
  return (
    <div>
      <div className="mx-5 rounded-2 shadow-sm mt-5 bg-white mb-4">
        {!editAgentsData && (
          <>
            <p className="h4 otawix-heading box_title">
              New Agency Registration
            </p>
            <div>
              <UploadExcel
                initialValues2={initialValues2}
                asyncRegNewAgencyMultiple={asyncRegNewAgencyMultiple}
              />{" "}
              {/* <Formi
        initialValues={initialValues2}
        validationSchema={validationSchema2}
        onSubmit={(values, actions) => {
          const formData = new FormData();
          formData.append('ExcelFile', values.excelFile);

          dispatch(asyncRegNewAgencyMultiple(formData))
            .then((response) => {
              if (response.errors && response.errors.length > 0) {
                setErrorMessages(response.errors);
                setModalShow(true);
              } else {
                navigate('/agents');
              }
            })
            .catch((error) => {
              setErrorMessages(["Failed to upload file. Please try again."]);
              setModalShow(true);
            });
        }}
      >
        {({ setFieldValue, isSubmitting }) => (
          <Form>
            <div className="ms-4 col-sm-2 pt-5">
              <p style={{ lineHeight: 0 }}>Import From File</p>
              <input
                type="file"
                accept=".xlsx, .xls"
                name="excelFile"
                onChange={(e) => {
                  setFieldValue("excelFile", e.target.files[0]);
                }}
              />
              <ErrorMessage
                component="div"
                className="text-danger mt-1"
                name="excelFile"
              />
            </div>
            <button
              type="submit"
              variant="contained"
              color="primary"
              // disabled={isSubmitting}
              className=" ms-4 btn setup-btn mt-5 mx-auto"
            >
              Continue
            </button>
          </Form>
        )}
      </Formi>

      <ErrorModal show={modalShow} handleClose={handleClose} errors={errorMessages} /> */}
            </div>
          </>
        )}
        <Formik
          validateOnChange={true}
          validateOnBlur={true}
          validationSchema={validationSchem}
          enableReinitialize={true}
          initialValues={initialValues}
          onSubmit={(values, action) => {
            let agentId = editAgentsData?.agent_id;
            let agentUser = editAgentsData?.agents_users?.find(
              (it) => it?.role_id === 6
            );
            let obj = {
              role_id: 6,
              agent_type: values.agent_type,
              auth_by: values.auth_by,
              manager: values.manager,
              agent_name: values.agent_name,
              agent_book_permission: values.agent_book_permission,
              agent_grp_id: values.bg_id,
              reg_no: values.reg_number,
              tax_no: values.tax_number,
              view_statement: values.statement,
              logo_view: values.logo,
              tkt_auth: values.ticket_authority,
              hours_72: values.hour_72,
              address: `${values.address}]${values.city}]${values.country}`,
              phone: values.phone?.toString(),
              mobile_no: values.mobile_no?.toString(),
              user_email: values.user_email,
              email: values.agent_email,
              url: values.url,
              f_name: values.f_name,
              l_name: values.l_name,
              password: values.password,
              user_mobile_no: values.mobile_no?.toString(),
              agent_grp_show: values?.agent_grp_show,
              attachement: values?.attachement,
            };

            let objFormData = new FormData();
            Object.entries(obj).forEach(([key, value]) => {
              objFormData.append(key, value);
            });

            let editData = {
              acc_id: editAgentsData?.acc_id,
              user_id: agentUser?.user_id,
              agent_type: values?.agent_type,
              auth_by: values?.auth_by,
              manager: values?.manager,
              agent_name: values?.agent_name,
              agent_grp_id: values?.bg_id,
              reg_no: values?.reg_number,
              tax_no: values?.tax_number,
              view_statement: values?.statement,
              logo_view: values?.logo,
              tkt_auth: values?.ticket_authority,
              hours_72: values?.hour_72,
              address: `${values?.address}]${values?.city}]${values?.country}`,
              phone: values.phone?.toString(),
              mobile_no: values.mobile_no?.toString(),
              user_email: values.user_email,
              email: values?.agent_email,
              url: values?.url,
              f_name: values?.f_name,
              l_name: values?.l_name,
              password: values?.password,
              agent_grp_show: values?.agent_grp_show,
              agent_book_permission: values?.agent_book_permission,
              attachement: values?.attachement,
              status: 1,
            };

            let editFormData = new FormData();
            Object.entries(editData).forEach(([key, value]) => {
              editFormData.append(key, value);
            });

            let pendingAgent = {
              agent_type: values.agent_type,
              user_id: agentUser?.user_id,
              auth_by: values.auth_by,
              manager: values.manager,
              agent_name: values.agent_name,
              agent_grp_id: values.bg_id,
              reg_no: values?.reg_number,
              tax_no: values?.tax_number,
              view_statement: values?.statement,
              logo_view: values?.logo,
              tkt_auth: values?.ticket_authority,
              hours_72: values?.hour_72,
              address: `${values?.address}]${values?.city}]${values?.country}`,
              phone: values.phone?.toString(),
              mobile_no: values.mobile_no?.toString(),
              user_email: values.user_email,
              email: values?.agent_email,
              agent_grp_show: values?.agent_grp_show,
              agent_book_permission: values?.agent_book_permission,
              attachement: values?.attachement,
            };

            let pendingAgentFormData = new FormData();

            Object.entries(pendingAgent).forEach(([key, value]) => {
              pendingAgentFormData.append(key, value);
            });


            if (editAgentsData) {
              if (editAgentsData?.status === 3) {
                if (agentNameError === "") {
                  dispatch(
                    asyncUpdatePendingAgency({
                      agent_id: editAgentsData?.agent_id,
                      pendingAgentFormData,
                      navigate,
                    })
                  );
                }
              } else {
                if (agentNameError === "") {
                  dispatch(
                    asyncUpdateNewAgency({ agentId, editFormData, navigate })
                  );
                }
              }
            } else {
              if (agentNameError === "") {
                dispatch(asyncPostNewAgencyReg(objFormData));
              }
            }

            // action.resetForm({ values: "" });
          }}
        >
          {({ values, setFieldValue, handleChange, errors }) => {
            return (
              <>
                <Form className="p-4 pb-0">
                  <div className="black_border rounded-2 py-5 px-4">
                    <p className="box_title">Pricing / Authority:</p>
                    <div className="row align-items-end pt-4">
                      <div className="col-sm-2">
                        <FormControl
                          variant="standard"
                          className="w-100"
                          required
                        >
                          <InputLabel id="demo-simple-select-standard-label">
                            Agent (Pricing)
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-standard-label"
                            id="demo-simple-select-standard"
                            name={`bg_id`}
                            value={values.bg_id}
                            onChange={handleChange}
                            MenuProps={dropMenuProp}
                            inputProps={{
                              readOnly: values.agent_type === "Corporate",
                            }}
                          >
                            {agentGroup?.map((agt) => (
                              <MenuItem
                                value={agt.agent_grp_id}
                                key={agt.agent_grp_id}
                              >
                                {agt.agent_grp_name}
                              </MenuItem>
                            ))}
                          </Select>
                          <ErrorMessage
                            name="bg_id"
                            component="div"
                            className="mt-2 text-danger"
                          />
                        </FormControl>
                      </div>
                      <div className="col-sm-2">
                        <FormControl
                          variant="standard"
                          className="w-100"
                          required
                        >
                          <InputLabel id="demo-simple-select-standard-label">
                            Authorized By
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-standard-label"
                            id="demo-simple-select-standard"
                            name={`auth_by`}
                            value={values.auth_by}
                            onChange={handleChange}
                            MenuProps={dropMenuProp}
                            inputProps={{
                              readOnly: values.agent_type === "Corporate" || user?.user_id !== 1
                            }}
                          >
                            {authByUsers?.map((it) => {
                              return (
                                <MenuItem value={it?.user_id} key={it?.user_id}>
                                  {`${it?.f_name} ${it?.l_name}`}
                                </MenuItem>
                              );
                            })}
                          </Select>
                          <ErrorMessage
                            name="auth_by"
                            component="div"
                            className="mt-2 text-danger"
                          />
                        </FormControl>
                      </div>
                      <div className="col-sm-2">
                        <FormControl
                          variant="standard"
                          className="w-100"
                          required
                        >
                          <InputLabel id="demo-simple-select-standard-label">
                            Agent Type
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-standard-label"
                            id="demo-simple-select-standard"
                            name={`agent_type`}
                            value={values.agent_type}
                            onChange={(e) => {
                              let value = e.target.value;
                              setFieldValue("agent_type", value);
                              setAgentType(value);
                            }}
                            MenuProps={dropMenuProp}
                          >
                            {officeType.map((office) => (
                              <MenuItem value={office} key={office}>
                                {office}
                              </MenuItem>
                            ))}
                          </Select>
                          <ErrorMessage
                            name="agent_type"
                            component="div"
                            className="mt-2 text-danger"
                          />
                        </FormControl>
                      </div>
                      <div className="col-sm-2">
                        <FormControl
                          variant="standard"
                          className="w-100"
                          required
                        >
                          <InputLabel id="demo-simple-select-standard-label">
                            Account Manager
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-standard-label"
                            id="demo-simple-select-standard"
                            name={`manager`}
                            value={values?.manager}
                            onChange={handleChange}
                            MenuProps={dropMenuProp}
                            inputProps={{
                              readOnly: values.agent_type === "Corporate" || user?.user_id !== 1
                            }}
                          >
                            {accManagerUser?.map((mngr) => (
                              <MenuItem value={mngr.user_id} key={mngr.user_id}>
                                {`${mngr?.f_name} ${mngr?.l_name}`}
                              </MenuItem>
                            ))}
                          </Select>
                          <ErrorMessage
                            name="manager"
                            component="div"
                            className="mt-2 text-danger"
                          />
                        </FormControl>
                      </div>
                    </div>
                    <div className="row align-items-end mt-4 pt-3">
                      <div className="col-sm-2">
                        <FormControl variant="standard" className="w-100">
                          <InputLabel id="demo-simple-select-standard-label">
                            Change Logo
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-standard-label"
                            id="demo-simple-select-standard"
                            name={`logo`}
                            value={values?.logo}
                            onChange={handleChange}
                            MenuProps={dropMenuProp}
                            inputProps={{
                              readOnly: values.agent_type === "Corporate",
                            }}
                          >
                            <MenuItem value={0}>{"NO"}</MenuItem>
                            <MenuItem value={1}>{"YES"}</MenuItem>
                          </Select>
                          <ErrorMessage
                            name="logo"
                            component="div"
                            className="mt-2 text-danger"
                          />
                        </FormControl>
                      </div>
                      <div className="col-sm-2">
                        <FormControl variant="standard" className="w-100">
                          <InputLabel id="demo-simple-select-standard-label">
                            Statement Layout
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-standard-label"
                            id="demo-simple-select-standard"
                            name={`statement`}
                            value={values?.statement}
                            onChange={handleChange}
                            MenuProps={dropMenuProp}
                            inputProps={{
                              readOnly: values.agent_type === "Corporate",
                            }}
                          >
                            <MenuItem value={"Full Statement"}>
                              {"Full Statement"}
                            </MenuItem>
                            <MenuItem value={"Only Total"}>
                              {"Only Total"}
                            </MenuItem>
                            <MenuItem value={"WithOut Discount/PSF"}>
                              {"WithOut Discount/PSF"}
                            </MenuItem>
                          </Select>
                          <ErrorMessage
                            name="statement"
                            component="div"
                            className="mt-2 text-danger"
                          />
                        </FormControl>
                      </div>
                      <div className="col-sm-2">
                        <FormControl variant="standard" className="w-100">
                          <InputLabel id="demo-simple-select-standard-label">
                            72 Hour Ticket Issue Condition
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-standard-label"
                            id="demo-simple-select-standard"
                            name={`hour_72`}
                            value={values?.hour_72}
                            onChange={handleChange}
                            MenuProps={dropMenuProp}
                            inputProps={{
                              readOnly: values.agent_type === "Corporate",
                            }}
                          >
                            <MenuItem value={0}>{"NO"}</MenuItem>
                            <MenuItem value={1}>
                              {"YES (NON-REF / 72 HOURS)"}
                            </MenuItem>
                          </Select>
                          <ErrorMessage
                            name="hour_72"
                            component="div"
                            className="mt-2 text-danger"
                          />
                        </FormControl>
                      </div>
                      <div className="col-sm-2">
                        <FormControl variant="standard" className="w-100">
                          <InputLabel id="demo-simple-select-standard-label">
                            Ticket Authority
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-standard-label"
                            id="demo-simple-select-standard"
                            name={`ticket_authority`}
                            value={values?.ticket_authority}
                            onChange={handleChange}
                            MenuProps={dropMenuProp}
                          >
                            <MenuItem value={0}>{"NO"}</MenuItem>
                            <MenuItem value={1}>{"YES"}</MenuItem>
                          </Select>
                          <ErrorMessage
                            name="ticket_authority"
                            component="div"
                            className="mt-2 text-danger"
                          />
                        </FormControl>
                      </div>
                      <div className="col-sm-2">
                        <FormControl variant="standard" className="w-100">
                          <InputLabel id="agent_book_permission">
                            Make PNR
                          </InputLabel>
                          <Select
                            labelId="agent_book_permission"
                            id="agent_book_permission"
                            name={`agent_book_permission`}
                            value={values?.agent_book_permission}
                            onChange={handleChange}
                            MenuProps={dropMenuProp}
                          >
                            <MenuItem value={0}>{"NO"}</MenuItem>
                            <MenuItem value={1}>{"YES"}</MenuItem>
                          </Select>
                          <ErrorMessage
                            name="agent_book_permission"
                            component="div"
                            className="mt-2 text-danger"
                          />
                        </FormControl>
                      </div>
                      <div className="col-sm-2">
                        <FormControl variant="standard" className="w-100">
                          <InputLabel id="agent_grp_show">
                            Show Supplier
                          </InputLabel>
                          <Select
                            labelId="agent_grp_show"
                            id="agent_grp_show"
                            name={`agent_grp_show`}
                            value={values?.agent_grp_show}
                            onChange={handleChange}
                            MenuProps={dropMenuProp}
                          >
                            <MenuItem value={0}>{"NO"}</MenuItem>
                            <MenuItem value={1}>{"YES"}</MenuItem>
                          </Select>
                          <ErrorMessage
                            name="agent_grp_show"
                            component="div"
                            className="mt-2 text-danger"
                          />
                        </FormControl>
                      </div>

                      {/************  Hotel authority will be implemented later ***********/}
                      {/* <div className="col-sm-2">
                      <FormControl variant="standard" className="w-100">
                        <InputLabel id="demo-simple-select-standard-label">
                          Hotel Authority
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-standard-label"
                          id="demo-simple-select-standard"
                          name={`hotel_authority`}
                          value={values?.hotel_authority}
                          onChange={handleChange}
                          MenuProps={dropMenuProp}
                        >
                          <MenuItem value={0}>{"NO"}</MenuItem>
                          <MenuItem value={1}>{"YES"}</MenuItem>
                        </Select>
                        <ErrorMessage
                          name="hotel_authority"
                          component="div"
                          className="mt-2 text-danger"
                        />
                      </FormControl>
                    </div> */}
                    </div>
                    <div className="col-sm-2 pt-5">
                      <p style={{ lineHeight: 0 }}>
                        Previous Statement (Attachement)
                      </p>
                      <TextField
                        type="file"
                        className="w-100"
                        variant="standard"
                        accept="application/pdf"
                        name={`attachement`}
                        onChange={(e) => {
                          let value = e.target.files[0];
                          setFieldValue("attachement", value);
                        }}
                      // onChange={(event) => {
                      //   setFieldValue("logo", event.target.files[0]);
                      // }}
                      />
                      <ErrorMessage
                        component="div"
                        className="text-danger mt-1"
                        name="attachement"
                      />
                    </div>
                  </div>
                  {/* ******************  Company Information ******************* */}
                  <div className="black_border rounded-2 pt-5 px-4 mt-5">
                    <div className="d-flex">
                      <p className="box_title mb-4">
                        Company / Personal Information:
                      </p>
                    </div>
                    <div className="row align-items-start h-6em">
                      <div className="col-sm-2">
                        <TextField
                          required
                          label={"Agent Name"}
                          className="w-100"
                          variant="standard"
                          autoComplete="off"
                          name={`agent_name`}
                          value={values.agent_name}
                          onChange={(e) => {
                            let value = e.target.value.toUpperCase();
                            setFieldValue("agent_name", value);

                            // Check if the agent name already exists in newAgencyRegData
                            const agentName = newAgencyRegData?.find(
                              (data) =>
                                data?.agent_name.toUpperCase() ===
                                value.toUpperCase()
                            );

                            if (agentName) {
                              setAgentNameError("This agent already exists.");
                            } else {
                              setAgentNameError("");
                            }
                          }}
                        />

                        {agentNameError && (
                          <div className="text-danger mt-1">
                            {agentNameError}
                          </div>
                        )}

                        <ErrorMessage
                          name="agent_name"
                          component="div"
                          className="mt-2 text-danger"
                        />
                      </div>

                      <div className="col-sm-2">
                        <TextField
                          required
                          label={"Agent ID "}
                          variant="standard"
                          className="w-100"
                          autoComplete="off"
                          name={`agent_id`}
                          value={values.agent_id}
                          onChange={handleChange}
                          inputProps={{
                            readOnly: true,
                          }}
                        />
                        <ErrorMessage
                          name="agent_id"
                          component="div"
                          className="mt-2 text-danger"
                        />
                      </div>
                      <div className="col-2 pt-4">
                        <div class="checkbox-rect">
                          <input
                            value={values.add_as_supplier}
                            checked={values.add_as_supplier}
                            type="checkbox"
                            id={"add_as_supplier"}
                            onChange={(event) => {
                              setFieldValue(
                                "add_as_supplier",
                                !values.add_as_supplier
                              );
                            }}
                          />
                          <label for={"add_as_supplier"} className="h6">
                            Check as Supplier.
                          </label>
                        </div>
                        <p className="text-danger box_title mt-2">
                          {errors.add_as_supplier}
                        </p>
                      </div>
                      {values.add_as_supplier && (
                        <div className=" col-sm-2">
                          <FormControl
                            variant="standard"
                            className="w-100"
                            required
                          >
                            <InputLabel id="demo-simple-select-standard-label">
                              Supplier List
                            </InputLabel>
                            <Select
                              labelId="demo-simple-select-standard-label"
                              id="demo-simple-select-standard"
                              name={`supplierName`}
                              value={values?.supplierName}
                              onChange={(e) => {
                                const value = e?.target?.value;
                                const suppName = newSupplier?.find(
                                  (data) => data?.list_id === value
                                );
                                const agentName = newAgencyRegData?.find(
                                  (data) =>
                                    data?.agent_name === suppName?.supp_name
                                );
                                console.log("agentName", agentName);
                                setFieldValue("supplierName", value);
                                setFieldValue(
                                  "agent_name",
                                  suppName?.supp_name
                                );
                                if (agentName) {
                                  setAgentNameError(
                                    "This agent already exists."
                                  );
                                } else {
                                  setAgentNameError("");
                                }
                              }}
                              MenuProps={dropMenuProp}
                            >
                              {newSupplier?.map((sup) => (
                                <MenuItem value={sup.list_id} key={sup.list_id}>
                                  {sup.supp_name}
                                </MenuItem>
                              ))}
                            </Select>
                            <ErrorMessage
                              name="supplierName"
                              component="div"
                              className="mt-2 text-danger"
                            />
                          </FormControl>
                        </div>
                      )}
                    </div>
                    <div className="row align-items-start h-6em">
                      <div className="col-sm-2">
                        <TextField
                          required
                          label={"First Name "}
                          variant="standard"
                          className="w-100"
                          autoComplete="off"
                          name={`f_name`}
                          value={values.f_name}
                          onChange={(e) => {
                            let value = e.target.value;
                            setFieldValue("f_name", value.toUpperCase());
                          }}
                        />
                        <ErrorMessage
                          name="f_name"
                          component="div"
                          className="mt-2 text-danger"
                        />
                      </div>
                      <div className="col-sm-2">
                        <TextField
                          required
                          label={"Last Name"}
                          variant="standard"
                          className="w-100"
                          autoComplete="off"
                          name={`l_name`}
                          value={values.l_name}
                          onChange={(e) => {
                            let value = e.target.value;
                            setFieldValue("l_name", value.toUpperCase());
                          }}
                        />
                        <ErrorMessage
                          name="l_name"
                          component="div"
                          className="mt-2 text-danger"
                        />
                      </div>
                      <div className="col-sm-3">
                        <TextField
                          required
                          label={"User Email (Your Login User)"}
                          variant="standard"
                          className="w-100"
                          autoComplete="off"
                          type="email"
                          name="user_email"
                          value={values.user_email}
                          onChange={(e) => {
                            let value = e.target.value;
                            setFieldValue("user_email", value.toUpperCase());
                          }}
                          onBlur={(e) => {
                            let value = e.target.value;
                            let match = allUsersData?.filter(
                              (user) => user?.email === value
                            );
                            match.length
                              ? setValidEmail(true)
                              : setValidEmail(false);
                          }}
                        />
                        {validEmail && (
                          <div className="text-danger pt-2">
                            This Email is already taken.
                          </div>
                        )}
                        <ErrorMessage
                          name="email"
                          component="div"
                          className="mt-2 text-danger"
                        />
                      </div>
                    </div>
                    <div className="row align-items-start h-6em ">
                      <div className="col-12 col-sm-2">
                        <TextField
                          label="Registraion number"
                          variant="standard"
                          className="w-100"
                          autoComplete="off"
                          name={`reg_number`}
                          value={values.reg_number}
                          onChange={(e) => {
                            let values = e.target.value;
                            setFieldValue("reg_number", values.toUpperCase());
                          }}
                        />
                      </div>
                      <div className="col-sm-2">
                        <TextField
                          label="Tax Number"
                          variant="standard"
                          className="w-100"
                          autoComplete="off"
                          name={`tax_number`}
                          value={values.tax_number}
                          onChange={(e) => {
                            let values = e.target.value;
                            setFieldValue("tax_number", values.toUpperCase());
                          }}
                        />
                      </div>
                      <div className="col-sm-2">
                        <CountryDropdown
                          id="UNIQUE_ID"
                          className="country_dropdown absolute w-100"
                          preferredCountries={["pk"]}
                          value={values.country}
                          handleChange={(e) =>
                            setFieldValue("country", e.target.value)
                          }
                        />
                      </div>
                      <div className="col-sm-2">
                        <TextField
                          required
                          label={"City "}
                          variant="standard"
                          className="w-100"
                          autoComplete="off"
                          name={`city`}
                          value={values.city}
                          onChange={(e) => {
                            let values = e.target.value;
                            setFieldValue("city", values.toUpperCase());
                          }}
                        />
                        <ErrorMessage
                          name="city"
                          component="div"
                          className="mt-2 text-danger"
                        />
                      </div>
                      <div className="col-sm-2">
                        <TextField
                          required
                          label={"Address "}
                          variant="standard"
                          className="w-100"
                          autoComplete="off"
                          name={`address`}
                          value={values.address}
                          onChange={(e) => {
                            let values = e.target.value;
                            setFieldValue("address", values.toUpperCase());
                          }}
                        />
                        <ErrorMessage
                          name="address"
                          component="div"
                          className="mt-2 text-danger"
                        />
                      </div>
                    </div>
                    <div className="row align-items-start h-6em">
                      <div className="col-sm-3">
                        <TextField
                          label="Company URL"
                          variant="standard"
                          className="w-100"
                          autoComplete="off"
                          name={`url`}
                          value={values.url}
                          onChange={(e) => {
                            let values = e.target.value;
                            setFieldValue("url", values);
                          }}
                        />
                      </div>
                      <div className="col-sm-3">
                        <TextField
                          required
                          label={"Agent Email "}
                          variant="standard"
                          className="w-100"
                          autoComplete="off"
                          name={`agent_email`}
                          value={values.agent_email}
                          onChange={(e) => {
                            let values = e.target.value;
                            setFieldValue("agent_email", values.toUpperCase());
                          }}
                        />
                        <ErrorMessage
                          name="agent_email"
                          component="div"
                          className="mt-2 text-danger"
                        />
                      </div>
                      <div className="col-sm-2">
                        <TextField
                          required
                          label={"Agent Phone "}
                          variant="standard"
                          className="w-100"
                          autoComplete="off"
                          type="number"
                          name={`phone`}
                          value={values.phone}
                          onChange={handleChange}
                        />
                        <ErrorMessage
                          name="phone"
                          component="div"
                          className="mt-2 text-danger"
                        />
                      </div>
                      <div className="col-sm-2">
                        <TextField
                          required
                          label={"Agent Mobile "}
                          variant="standard"
                          className="w-100"
                          autoComplete="off"
                          type="number"
                          name={`mobile_no`}
                          value={values.mobile_no}
                          onChange={handleChange}
                        />
                        <ErrorMessage
                          name="mobile_no"
                          component="div"
                          className="mt-2 text-danger"
                        />
                      </div>
                    </div>
                    {!editAgentsData && (
                      <div className="row align-items-start h-6em">
                        <div className="col-sm-3">
                          <FormControl variant="standard" required fullWidth>
                            <InputLabel htmlFor="standard-adornment-password">
                              Password
                            </InputLabel>
                            <Input
                              id="standard-adornment-password"
                              autoComplete="off"
                              type={showPassword ? "text" : "password"}
                              name="password"
                              value={values.password}
                              onChange={handleChange}
                              inputProps={{
                                readOnly: values.agent_type === "Corporate",
                              }}
                              endAdornment={
                                <InputAdornment position="end">
                                  <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={(e) =>
                                      setShowPassword(!showPassword)
                                    }
                                  >
                                    {showPassword ? (
                                      <VisibilityOff />
                                    ) : (
                                      <Visibility />
                                    )}
                                  </IconButton>
                                </InputAdornment>
                              }
                            />
                          </FormControl>
                          <ErrorMessage
                            name="password"
                            component="p"
                            className="mt-2 text-danger "
                          />
                        </div>
                        <div className="col-sm-3">
                          <TextField
                            label="Confirm Password"
                            variant="standard"
                            className="w-100"
                            autoComplete="off"
                            type="password"
                            name={`password_confirmation`}
                            value={values.password_confirmation}
                            onChange={handleChange}
                          />

                          <ErrorMessage
                            name="password_confirmation"
                            component="div"
                            className="mt-2 text-danger"
                          />
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="text-center">
                    {!editAgentsData ? (
                      <button
                        className="btn setup-btn mt-5 mx-auto"
                        type="submit"
                      >
                        {loading ? <BtnLoader /> : "Save"}
                      </button>
                    ) : (
                      <button
                        className="btn setup-btn mt-5 mx-auto"
                        type="submit"
                        disabled={!editPermission}
                      >
                        {loading ? <BtnLoader /> : "Update"}
                      </button>
                    )}

                    <p className="confirm_msg mt-1 text-success">
                      {message && "Agent has been registered!"}
                    </p>
                  </div>
                </Form>
              </>
            );
          }}
        </Formik>
      </div>
    </div>
  );
};

export default AgencyRegistration;
