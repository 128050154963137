import React, { useState } from "react";
import ReactDatePicker from "react-datepicker";
import "../Styles/manualInvoices.css";
import { FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import Reports from "../components/Reports/Reports";
import { useDispatch, useSelector } from "react-redux";
import {
  asyncGetSaleReportData,
  setChangeDailyInvoice,
  setChangeInvoice,
} from "../redux/features/Finance/Sale/SaleReportSlice";

function DailySaleReport() {
  const [startDate, setStartDate] = React.useState("");
  const [service, setService] = useState("");
  const { changeInvoice, saleReportData } = useSelector(
    (state) => state.SaleReport
  );
  const [saleReport, setSaleReport] = useState({
    fromDate: new Date(),
    voucher_type_id: [4],
    report:"DailySaleReport",
    service_type: "",
  });
  const dispatch = useDispatch();
  const arry = [
    { id: 0, type: "All" },
    { id: 1, type: "Flight" },
    { id: 2, type: "Visa" },
    { id: 3, type: "Hotel" },
    { id: 4, type: "Insurance" },
    { id: 5, type: "Transfer" },
    { id: 6, type: "Activity" },
    { id: 7, type: "Umrah" },
  ];
  return (
    <div>
      {changeInvoice === "dailyInvoice" && (
        <div className="finance-container Full_height">
          <div className="manual_invoices mx-auto  mt-5">
            <div className="manual_invoices_header ">
              <div className="w-100 text-center">Daily Sale Report</div>
            </div>

            <div className="row align-items-end py-4 mx-5">
              <div className="col-12 col-md-6 pe-4">
                <FormControl variant="standard" className="w-100" sx={{}}>
                  <InputLabel id="demo-simple-select-standard-label">
                    Services
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                    name={service}
                    value={saleReport?.service_type}
                    onChange={(e) => {
                      const value = e.target.value;
                      setSaleReport((prev) => ({
                        ...prev,
                        service_type: value,
                      }));
                    }}
                    label="Services"
                  >
                    {arry.map((list) => (
                      <MenuItem
                        value={list?.type}
                        sx={{ bgcolor: "#fff" }}
                        key={list?.id}
                      >
                        {list?.type}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>

              <div className="col-12 col-md-6">
                <ReactDatePicker
                  placeholderText="Date"
                  selected={saleReport?.fromDate}
                  onChange={(date) => {
                    setSaleReport((prev) => ({
                      ...prev,
                      fromDate: date,
                    }));
                  }}
                  maxDate={new Date()}
                  className="date_picker "
                />
              </div>
            </div>
            <div className="d-flex justify-content-center align-items-center my-4">
              <button
                className="button_style"
                onClick={(e) => {
                  const queryParams = {
                    ...saleReport,
                  };
                  dispatch(asyncGetSaleReportData(queryParams));
                  console.log("sale", queryParams);
                  dispatch(setChangeInvoice("dailyInvoiceReport"));
                }}
              >
                Generate DSR
              </button>
            </div>
          </div>
        </div>
      )}

      {changeInvoice === "dailyInvoiceReport" && (
        <div>
          <Reports
            data={saleReportData}
            report="dailyInvoice"
            text="Daily Sale Report"
          />
        </div>
      )}
    </div>
  );
}

export default DailySaleReport;
