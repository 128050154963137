import * as React from "react";
import Box from "@mui/material/Box";
import Slider from "@mui/material/Slider";
import { CurrencyCode } from "../../../../Utils/currencyExchange";
import { useDispatch, useSelector } from "react-redux";
import { set_price_filter } from "../../../../redux/features/home/FlightSearchSlice";

export default function DiscreteSlider() {
  const { all_prices_array } = useSelector((state) => state.FlightSearch);
  const [first, setFirst] = React.useState([
    0,
    all_prices_array[all_prices_array?.length - 1],
  ]);
  const dispatch = useDispatch();

  const handleChange = (event, newValues) => {
    setFirst(newValues);
    dispatch(set_price_filter(newValues));
  };

  return (
    <Box>
      <Slider
        getAriaLabel={() => "Price range"}
        value={first}
        onChange={handleChange}
        valueLabelDisplay="auto"
        valueLabelFormat={(value) => `${CurrencyCode()} ${value}`}
        min={0}
        max={all_prices_array[1]}
        step={10}
      />
    </Box>
  );
}
