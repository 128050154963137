// newAgencyRegistration + CorporateRegistration

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { HTTPForm, NODEURL } from "../../ApiConfig";
import { asyncEditAgentsData } from "../../setupRestPages/agentsSlice";

const initialState = {
  newAgencyRegData: [],
  newCorporateAgencyData: [],
  allAgencyUser: [],
  loading: false,
  error: null,
  registerationSuccess: false,
  message: false,
  message2: "",
  searchAgency: [],
  agentError: {},
};

// getting newAgencyReg data
export const asyncGetNewAgencyReg = createAsyncThunk(
  "asyncGetNewAgencyReg/get",
  async (_, { rejectWithValue }) => {
    try {
      const response = await NODEURL.get(`/api/v1/b2b/new-agency`);
      //   console.log(await response.data)
      return await response.data.data;
    } catch (error) {
      console.log(error.message);
      return rejectWithValue(error.message);
    }
  }
);

// getting corporateAgency data
export const asyncGetCorporateRegistration = createAsyncThunk(
  "asyncGetCorporateRegistration/get",
  async (_, { rejectWithValue }) => {
    try {
      const response = await NODEURL.get(
        `/api/v1/b2b/new_agency/new_corporate`
      );
      // console.log(await response.data)
      return await response.data.data;
    } catch (error) {
      console.log(error.message);
      return rejectWithValue(error.message);
    }
  }
);

// posting newAgencyReg data
export const asyncPostNewAgencyReg = createAsyncThunk(
  "asyncPostNewAgencyReg/post",
  async (values, { rejectWithValue, dispatch }) => {
    // for (var pair of values.entries()) {
    //   console.log(pair[0] + ", " + pair[1] + ":" + typeof pair[1]);
    // }
    try {
      const response = await HTTPForm.post(`/api/v1/b2b/new-agency`, values);
      // return await response.data.data;
      dispatch(asyncGetNewAgencyReg());
    } catch (error) {
      console.log(error);
      return rejectWithValue(error);
    }
  }
);
// posting newAgencyReg data
export const asyncRegNewAgency = createAsyncThunk(
  "asyncRegNewAgent/post",
  async (data, { rejectWithValue, dispatch }) => {
    try {
      const response = await NODEURL.post(`/api/v1/b2b/register-agency`, data);
      return await response?.data?.data;
    } catch (error) {
      console.log(error);
      return rejectWithValue(error);
    }
  }
);
// posting newAgencyReg data
export const asyncRegNewAgencyMultiple = createAsyncThunk(
  "asyncRegNewAgentMultiple/post",
  async (data, { rejectWithValue, dispatch }) => {
    try {
      const response = await NODEURL.post(
        `/api/v1/b2b/create-multiple-agency`,
        data
      );
      return response.data.data;
    } catch (error) {
      const errorDetails = error?.response?.data?.errors;
      const errorMessage = error?.response?.data?.message;
      if (errorDetails) {
        dispatch(setAgentError(errorDetails));
        return rejectWithValue(errorDetails);
      } else if (errorMessage) {
        dispatch(setErrorMessage(errorMessage));
        return rejectWithValue(errorMessage);
      }
    }
  }
);

// posting newAgencyReg data
export const asyncUpdateNewAgency = createAsyncThunk(
  "asyncUpdateNewAgency/patch",
  async (
    { agentId, editFormData, navigate },
    { state, rejectWithValue, dispatch }
  ) => {
    console.log("agentId", agentId, "editFormData", editFormData, "navigate",navigate);
    try {
      const response = await HTTPForm.patch(
        `/api/v1/b2b/new-agency/${agentId}`,
        editFormData
      );
      // return await response.data.data
      // dispatch(asyncEditAgentsData(agentId));
      setTimeout(() => {
        dispatch(messageNull());
        navigate("/agents");
      }, 2000);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// posting newAgencyReg data
export const asyncUpdatePendingAgency = createAsyncThunk(
  "asyncUpdatePendingAgency/patch",
  async (
    { agent_id, pendingAgentFormData, navigate },
    { rejectWithValue, dispatch }
  ) => {
    try {
      const response = await HTTPForm.patch(
        `/api/v1/b2b/pending-agency/${agent_id}`,
        pendingAgentFormData
      );
      // return await response.data.data,
      // dispatch(asyncEditAgentsData(agent_id));
      setTimeout(() => {
        dispatch(messageNull());
        navigate("/agents");
      }, 2000);
    } catch (error) {
      return rejectWithValue(error?.response?.data?.message);
    }
  }
);

// deleting pending agency
export const asyncDeletePendingAgency = createAsyncThunk(
  "asyncDeletePendingAgency/delete",
  async (id, { rejectWithValue, dispatch }) => {
    try {
      const response = await NODEURL.delete(`/api/v1/b2b/pending-agency/${id}`);
      // return await response.data.data
      dispatch(asyncGetNewAgencyReg());
    } catch (error) {
      console.log(error);
      return rejectWithValue(error);
    }
  }
);
// posting newAgencyReg data
export const asynGetAllAgencyUser = createAsyncThunk(
  "asynGetAllAgencyUser/get",
  async (_, { rejectWithValue }) => {
    try {
      const response = await NODEURL.get("/api/v1/b2b/new-agency-users");
      return await response.data.data;
    } catch (error) {
      console.log(error);
      return rejectWithValue(error);
    }
  }
);
// posting newCorporateReg data
export const asyncPostNewCorporateReg = createAsyncThunk(
  "asyncPostNewCorporateReg/get",
  async (values, { rejectWithValue }) => {
    try {
      const response = await NODEURL.post(
        `/api/v1/b2b/new_agency/agency`,
        values
      );
      // return await response.data.data
    } catch (error) {
      console.log(error);
      return rejectWithValue(error);
    }
  }
);

// update agency credit line
export const asyncUpdateAgencyCreditLine = createAsyncThunk(
  "asyncUpdateAgencyCreditLine/patch",
  async ({ obj, agentId }, { rejectWithValue, dispatch }) => {
    try {
      const response = await NODEURL.patch(
        `/api/v1/b2b/new-agency-credit-line/${agentId}`,
        obj
      );
      // console.log(await response.data);
      // return await response.data.data
      dispatch(asyncEditAgentsData(agentId));
    } catch (error) {
      console.log(error);
      return rejectWithValue(error);
    }
  }
);

// search agency data
export const asyncSearchAgencyData = createAsyncThunk(
  "asyncSearchAgencyData/get",
  async (value, { rejectWithValue }) => {
    try {
      const response = await NODEURL.get(
        `/api/v1/b2b/search-new-agency/?agent_name=${value}`
      );
      return await response.data.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const NewAgencyRegistrationSlice = createSlice({
  name: "NewAgencyRegistration",
  initialState,
  reducers: {
    messageNull: (state) => {
      state.message = null;
      state.registerationSuccess = false;
    },
    searchAgencyNull: (state) => {
      state.searchAgency = null;
    },
    setAgentError: (state, action) => {
      state.agentError = action.payload;
    },
    setErrorMessage: (state, action) => {
      state.message2 = action.payload;
    },
  },

  extraReducers: (builder) => {
    // getting newAgncyReg data
    builder.addCase(asyncGetNewAgencyReg.pending, (state, { payload }) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(asyncGetNewAgencyReg.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.newAgencyRegData = payload;
    });
    builder.addCase(asyncGetNewAgencyReg.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    });

    // posting newAgncyReg data
    builder.addCase(asyncPostNewAgencyReg.pending, (state, { payload }) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(asyncPostNewAgencyReg.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = null;
      // state.newAgencyRegData = payload;
    });
    builder.addCase(asyncPostNewAgencyReg.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    });

    // posting newAgncyMultiplReg data
    builder.addCase(asyncRegNewAgencyMultiple.pending, (state, { payload }) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(
      asyncRegNewAgencyMultiple.fulfilled,
      (state, { payload }) => {
        state.loading = false;
        state.error = null;
        state.agentError = payload;
      }
    );
    builder.addCase(
      asyncRegNewAgencyMultiple.rejected,
      (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      }
    );

    // getting corporateAgency data
    builder.addCase(
      asyncGetCorporateRegistration.pending,
      (state, { payload }) => {
        state.loading = true;
        state.error = null;
      }
    );
    builder.addCase(
      asyncGetCorporateRegistration.fulfilled,
      (state, { payload }) => {
        state.loading = false;
        state.error = null;
        state.newCorporateAgencyData = payload;
      }
    );
    builder.addCase(
      asyncGetCorporateRegistration.rejected,
      (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      }
    );

    // posting corporateAgency data
    builder.addCase(asyncPostNewCorporateReg.pending, (state, { payload }) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(
      asyncPostNewCorporateReg.fulfilled,
      (state, { payload }) => {
        state.loading = false;
        state.error = null;
        // state.newCorporateAgencyData = payload;
      }
    );

    builder.addCase(asyncPostNewCorporateReg.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    });

    // Update new agency
    builder.addCase(asyncUpdateNewAgency.pending, (state, { payload }) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(asyncUpdateNewAgency.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.message = true;
    });
    builder.addCase(asyncUpdateNewAgency.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload;
      state.message = false;
    });
    // Update agency credit line
    builder.addCase(
      asyncUpdateAgencyCreditLine.pending,
      (state, { payload }) => {
        state.loading = true;
        state.error = null;
      }
    );
    builder.addCase(
      asyncUpdateAgencyCreditLine.fulfilled,
      (state, { payload }) => {
        state.loading = false;
        state.error = null;
        // state.newCorporateAgencyData = payload;
      }
    );
    builder.addCase(
      asyncUpdateAgencyCreditLine.rejected,
      (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      }
    );

    // Get All agency users
    builder.addCase(asynGetAllAgencyUser.pending, (state, { payload }) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(asynGetAllAgencyUser.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.allAgencyUser = payload;
    });
    builder.addCase(asynGetAllAgencyUser.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    });

    // Register new agent
    builder.addCase(asyncRegNewAgency.pending, (state, { payload }) => {
      state.registerationSuccess = true;
      state.loading = true;
      state.error = null;
    });
    builder.addCase(asyncRegNewAgency.fulfilled, (state, { payload }) => {
      state.registerationSuccess = true;
      state.loading = false;
      state.error = null;
    });
    builder.addCase(asyncRegNewAgency.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    });

    // Updata pending agency
    builder.addCase(asyncUpdatePendingAgency.pending, (state, { payload }) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(
      asyncUpdatePendingAgency.fulfilled,
      (state, { payload }) => {
        state.loading = false;
        state.error = null;
        state.message = true;
      }
    );
    builder.addCase(asyncUpdatePendingAgency.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload;
      state.message = false;
    });

    // search agency data
    builder.addCase(asyncSearchAgencyData.pending, (state, { payload }) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(asyncSearchAgencyData.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.searchAgency = payload;
    });
    builder.addCase(asyncSearchAgencyData.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload;
      state.message = false;
    });
  },
});
export const { messageNull, searchAgencyNull, setAgentError, setErrorMessage } =
  NewAgencyRegistrationSlice.actions;
export default NewAgencyRegistrationSlice.reducer;
