import React from "react";
import {
  Document,
  Page,
  Text,
  View,
  Image,
  StyleSheet,
  PDFViewer,
} from "@react-pdf/renderer";
import logo from "../../assets/images/zairaa.jpeg";

const AgentListPdf = ({first}) => {
  const tableData = [
    {
      OfficeID: "TA-14 ",
      OfficeName: "Zairaa Main Office",
      OfficeType: "Travel Agent ",
      TicketAuthority: "No",
      Contact: "0512120448",
      ApproveLimit: 2000,
      UsedLimit: 1200,
      AvailableLimit: 345332,
      Status: "Active",
    },
    {
      OfficeID: "TA-14 ",
      OfficeName: "Zairaa Main Office",
      OfficeType: "Travel Agent ",
      TicketAuthority: "No",
      Contact: "0512120448",
      ApproveLimit: 2000,
      UsedLimit: 1200,
      AvailableLimit: 345332,
      Status: "Active",
    },
    {
      OfficeID: "TA-14 ",
      OfficeName: "Zairaa Main Office",
      OfficeType: "Travel Agent ",
      TicketAuthority: "No",
      Contact: "0512120448",
      ApproveLimit: 2000,
      UsedLimit: 1200,
      AvailableLimit: 345332,
      Status: "Active",
    },
    {
      OfficeID: "TA-14 ",
      OfficeName: "Zairaa Main Office",
      OfficeType: "Travel Agent ",
      TicketAuthority: "No",
      Contact: "0512120448",
      ApproveLimit: 2000,
      UsedLimit: 1200,
      AvailableLimit: 345332,
      Status: "Active",
    },
  ];

  const consultant=[]
  const corporate = []

  const borderColor = "#000000";
  const styles = StyleSheet.create({
    containerheader: {
      flexDirection: "row",
      borderBottomColor: borderColor,
      borderBottomWidth: 1,
      borderTopColor: borderColor,
      borderTopWidth: 1,
      fontSize: 9,
      marginTop: 10,
    },
    containerbody: {
      flexDirection: "row",
      borderBottomColor: borderColor,
      borderBottomWidth: 1,
      fontSize: 9,
    },
    OfficeID: {
      width: "10%",
      padding: 3,
      borderRightColor: borderColor,
      borderRightWidth: 1,
      borderLeftColor: borderColor,
      borderLeftWidth: 1,
    },
    OfficeName: {
      width: "14%",
      padding: 3,
      borderRightColor: borderColor,
      borderRightWidth: 1,
    },
    OfficeType: {
      width: "14%",
      padding: 3,
      borderRightColor: borderColor,
      borderRightWidth: 1,
    },
    TicketAuthority: {
      width: "14%",
      padding: 3,
      borderRightColor: borderColor,
      borderRightWidth: 1,
    },
    Contact: {
      width: "14%",
      padding: 3,
      borderRightColor: borderColor,
      borderRightWidth: 1,
    },
    ApproveLimit: {
      width: "12%",
      padding: 3,
      borderRightColor: borderColor,
      borderRightWidth: 1,
    },
    UsedLimit: {
      width: "12%",
      padding: 3,
      borderRightColor: borderColor,
      borderRightWidth: 1,
    },
    AvailableLimit: {
      width: "12%",
      padding: 3,
      borderRightColor: borderColor,
      borderRightWidth: 1,
    },
    Status: {
      width: "8%",
      padding: 3,
      borderRightColor: borderColor,
      borderRightWidth: 1,
    },
  });
  return (
    <Document>
      <Page size="A4" style={{ padding: 10 }}>
        <View style={{ display: "flex", flexDirection: "row" }}>
          <View
            style={{
              width: "20%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Image src={logo} style={{ width: "80%", height: "40px" }} />
          </View>
          <View
            style={{
              width: "60%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Text style={{ fontStyle: "bold", fontSize: 16, paddingTop: 20 }}>
              OTAWIX Solution Pvt Ltd
            </Text>
            <Text style={{ fontSize: 13, marginTop: 4 }}>
              Address: Egerton Road, Lahore
            </Text>
            <Text style={{ fontSize: 13, marginTop: 4 }}>
              Email: otawix.solution@gmail.com
            </Text>
            <Text style={{ fontSize: 13, marginTop: 4 }}>
              Contact: 0333-123567
            </Text>
          </View>
          <View
            style={{
              width: "20%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Image src={logo} style={{ width: "80%", height: "40px" }} />
          </View>
        </View>

        <View
          style={{
            backgroundColor: "#B0B0B0",
            padding: 4,
            marginTop: 20,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Text style={{ color: "#FFFFFF", fontSize: 14 }}>
            Agent Statement
          </Text>
        </View>

        <View
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Text style={{ fontSize: 14, marginTop: 10, fontStyle: "bold" }}>
          Travel Agent List
          </Text>
        </View>

        <View style={styles.containerheader}>
          <Text style={styles.OfficeID}>OFFICE ID</Text>
          <Text style={styles.OfficeName}>OFFICE NAME</Text>
          <Text style={styles.OfficeType}>OFFICE TYPE</Text>
          <Text style={styles.TicketAuthority}>TICKET AUTHORITY</Text>
          <Text style={styles.Contact}>CONTACT</Text>
          <Text style={styles.ApproveLimit}>APPROVE LIMIT</Text>
          <Text style={styles.UsedLimit}>USED LIMIT</Text>
          <Text style={styles.AvailableLimit}>AVAILABLE LIMIT</Text>
          <Text style={styles.Status}>STATUS</Text>
        </View>
        <View>
          {first === "Active" ? tableData.map((data, index) => {
            return (
              <View style={styles.containerbody} key={index}>
                <Text style={styles.OfficeID}>{data?.OfficeID}</Text>
                <Text style={styles.OfficeName}>{data?.OfficeName}</Text>
                <Text style={styles.OfficeType}>{data?.OfficeType}</Text>
                <Text style={styles.TicketAuthority}>
                  {data?.TicketAuthority}
                </Text>
                <Text style={styles.Contact}>{data?.Contact}</Text>
                <Text style={styles.ApproveLimit}>{data?.ApproveLimit}</Text>
                <Text style={styles.UsedLimit}>{data?.UsedLimit}</Text>
                <Text style={styles.AvailableLimit}>
                  {data?.AvailableLimit}
                </Text>
                <Text style={styles.Status}>{data?.Status}</Text>
              </View>
            );
          }): null}
        </View>
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            borderBottomColor: borderColor,
            borderBottomWidth: 1,
            fontSize: 9,
          }}
        >
          <Text
            style={{
              flex: 1,
              padding: 3,
              borderLeftColor: borderColor,
              borderLeftWidth: 1,
              borderRightColor: borderColor,
              borderRightWidth: 1,
              textAlign: "right"
            }}
          >
            Total Used Limit
          </Text>
          <Text
            style={{
              width: "63px",
              padding: 3,
              borderRightColor: borderColor,
              borderRightWidth: 1,
            }}
          >
            2235496
          </Text>
          <Text
            style={{
              width: "104px",
              padding: 3,
              borderRightColor: borderColor,
              borderRightWidth: 1,
            }}
          ></Text>
        </View>

        {/* consultant list  */}
        <View
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Text style={{ fontSize: 14, marginTop: 10, fontStyle: "bold" }}>
          Travel Consultant List
          </Text>
        </View>

        <View style={styles.containerheader}>
          <Text style={styles.OfficeID}>OFFICE ID</Text>
          <Text style={styles.OfficeName}>OFFICE NAME</Text>
          <Text style={styles.OfficeType}>OFFICE TYPE</Text>
          <Text style={styles.TicketAuthority}>TKT AUTHORITY</Text>
          <Text style={styles.Contact}>CONTACT</Text>
          <Text style={styles.ApproveLimit}>APPROVE LIMIT</Text>
          <Text style={styles.UsedLimit}>USED LIMIT</Text>
          <Text style={styles.AvailableLimit}>AVAILABLE LIMIT</Text>
          <Text style={styles.Status}>STATUS</Text>
        </View>
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            borderBottomColor: borderColor,
            borderBottomWidth: 1,
            fontSize: 9,
          }}
        >
          <Text
            style={{
              flex: 1,
              padding: 3,
              borderLeftColor: borderColor,
              borderLeftWidth: 1,
              borderRightColor: borderColor,
              borderRightWidth: 1,
              textAlign: "right"
            }}
          >
            Total Used Limit
          </Text>
          <Text
            style={{
              width: "63px",
              padding: 3,
              borderRightColor: borderColor,
              borderRightWidth: 1,
            }}
          >
            2235496
          </Text>
          <Text
            style={{
              width: "104px",
              padding: 3,
              borderRightColor: borderColor,
              borderRightWidth: 1,
            }}
          ></Text>
        </View>

        

        <View
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Text style={{ fontSize: 14, marginTop: 10, fontStyle: "bold" }}>
          Corporate List
          </Text>
        </View>

        <View style={styles.containerheader}>
          <Text style={styles.OfficeID}>OFFICE ID</Text>
          <Text style={styles.OfficeName}>OFFICE NAME</Text>
          <Text style={styles.OfficeType}>OFFICE TYPE</Text>
          <Text style={styles.TicketAuthority}>TKT AUTHORITY</Text>
          <Text style={styles.Contact}>CONTACT</Text>
          <Text style={styles.ApproveLimit}>APPROVE LIMIT</Text>
          <Text style={styles.UsedLimit}>USED LIMIT</Text>
          <Text style={styles.AvailableLimit}>AVAILABLE LIMIT</Text>
          <Text style={styles.Status}>STATUS</Text>
        </View>
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            borderBottomColor: borderColor,
            borderBottomWidth: 1,
            fontSize: 9,
          }}
        >
          <Text
            style={{
              flex: 1,
              padding: 3,
              borderLeftColor: borderColor,
              borderLeftWidth: 1,
              borderRightColor: borderColor,
              borderRightWidth: 1,
              textAlign: "right"
            }}
          >
            Total Used Limit
          </Text>
          <Text
            style={{
              width: "63px",
              padding: 3,
              borderRightColor: borderColor,
              borderRightWidth: 1,
            }}
          >
            2235496
          </Text>
          <Text
            style={{
              width: "104px",
              padding: 3,
              borderRightColor: borderColor,
              borderRightWidth: 1,
            }}
          ></Text>
        </View>
      </Page>
    </Document>
  );
};

export default AgentListPdf;
