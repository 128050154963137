import React, { useEffect, useState } from "react";
import "../Styles/manualInvoices.css";
import CustomDropdown from "../components/CustomDrp";
import ReactDatePicker from "react-datepicker";
import {
  Button,
  ClickAwayListener,
  FormControl,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { asyncGetNewSupplier } from "../redux/features/supplier/AddNewSupplier";
import { MdClose } from "react-icons/md";
import {
  asyncGetNewAgencyReg,
  asyncSearchAgencyData,
} from "../redux/features/setupFeature/NewRegistration/NewRegistrationSlice";
import { asyncGetAllUserData } from "../redux/features/agency/usersSlice";
import {
  asyncGetSaleReportData,
  setChangeInvoice,
} from "../redux/features/Finance/Sale/SaleReportSlice";
import { asyncGetAddAirlinesData } from "../redux/features/setupRestPages/Price_AirLine_SuppRole";
import { includes } from "lodash";
import { asyncGetAllUserPermission } from "../redux/features/agency/rolesPermissionSlice";
import Reports from "../components/Reports/Reports";

function SaleReport() {
  const dispatch = useDispatch();
  const { addAirLinesData } = useSelector(
    (state) => state?.price_airlines_suppRoleSlice
  );
  const { saleReportData, changeInvoice } = useSelector(
    (state) => state.SaleReport
  );
  const userData = JSON.parse(localStorage.getItem("loginUser"));
  const { userPermission } = useSelector((state) => state?.roleAndPermissions);
  const ownBooking = userPermission
    ?.filter(
      (flt, index) =>
        flt.user_id === userData?.user_id &&
        flt?.page_level?.permission_name === "own-bookings"
    )
    ?.at(0);
 
  const [manager, setManager] = useState(
    userData?.user_id !== 1 ? userData?.f_name + " " + userData?.l_name : ""
  );
  const [accountManager, setAccountManager] = useState(
    ownBooking?.user_id ? ownBooking?.user_id : "All"
  );
  const [service, setService] = useState("");
  const AirLineData = [
    { name: "All", code_alpha: "All" },
    ...addAirLinesData,
  ]?.filter(
    (flt) =>
      ![
        "SERENE AIR",
        "PIA-HITIT",
        "AIR SIAL",
        "AIRBLUE",
        "FLY JINNAH",
      ].includes(flt?.name)
  );
  const [saleReport, setSaleReport] = useState({
    fromDate: new Date(),
    toDate: new Date(),
    voucher_type_id: [4],
    accId: [],
    service_type: "",
    supplier_acc_Id: "",
    IatBsp: "",
    IataBspToogle: false,
    IatBspCode: [],
  });

  const arry = [
    { id: 0, type: "All" },
    { id: 1, type: "Flight" },
    { id: 2, type: "Visa" },
    { id: 3, type: "Hotel" },
    { id: 4, type: "Insurance" },
    { id: 5, type: "Transfer" },
    { id: 6, type: "Activity" },
    { id: 7, type: "Umrah" },
  ];

  const [agentDRopDwn, setagentDRopDwn] = useState(false);
  const [agent, setAgent] = useState("");
  const { searchAgency } = useSelector((state) => state.newAgency);

  const { newSupplier } = useSelector((state) => state.supplier);
  const suppArray = newSupplier?.map((sup) => sup?.supp_name);
  const [drp, setdrp] = useState(false);
  const [supplierName, setSupplierName] = useState([]);
  const [supValue, setSupValue] = useState("");
  const { newAgencyRegData } = useSelector((state) => state.newAgency);
  const { allUsersData } = useSelector((state) => state?.usersSlice);

  const accManagerUser = allUsersData?.filter((it) => it.agent_id === 1);

  useEffect(() => {
    dispatch(asyncGetNewSupplier());
    dispatch(asyncGetNewAgencyReg());
    dispatch(asyncGetAllUserData());
    dispatch(asyncGetAddAirlinesData());
    dispatch(asyncGetAllUserPermission());
  }, [dispatch]);

  const handleGenerateReport = () => {
    let check = false;
    if (saleReport?.supplier_acc_Id === 29) {
      if (saleReport?.IatBspCode.length === 0) {
        check = true;
      } else {
        if (saleReport?.IatBspCode?.at(0) === "All") {
          check = true;
        }
      }
    }

    const Airline = check && AirLineData?.map((fltr) => fltr?.code_alpha);

   
    const queryParams = {
      ...saleReport,
       checkIATABSP:saleReport?.supplier_acc_Id === 29 ? "IATA BSP" : null,
      IatBspCode:
        Airline?.length > 0 ? Airline?.slice(1) : saleReport?.IatBspCode,
    };
     dispatch(setChangeInvoice("InvoiceReport"));
    dispatch(asyncGetSaleReportData(queryParams));
  };

  return (
    <div>
      {changeInvoice === "Invoice" && (
        <div className="finance-container">
          <div className="manual_invoices mx-auto mt-5">
            <div className="manual_invoices_header">
              <div className="w-100 text-center">
                Sale Report (Date Wise / Sale Type Wise / Agent Wise / Supplier
                Wise)
              </div>
            </div>
            <div className="px-2 px-md-5">
              <div className="pt-5">
                <div className="row align-items-end">
                  <div className="col-6">
                    <ReactDatePicker
                      placeholderText="From Date"
                      selected={saleReport?.fromDate}
                      onChange={(date) =>
                        setSaleReport((prev) => ({
                          ...prev,
                          fromDate: date,
                        }))
                      }
                      maxDate={new Date()}
                      className="date_picker"
                    />
                  </div>
                  <div className="col-6">
                    <ReactDatePicker
                      placeholderText="To Date"
                      selected={saleReport?.toDate}
                      onChange={(date) =>
                        setSaleReport((prev) => ({
                          ...prev,
                          toDate: date,
                        }))
                      }
                      minDate={saleReport?.fromDate}
                      maxDate={new Date()}
                      disabled={!saleReport?.fromDate}
                      className="date_picker"
                    />
                  </div>
                </div>
                <div className="row align-items-end pt-4 pt-md-5">
                  <div className="col-6 pe-4">
                    <FormControl variant="standard" className="w-100">
                      <InputLabel id="demo-simple-select-standard-label">
                        Account Manager
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-standard-label"
                        id="demo-simple-select-standard"
                        name="manager"
                        value={manager}
                        disabled={userData?.user_id !== 1}
                        onClick={(e) => {
                          setAgent("");
                        }}
                      >
                        {[
                          { f_name: "All", user_id: "All", l_name: "" },
                          ...accManagerUser,
                        ]?.map((mngr) => (
                          <MenuItem
                            value={mngr?.f_name + " " + mngr?.l_name}
                            key={mngr.user_id}
                            onClick={() => {
                              setAccountManager(mngr.user_id);
                              setManager(mngr?.f_name + " " + mngr?.l_name);
                              // setAgent("");
                            }}
                          >
                            {`${mngr?.f_name} ${mngr?.l_name}`}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>

                  <div className="col-6 pe-4">
                    <FormControl variant="standard" className="w-100">
                      <InputLabel id="demo-simple-select-standard-label">
                        Services
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-standard-label"
                        id="demo-simple-select-standard"
                        name={saleReport.service_type}
                        value={saleReport.service_type}
                        onChange={(e) =>
                          setSaleReport((prev) => ({
                            ...prev,
                            service_type: e.target.value,
                          }))
                        }
                        label="Services"
                      >
                        {arry?.map((list) => (
                          <MenuItem
                            value={list?.type}
                            sx={{ bgcolor: "#fff" }}
                            key={list?.id}
                          >
                            {list?.type}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                  <div className="col-6 pe-4">
                    <FormControl
                      variant="standard"
                      className="w-100"
                      onClick={() => setagentDRopDwn(!agentDRopDwn)}
                    >
                      <InputLabel htmlFor="supplier">Select Agent</InputLabel>
                      <Input
                        variant="standard"
                        label="Select Agent"
                        autoComplete="off"
                        className="w-100"
                        name="agent"
                        value={agent}
                        onChange={(e) => {
                          let value = e.target.value;
                          setAgent(value);
                          dispatch(asyncSearchAgencyData(value));
                        }}
                        endAdornment={
                          <InputAdornment position="end">
                            {agent !== "" && (
                              <IconButton
                                onClick={() => {
                                  setAgent("");
                                }}
                              >
                                <MdClose />
                              </IconButton>
                            )}
                          </InputAdornment>
                        }
                        // disabled={!accountManager}
                      />
                    </FormControl>

                    {agentDRopDwn && (
                      <ClickAwayListener
                        onClickAway={() => setagentDRopDwn(false)}
                      >
                        <div className="agent_dropdown">
                          {searchAgency?.length > 0 ? (
                            [
                              { agent_name: "All", acc_id: null },
                              ...searchAgency?.filter((fl) =>
                                accountManager === "All"
                                  ? fl
                                  : fl?.manager === accountManager
                              ),
                            ]?.map((item, index) => (
                              <div
                                className="p-3"
                                onClick={() => {
                                  const accIds =
                                    item.agent_name === "All"
                                      ? searchAgency.map((it) => it.acc_id)
                                      : [item.acc_id];

                                  setSaleReport((prev) => ({
                                    ...prev,
                                    accId: accIds,
                                  }));
                                  setAgent(item.agent_name);
                                  setagentDRopDwn(false);
                                }}
                                style={{ cursor: "pointer" }}
                                key={index}
                              >
                                {item?.agent_name}
                              </div>
                            ))
                          ) : (
                            <div className="p-3">No record available.</div>
                          )}
                        </div>
                      </ClickAwayListener>
                    )}
                  </div>

                  <div className="col-6 pt-4 pt-md-5 pe-4">
                    <FormControl
                      variant="standard"
                      className="w-100"
                      onClick={() => setdrp(!drp)}
                    >
                      <InputLabel htmlFor="supplier">
                        Select Supplier
                      </InputLabel>
                      <Input
                        autoComplete="off"
                        id="supplier"
                        className="w-100"
                        value={supValue}
                        name={supValue}
                        onChange={(e) => {
                          let value = e.target.value.toLocaleUpperCase();
                          setSupValue(value);
                          let filterSupplier = value
                            ? suppArray?.filter((item) =>
                                item
                                  ?.toLowerCase()
                                  .includes(value.toLowerCase())
                              )
                            : suppArray;
                          setSupplierName([...filterSupplier]);
                        }}
                        endAdornment={
                          <InputAdornment position="end">
                            {supValue !== "" && (
                              <IconButton
                                onClick={() => {
                                  setSupValue("");
                                  setSaleReport((prev) => ({
                                    ...prev,
                                    supplier_acc_Id: "",
                                  }));
                                }}
                              >
                                <MdClose />
                              </IconButton>
                            )}
                          </InputAdornment>
                        }
                      />
                    </FormControl>
                    {drp && (
                      <ClickAwayListener onClickAway={() => setdrp(!drp)}>
                        <div className="supplier_dropdown">
                          {supplierName?.length > 0 ? (
                            supplierName?.map((item, index) => (
                              <div
                                key={index}
                                className="supplier_style"
                                onClick={() => {
                                  let value = `${item}`;
                                  setSupValue(value);
                                  setdrp(!drp);
                                  let supp_data = newSupplier
                                    ?.filter((sup) => sup?.supp_name === value)
                                    ?.at(0);

                                  setSaleReport((prev) => ({
                                    ...prev,
                                    supplier_acc_Id: supp_data.acc_id,
                                  }));
                                }}
                              >
                                <div className="fs-6 supplier_captilize">
                                  {item.toLocaleUpperCase()}
                                </div>
                              </div>
                            ))
                          ) : (
                            <div className="supplier_style">
                              No Record Found.
                            </div>
                          )}
                        </div>
                      </ClickAwayListener>
                    )}
                  </div>

                  {supValue === "IATA BSP" ? (
                    <div className="ms-auto col-6 pt-4 pt-md-5 pe-4">
                      <FormControl
                        variant="standard"
                        className="w-100"
                        onClick={() =>
                          setSaleReport((prev) => ({
                            ...prev,
                            IataBspToogle: !saleReport?.IataBspToogle,
                          }))
                        }
                      >
                        <InputLabel htmlFor="supplier">
                          Select Airline
                        </InputLabel>
                        <Input
                          autoComplete="off"
                          id="supplier"
                          className="w-100"
                          value={saleReport?.IatBsp}
                          name="IatBsp"
                          onChange={(e) => {
                            let value = e.target.value.toLocaleUpperCase();
                            // const data = addAirLinesData?.filter(
                            //   (flt) =>
                            //     flt?.name?.toLowerCase() === value.toLowerCase()
                            // );
                            setSaleReport((prev) => ({
                              ...prev,
                              IatBsp: value,
                            }));
                          }}
                          endAdornment={
                            <InputAdornment position="end">
                              {saleReport?.name !== "" && (
                                <IconButton
                                  onClick={() =>
                                    setSaleReport((prev) => ({
                                      ...prev,
                                      IatBsp: "",
                                      IatBspCode: "",
                                    }))
                                  }
                                >
                                  <MdClose />
                                </IconButton>
                              )}
                            </InputAdornment>
                          }
                        />
                      </FormControl>
                      {saleReport?.IataBspToogle && (
                        <ClickAwayListener
                          onClickAway={() =>
                            setSaleReport((prev) => ({
                              ...prev,
                              IataBspToogle: !saleReport?.IataBspToogle,
                            }))
                          }
                        >
                          <div className="supplier_dropdown">
                            {AirLineData?.length > 0 ? (
                              AirLineData.map((item, index) => (
                                <div
                                  key={index}
                                  className="supplier_style"
                                  onClick={() => {
                                    setSaleReport((prev) => ({
                                      ...prev,
                                      IatBsp: item?.name,
                                      IataBspToogle: !saleReport?.IataBspToogle,
                                      IatBspCode: [item?.code_alpha],
                                    }));
                                  }}
                                >
                                  <div className="fs-6 supplier_captilize">
                                    {item?.name?.toLocaleUpperCase()}
                                  </div>
                                </div>
                              ))
                            ) : (
                              <div className="supplier_style">
                                No Record Found.
                              </div>
                            )}
                          </div>
                        </ClickAwayListener>
                      )}
                    </div>
                  ) : null}
                </div>
              </div>

              {/* <div className="d-flex justify-content-center align-items-center pt-5">
            <div className="me-5">
              <label className="form-control">
                <input type="radio" name="radio" />
                <span className="h6">PDF</span>
              </label>
            </div>
            <div className="">
              <label className="form-control">
                <input type="radio" name="radio" />
                <span className="h6">EXCEL</span>
              </label>
            </div>
          </div> */}

              <div className="container py-4">
                <div className="d-flex justify-content-center align-items-center my-3 mt-4">
                  <button
                    className="button_style"
                    onClick={handleGenerateReport}
                  >
                    Generate Report
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {changeInvoice === "InvoiceReport" && (
        <div>
          <Reports data={saleReportData} report="Invoice" text="Sale Report" />
        </div>
      )}
    </div>
  );
}

export default SaleReport;
