import { useNavigate, useParams } from "react-router-dom";
import iata from "../../../../assets/images/iata.png";
import logo from "../../../../assets/images/zairaa.jpeg";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useRef, useState } from "react";
import { AsyncGetAllVoucher } from "../../../../redux/features/Voucher/VoucherSlice";
import { TwoDecimalPoint } from "../../../../Utils/FixedTwoDecimal";
import moment from "moment";
import { apiUrl } from "../../../../Utils/configUrl";
import ReactToPrint from "react-to-print";
const AgentsStatementPDF = ({
  data,
  agentData,
  voucher,
  setChangeComponet,
  fromDate,
  toDate,
  statement,
  userData,
  allwaysCredit,
}) => {
  const dispatch = useDispatch()
  const { adminAgencyData } = useSelector((state) => state.agentsCategories);
  let cumulativeBalance = 0;
  const calculateCumulativeBalance = (transaction) => {
    const debit = TwoDecimalPoint(
      voucher?.curr === "PKR"
        ? transaction?.acc_voucher_trans?.at(0)?.d_cur_debit
        : transaction?.acc_voucher_trans?.at(0)?.d_cur_debit /
            agentData?.agent_group?.currency?.roe
    );
    const credit = TwoDecimalPoint(
      voucher?.curr === "PKR"
        ? transaction?.acc_voucher_trans?.at(0)?.d_cur_credit
        : transaction?.acc_voucher_trans?.at(0).d_cur_credit /
            agentData?.agent_group?.currency?.roe
    );
    cumulativeBalance += debit - credit;
    //  cumulativeBalance = cumulativeBalance + debit - credit;
    return cumulativeBalance;
  };
  const transformSign = (balance) => {
    return balance < 0 ? Math.abs(balance) : -Math.abs(balance);
  };
  const naivate = useNavigate();
  const obj = {
    INV: "manual-invoice",
    RV: "vouchers-entry",
  };
  const componentRef = useRef();
  const firstColor = adminAgencyData?.first_color;
  return (
    <div className="d-flex  flex-column gap-4 bg-white">
      <div className="p-4 fs-4 no-print border border-secondry ">
        <div
          className="d-flex justify-content-between align-items-center"
          style={{ marginRight: "100px", marginLeft: "100px" }}
        >
          <ReactToPrint
            trigger={() => (
              <button className="bg_primary px-4 py-1 text-white">Print</button>
            )}
            content={() => componentRef.current}
          />

          <button
            onClick={(e) => dispatch(setChangeComponet("search"))}
            className="bg_primary px-4 py-1 rounded text-white"
          >
            Back
          </button>
        </div>
      </div>
      {/* bottom setion */}
      <div className="d-flex flex-column gap-3  mt-4" ref={componentRef}>
        {/* logo */}
        <div className="d-flex justify-content-around align-items-center">
          <div style={{ width: "300px" }}>
            <img
              src={`${apiUrl}/${userData?.agents_comp?.logo}`}
              alt="logo"
              style={{ width: "70%", height: "auto" }}
            />
          </div>
          <div
            className="d-flex fw-bold fs-6  flex-column gap-2 align-items-center"
            style={{ width: "1200px" }}
          >
            <span className=" text-black">
              {" "}
              {userData?.agents_comp?.agent_name}
            </span>
            <span className="text-black">
              {" "}
              {userData?.agents_comp?.address
                ?.split("]")
                ?.slice(0, 3)
                ?.join(" ")}{" "}
            </span>
            <span className=" text-black"> Email: {userData?.email}</span>
            <span className=" text-black"> Contact:{userData?.mobile_no}</span>
          </div>
          <div style={{ width: "200px" }}>
            <img
              src={iata}
              alt="iata"
              style={{ width: "70%", height: "auto" }}
            />
          </div>
        </div>
        <div
          className="d-flex flex-column gap-1 justify-content-center mx-4 align-items-center"
          style={{ backgroundColor: firstColor }}
        >
          <span className="fs-3 text-white fw-bold my-2">
            {" "}
            Account Statement
          </span>
          {agentData?.credit_limit ? (
            statement === "agent" ? (
              <div className="text-white d-flex flex-column gap-1 align-items-center">
                <span className=" text-black">
                  ({agentData?.agent_name}
                  {" - "}
                  {voucher?.curr === "PKR"
                    ? "PKR"
                    : agentData?.agent_group?.currency?.curr_code}
                  )
                </span>
                <span className="mb-2">
                  ( Date: {moment(fromDate)?.format("DD-MMM-YY")}
                  {" - "} {moment(toDate)?.format("DD-MMM-YY")})
                </span>
              </div>
            ) : null
          ) : (
            <span className=" text-white d-flex flex-column gap-1 align-items-center pb-2">
              ({agentData})
            </span>
          )}
        </div>
        {/* statement */}
        {agentData?.credit_limit ? (
          <div className="d-flex justify-content-center gap-2 fw-bold ">
            <div className="d-flex gap-4">
              <div className="d-flex flex-column gap-2">
                <div className="">
                  <span>
                    {" "}
                    Approve Limit {agentData?.credit_limit} {"PKR"}{" "}
                  </span>
                </div>
                <div className="d-flex gap-2">
                  <span>
                    Used Limit {agentData?.credit_used} {"PKR"}{" "}
                  </span>
                </div>
                <div className="d-flex gap-2">
                  <span>
                    {" "}
                    Available Limit{" "}
                    {agentData?.credit_limit - agentData?.credit_used} {"PKR"}{" "}
                  </span>
                </div>
              </div>
              <div className="d-flex flex-column gap-2">
                <div>
                  <span style={{ color: "#0000ff" }}>
                    {agentData?.agent_group?.currency?.curr_code}{" "}
                    {TwoDecimalPoint(
                      Number(agentData?.credit_limit) /
                        Number(agentData?.agent_group?.currency?.roe)
                    )}
                  </span>
                </div>
                <div className="d-flex gap-2">
                  <span style={{ color: "#0000ff" }}>
                    {agentData?.agent_group?.currency?.curr_code}{" "}
                    {TwoDecimalPoint(
                      Number(agentData?.credit_used) /
                        Number(agentData?.agent_group?.currency?.roe)
                    )}{" "}
                  </span>
                </div>
                <div className="d-flex gap-2">
                  <span style={{ color: "#0000ff" }}>
                    {agentData?.agent_group?.currency?.curr_code}{" "}
                    {TwoDecimalPoint(
                      (agentData?.credit_limit - agentData?.credit_used) /
                        agentData?.agent_group?.currency?.roe
                    )}
                  </span>
                </div>
              </div>
            </div>
          </div>
        ) : null}
        {/* table */}
        <div
          className="d-flex justify-content-center  align-items-center mx-1  "
          style={{
            marginBottom: "80px",
          }}
        >
          <table className="table table-bordered">
            <thead>
              <tr>
                <th>VN NO</th>
                <th>DATE</th>
                <th>Description</th>
                <th>DEBIT</th>
                <th>CREDIT</th>
                <th>BALANCE</th>
              </tr>
            </thead>
            <tbody>
              {data?.map((transaction, index) => (
                <tr key={index}>
                  {statement === "agent" && index === 0 ? (
                    <>
                      <td colSpan="5" className=" text-center">
                        {
                          transaction?.acc_voucher_trans?.at(0)
                            ?.trans_description
                        }
                      </td>
                      <td colSpan="1">
                        {/* {transaction?.trans?.d_cur_debit}{" "} */}
                        {allwaysCredit
                          ? TwoDecimalPoint(
                              transformSign(
                                calculateCumulativeBalance(transaction)
                              )
                            )
                          : TwoDecimalPoint(
                              calculateCumulativeBalance(transaction)
                            )}
                      </td>
                    </>
                  ) : (
                    <>
                      <td
                        className="text-primary"
                        style={{ cursor: "pointer" }}
                        onClick={(e) => {
                          const url = `/${
                            obj[transaction?.voucher_no?.split("-")?.at(0)]
                          }/${
                            transaction?.voucher_no?.split("-")?.at(0) === "INV"
                              ? `${~~transaction?.acc_voucher_trans
                                  ?.at(0)
                                  ?.trans_description?.split("/")
                                  ?.at(0)}/${Number(
                                  transaction?.acc_voucher_trans?.at(0)
                                    ?.tkt_voucher_no
                                )}/${transaction?.voucher_id}`
                              : transaction?.voucher_id
                          }`;

                          window.open(url, "_blank", "noopener,noreferrer");
                        }}
                      >
                        {transaction?.voucher_no}
                      </td>
                      <td>{transaction?.saved_date}</td>
                      <td>
                        {
                          transaction?.acc_voucher_trans?.at(0)
                            ?.trans_description
                        }
                      </td>
                      <td>
                        {TwoDecimalPoint(
                          voucher?.curr === "PKR"
                            ? transaction?.acc_voucher_trans?.at(0)?.d_cur_debit
                            : transaction?.acc_voucher_trans?.at(0)
                                ?.d_cur_debit /
                                agentData?.agent_group?.currency?.roe
                        )}
                      </td>
                      <td>
                        {TwoDecimalPoint(
                          voucher?.curr === "PKR"
                            ? transaction?.acc_voucher_trans?.at(0)
                                ?.d_cur_credit
                            : transaction?.acc_voucher_trans?.at(0)
                                ?.d_cur_credit /
                                agentData?.agent_group?.currency?.roe
                        )}
                      </td>
                      <td>
                        {allwaysCredit
                          ? TwoDecimalPoint(
                              transformSign(
                                calculateCumulativeBalance(transaction)
                              )
                            )
                          : TwoDecimalPoint(
                              calculateCumulativeBalance(transaction)
                            )}
                      </td>
                    </>
                  )}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default AgentsStatementPDF;
